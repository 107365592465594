/* eslint-disable lines-between-class-members */
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { IPartnerStoreApi, Partner } from '../api/partner';
import ApplicationStore from './application';

const formatter = new Intl.DateTimeFormat('nl-NL', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

export class PartnerStore {
    data: Partner[] = [];
    loading = false;
    api: IPartnerStoreApi;
    applicationStore: ApplicationStore;

    constructor({ api, applicationStore }: { api: IPartnerStoreApi; applicationStore: ApplicationStore }) {
        autoBind(this);
        makeObservable(this, {
            activate: action,
            deactivate: action,
            partners: computed,
            data: observable,
            getData: action,
            update: action,
        });
        this.api = api;
        this.data = [];
        this.applicationStore = applicationStore;
    }

    async getData(): Promise<void> {
        this.loading = true;
        try {
            runInAction(async () => {
                this.data = await this.api.getPartners();
            });
        } catch (error) {
            console.error(error);
        }
        this.loading = false;
    }

    get partners(): Partner[] {
        if (!this.data?.length) return [];
        return this.data.map((partner) => ({
            ...partner,
            id: partner.client_id,
            rate_limit_violation: partner.rate_limit_violation
                ? formatter.format(new Date(partner.rate_limit_violation))
                : '',
        }));
    }

    async update(partner: Partner): Promise<boolean> {
        const success = await this.api.update(partner);
        if (success) this.getData();
        return success;
    }

    async activate(): Promise<void> {
        await this.getData();
    }

    deactivate(): void {
        console.error('Unimplemented');
    }
}
