import { useEffect, useRef, useState, BaseSyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Paper, DialogContentText, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Info } from '@material-ui/icons';

import { useMobxStores } from '../../hooks/useMobxStores';
import MnxButton from '../../components/mnx-button';
import { useToaster } from '../../hooks/useToaster';
import { BookkeepingApi } from '../../api/bookkeeping';
import MnxTextField from '../../components/mnx-textfield';
import {
    SortingByFirstEnum,
    SortingBySecondEnum,
    LineSortingEnum,
    LineSortingKeys,
    SortingByFirstKeys,
    SortingBySecondKeys,
} from '../../api/bookkeeping';

type ApiResult = {
    result: string;
    message?: string;
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 250,
    },
    toolbar: {
        flexGrow: 0,
    },
    searchIcon: {
        paddingLeft: theme.spacing(1),
    },
    paper: {
        background: '#fff',
        padding: theme.spacing(1.5),
        flex: '0 0 auto',
        display: 'flex',
    },
    search_field: {
        marginRight: theme.spacing(2),
    },
    box: {
        maxWidth: '90vw',
        paddingBottom: theme.spacing(2),
    },
}));

const ReorderJournalPage = observer(() => {
    const {
        applicationStore: { reason, tenantId, administrationId },
    } = useMobxStores();

    const classes = useStyles();

    const inputRef = useRef(null);
    const { addToast } = useToaster();
    const [api] = useState(new BookkeepingApi());

    const [year, setYear] = useState(new Date().getFullYear().toString());
    const [journal, setJournal] = useState('');
    const [firstJournalPage, setFirstJournalPage] = useState('');
    const [lastJournalPage, setLastJournalPage] = useState('');
    const [sortingByFirst, setSortingByFirst] = useState<SortingByFirstKeys>('EntryNumber');
    const [sortingBySecond, setSortingBySecond] = useState<SortingBySecondKeys>('None');
    const [lineSorting, setLineSorting] = useState<LineSortingKeys>('None');
    const [compileEntries, setCompileEntries] = useState(false);
    const [reorderDisabled, setReorderDisabled] = useState(false);

    const resortJournalPage = async () => {
        setReorderDisabled(true);
        if (sortingByFirst === sortingBySecond) {
            addToast(
                'Je hebt twee keer hetzelfde soorteertype geselecteerd. Wanneer je geen tweede sorteertype wilt gebruiken, selecteer dan "geen".',
                {
                    variant: 'error',
                },
            );
        }
        if (!year || !journal || !firstJournalPage || !lastJournalPage) {
            addToast('Vul het jaar, dagboek, eerste en laatste blad in om te kunnen hersorteren.', {
                variant: 'error',
            });
        }

        if (year && journal && firstJournalPage && lastJournalPage && sortingByFirst !== sortingBySecond) {
            api.resortJournalPage({
                tenantId,
                administrationId,
                year: Number(year),
                journal,
                firstJournalPage: Number(firstJournalPage),
                lastJournalPage: Number(lastJournalPage),
                sortingByFirst,
                sortingBySecond,
                lineSorting,
                compileEntries,
                reason,
            })
                .then((apiResult: ApiResult) => {
                    if (apiResult.result === 'ok') {
                        addToast('Dagboekblad hersorteren is gestart', {
                            variant: 'success',
                        });
                    }

                    if (apiResult.result === 'unauthorized') {
                        addToast(apiResult.message, {
                            variant: 'warning',
                        });
                    }

                    if (['error', 'forbidden'].includes(apiResult.result)) {
                        addToast(apiResult.message, { variant: 'error' });
                    }
                    setReorderDisabled(false);
                })
                .catch((error) => {
                    addToast('Er is een fout opgetreden bij het hersorteren van het dagboekblad', {
                        variant: 'error',
                    });
                    setReorderDisabled(false);
                });
        } else {
            setReorderDisabled(false);
        }
    };

    useEffect(() => {
        inputRef?.current?.focus();
    }, []);

    return (
        <>
            <Paper style={{ padding: 24 }} elevation={3}>
                <Grid container alignItems="flex-start" spacing={2} justifyContent="space-between">
                    <Grid item xs={10}>
                        <h2>Vul in wat je wilt hersorteren</h2>
                    </Grid>
                    <Chip
                        icon={<Info />}
                        color="secondary"
                        label={`info`}
                        clickable
                        onClick={() => {
                            window.open(
                                'https://minoxsoftware.atlassian.net/wiki/spaces/MDT/pages/639631424/Hersorteren+van+dagboekbladen',
                                '_blank',
                            );
                        }}
                    />
                </Grid>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} md={3}>
                        <MnxTextField label="Jaar" value={year} onChange={(event) => setYear(event.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MnxTextField
                            label="Dagboek"
                            value={journal}
                            onChange={(event) => setJournal(event.target.value.toUpperCase())}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MnxTextField
                            label="Eerste blad"
                            value={firstJournalPage}
                            onChange={(event) => setFirstJournalPage(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MnxTextField
                            label="Laatste blad"
                            value={lastJournalPage}
                            onChange={(event) => setLastJournalPage(event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <h2>Selecteer hieronder hoe je wilt hersorteren</h2>
                    </Grid>
                </Grid>

                <Box>
                    <DialogContentText>Eerste keuze hersorteren</DialogContentText>
                    <Grid container alignItems="flex-start">
                        <div className={classes.search_field}>
                            <FormControl variant="outlined" className={classes.formControl} size="small">
                                <InputLabel id="select-error-type-label">Soorteertype 1</InputLabel>
                                <Select
                                    labelId="select-error-type-label"
                                    id="select-error-type"
                                    value={sortingByFirst || ''}
                                    onChange={(event: BaseSyntheticEvent) => {
                                        setSortingByFirst(event.target.value);
                                    }}
                                    label="Errortype"
                                >
                                    <MenuItem value="EntryNumber">{SortingByFirstEnum.EntryNumber}</MenuItem>
                                    <MenuItem value="DocumentDate">{SortingByFirstEnum.DocumentDate}</MenuItem>
                                    <MenuItem value="CreatedAt">{SortingByFirstEnum.CreatedAt}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                </Box>

                <Box mt={3}>
                    <DialogContentText>Tweede keuze hersorteren</DialogContentText>
                    <Grid container alignItems="flex-start">
                        <div className={classes.search_field}>
                            <FormControl variant="outlined" className={classes.formControl} size="small">
                                <InputLabel id="select-error-type-label">Soorteertype 2</InputLabel>
                                <Select
                                    labelId="select-error-type-label"
                                    id="select-error-type"
                                    value={sortingBySecond || ''}
                                    onChange={(event: BaseSyntheticEvent) => {
                                        setSortingBySecond(event.target.value);
                                    }}
                                    label="Errortype"
                                >
                                    <MenuItem value="None">
                                        <em>{SortingBySecondEnum.None}</em>
                                    </MenuItem>
                                    <MenuItem value="EntryNumber">{SortingBySecondEnum.EntryNumber}</MenuItem>
                                    <MenuItem value="DocumentDate">{SortingBySecondEnum.DocumentDate}</MenuItem>
                                    <MenuItem value="CreatedAt">{SortingBySecondEnum.CreatedAt}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                </Box>

                {sortingByFirst === sortingBySecond && (
                    <Box mt={3}>
                        <DialogContentText color="error">
                            Je hebt twee keer hetzelfde soorteertype geselecteerd. Wanneer je geen tweede sorteertype
                            wilt gebruiken, selecteer dan "geen".
                        </DialogContentText>
                    </Box>
                )}

                <Box mt={3}>
                    <DialogContentText>Optioneel: regelvolgorde hersorteren *</DialogContentText>
                    <Grid container alignItems="flex-start">
                        <div className={classes.search_field}>
                            <FormControl variant="outlined" className={classes.formControl} size="small">
                                <InputLabel id="select-error-type-label">Sorteer op:</InputLabel>
                                <Select
                                    labelId="select-error-type-label"
                                    id="select-error-type"
                                    value={lineSorting || ''}
                                    onChange={(event: BaseSyntheticEvent) => {
                                        setLineSorting(event.target.value);
                                    }}
                                    label="Errortype"
                                >
                                    <MenuItem value="None">
                                        <em>{LineSortingEnum.None}</em>
                                    </MenuItem>
                                    <MenuItem value="AccountNumber">Grootboekrekeningnummer aflopend</MenuItem>
                                    <MenuItem value="CreatedAt">{LineSortingEnum.CreatedAt}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                </Box>

                <Box mt={3}>
                    <FormControl required error={compileEntries} component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Optioneel</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={compileEntries}
                                        onChange={(event: BaseSyntheticEvent) => {
                                            setCompileEntries(event.target.checked);
                                        }}
                                        name="compileEntries"
                                    />
                                }
                                label="Boekingen opnieuw samenstellen"
                            />
                        </FormGroup>
                        {compileEntries && (
                            <DialogContentText color="error">
                                Let op! Deze optie past boekingsidentificatie (trx_id) aan, gekoppelde programma’s
                                kunnen hierdoor de connectie met de boeking verliezen!
                            </DialogContentText>
                        )}
                    </FormControl>
                </Box>

                <Box pt={2}>
                    <Grid item>
                        <MnxButton
                            onClick={() => {
                                resortJournalPage();
                            }}
                            disabled={!reason || !tenantId || !administrationId || reorderDisabled}
                            color="primary"
                        >
                            Dagboekblad hersorteren
                        </MnxButton>
                    </Grid>
                </Box>
            </Paper>
        </>
    );
});

export default ReorderJournalPage;
