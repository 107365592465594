import request from '../utils/request';
import clean from '../utils/clean';
import env from '../env';

export const errorTypes = [
    'afwijkendeperiodenummers',
    'afwijkendedagboektotalen',
    'afwijkenderekeningtotalen',
    'dubbelejaren',
    'dubbeleregels',
    'geentotalenvoorrekeningen',
    'afwijkendefactuursubtotaal',
    'afwijkenderekeningsoort',
    'afwijkendeschermbedragen',
    'bladen_niet_in_evenwicht',
    'verkeerde_koers',
    'missende_periodes',
    'verschil_openstaandeposten',
    'lege_kolommen_mutaties',
    'tegenrekening_verschil_openstaandeposten',
    'dagboekrekening_VI_foutievemutaties',
];

export type QosError = {
    id: string;
    type: string;
    ownerId: string;
    administrationId: string;
    fixable: boolean;
    fixed: boolean;
    fixed_date: Date;
    error: unknown;
    createdAt: Date;
    updatedAt: Date;
};

export interface IQosErrorStoreApi {
    getQosErrors(reason: string, from?: Date, to?: Date, type?: string, tenant_id?: string): Promise<QosError[]>;
    repairQosError(reason: string, tenant_id: string, type: string): Promise<void>;
}

export class QosErrorStoreApi implements IQosErrorStoreApi {
    async getQosErrors(reason: string, from?: Date, to?: Date, type?: string, tenant_id?: string): Promise<QosError[]> {
        return request('GET', `${env.domain}/admin/qos`, true)
            .set({ 'x-reason': reason })
            .query(clean({ tenant_id, type, from: from?.toISOString(), to: to?.toISOString() }))
            .then((res) => res.body);
    }

    async repairQosError(reason: string, tenant_id: string, type: string): Promise<void> {
        await request('POST', `${env.domain}/admin/qos/repair`, true).set({ 'x-reason': reason }).send({
            tenant_id,
            type,
        });
    }
}
