import env from '../env';
import clean from '../utils/clean';
import request from '../utils/request';

export type ApiLog = {
    id: string;
    active: boolean;
    client_id: string;
    database_name: string;
    headers?: Record<string, unknown>;
    method: string;
    params?: Record<string, unknown>;
    query?: Record<string, unknown>;
    request?: Record<string, unknown>;
    response?: Record<string, unknown>;
    response_status: number;
    response_time: number;
    tenant_id: string;
    tenant_nr: number;
    tenants: string[];
    time: number;
    url: string;
    user_id: string;
};

type getLogsArguments = {
    reason: string;
    search?: string;
    page?: number;
    from?: string;
    to?: string;
};
export interface IApiLogApi {
    getLogs(arg0: getLogsArguments): Promise<{ collection: ApiLog[]; count: number }>;
}

export class ApiLogApi implements IApiLogApi {
    async getLogs({
        reason,
        search,
        page,
        from,
        to,
    }: getLogsArguments): Promise<{ collection: ApiLog[]; count: number }> {
        return request('GET', `${env.domain}/admin/request`, true)
            .query(
                clean({
                    page_number: page,
                    from,
                    to,
                    search: search ? encodeURIComponent(search) : undefined,
                }),
            )
            .set({ 'x-reason': reason })
            .then((res) => res.body);
    }
}
