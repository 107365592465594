import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MnxButton from '../mnx-button';

export type MnxDialogProps = {
    id?: string;
    title?: string;
    header?: React.ReactNode;
    disabled?: boolean;
    wrapContent?: boolean;
    footer?: React.ReactNode;
    handleSubmit?: () => void;
    handleClose?: () => void;
} & DialogProps;

const MnxDialog: React.FunctionComponent<MnxDialogProps> = ({
    id,
    title,
    disabled = false,
    handleSubmit = () => undefined,
    handleClose = () => undefined,
    fullScreen,
    header,
    wrapContent = true,
    footer,
    children,
    ...props
}) => {
    const theme = useTheme();
    const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog onClose={handleClose} fullScreen={fullScreen ?? isFullScreen} {...props}>
            {header ?? <DialogTitle id={id || `mnx-dialog-${title}`}>{title}</DialogTitle>}
            {wrapContent ? <DialogContent>{children}</DialogContent> : children}
            {footer ?? (
                <DialogActions>
                    <MnxButton autoFocus onClick={handleClose} mnxColor={theme.palette.error.main}>
                        Annuleren
                    </MnxButton>
                    <MnxButton onClick={handleSubmit} color="primary" disabled={disabled} autoFocus>
                        Bevestigen
                    </MnxButton>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default MnxDialog;
