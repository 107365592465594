const distanceOfTimeInWords = (to: number, from: number): string => {
    const distance_in_seconds = (to - from) / 1000;
    const distance_in_minutes = Math.floor(distance_in_seconds / 60);
    if (distance_in_minutes === 0) {
        return 'minder dan een minuut';
    }
    if (distance_in_minutes === 1) {
        return '1 minuut';
    }
    if (distance_in_minutes < 45) {
        return `${distance_in_minutes} minuten`;
    }
    if (distance_in_minutes < 90) {
        return 'een uur';
    }
    if (distance_in_minutes < 1440) {
        return `ongeveer ${Math.floor(distance_in_minutes / 60)} uur`;
    }
    if (distance_in_minutes < 2880) {
        return 'ongeveer een dag';
    }
    if (distance_in_minutes < 43200) {
        return `ongeveer ${Math.floor(distance_in_minutes / 1440)} dagen`;
    }
    if (distance_in_minutes < 86400) {
        return 'ongeveer een maand';
    }
    if (distance_in_minutes < 525960) {
        return `ongeveer ${Math.floor(distance_in_minutes / 43200)} maanden`;
    }
    if (distance_in_minutes < 1051199) {
        return 'ongeveer een jaar';
    }
    return `over ${Math.floor(distance_in_minutes / 525960)} years ago`;
};

export default distanceOfTimeInWords;
