import { Autocomplete } from '@material-ui/lab';
import React, { forwardRef, Ref, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles, TextField } from '@material-ui/core';

import { useKeyCombination } from '../../hooks/useKeyCombination';

interface Props<T> {
    id: string;
    label: string;
    placeholder?: string;
    input?: T;
    loading?: boolean;
    clearOnSelect?: boolean;
    margin?: 'dense' | 'none' | 'normal';
    size?: 'medium' | 'small';
    values: T[];
    startAdornment?: React.ReactNode;
    onValueSelected: (selected: T) => void;
    onSearchChange?: (term: string) => void;
    getOptionLabel: (option: T) => string;
    [key: string]: unknown;
}

const useStyles = makeStyles(() => ({
    root: {
        color: 'inherit',
    },
}));

const MnxAutocomplete = forwardRef(
    (
        {
            id,
            values,
            label,
            placeholder,
            loading,
            margin,
            size,
            startAdornment,
            clearOnSelect,
            onValueSelected,
            onSearchChange,
            getOptionLabel,
            input,
            ...rest
        }: Props<unknown>,
        ref?: Ref<HTMLSelectElement>,
    ): React.ReactElement => {
        const [open, setOpen] = useState(false);
        const [inputValue, setInputValue] = useState('');
        const classes = useStyles();
        const tabPressed = useKeyCombination('tab');

        useEffect(() => {
            if (tabPressed && open) {
                const firstValue = values?.find((value) => getOptionLabel(value)?.toLowerCase()?.includes(inputValue));
                onChange(firstValue);
            }
        }, [tabPressed]);

        useEffect(() => {
            setInputValue(getOptionLabel(input));
            if (input) {
                onValueSelected(input);
            }
        }, [input]);

        const onChange = (value: unknown) => {
            if (typeof value !== 'object') {
                return;
            }

            if (clearOnSelect) {
                setInputValue('');
            } else {
                setInputValue(getOptionLabel(value));
            }

            if (onValueSelected) {
                onValueSelected(value);
            }
        };

        return (
            <Autocomplete
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                freeSolo
                autoHighlight
                {...rest}
                id={id}
                onChange={(_, value) => onChange(value)}
                onInputChange={(_, __, reason) => {
                    if (reason === 'clear') {
                        setInputValue('');
                    }
                }}
                inputValue={inputValue}
                options={values}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputRef={ref}
                        margin={margin || undefined}
                        size={size || 'small'}
                        label={label}
                        placeholder={placeholder}
                        onChange={(event) => {
                            setInputValue(event?.target?.value || '');
                            if (onSearchChange) {
                                onSearchChange(event?.target?.value);
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            classes: { root: classes.root },
                            'aria-label': 'search',
                            startAdornment,
                            endAdornment: (
                                <>
                                    {loading && inputValue.length ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        );
    },
);

MnxAutocomplete.propTypes = {
    id: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.object),
    onValueSelected: PropTypes.func,
    onSearchChange: PropTypes.func,
    getOptionLabel: PropTypes.func,
};

export default MnxAutocomplete;
