import { observer } from 'mobx-react-lite';
import MnxButton from '../../components/mnx-button';
import AdministrationSelector from '../../components/authorization/administrationSelector';
import { Box, Card, Typography } from '@material-ui/core';
import { useMobxStores } from '../../hooks/useMobxStores';
import { useEffect, useState } from 'react';
import { MinoxConnectionStatus } from '../../api/internaladministration';
import Alert from '@material-ui/lab/Alert';

const LinkRBSWithMinox = observer(() => {
    const {
        internalAdministrationStore: { linkRBSWithMinox, getConnectionStatus, updateConnectionSettings },
    } = useMobxStores();

    const [currentStatus, setStatus] = useState<MinoxConnectionStatus>();
    const updateStatus = async () => {
        const status = await getConnectionStatus();
        if (status.result === 'error') {
            setStatus({
                available_administrations: [],
                connected: false,
                tenant_nr: undefined,
                administration_nr: undefined,
            });
        } else {
            setStatus(status.value);
        }
    };

    const [administrationNumber, setAdministrationNumber] = useState(null);

    useEffect(() => {
        updateStatus();
        const cancelToken = setInterval(updateStatus, 5000);
        return () => {
            clearInterval(cancelToken);
        };
    }, []);

    const linkRBSMinox = () => {
        linkRBSWithMinox();
    };

    let alertSuccess = <></>;
    let alertTakeAction = <></>;
    if (currentStatus?.connected && currentStatus?.administration_nr) {
        const selectedAdministrationName = currentStatus.available_administrations.find((administration) => {
            return administration.nr === Number(currentStatus.administration_nr);
        });

        alertSuccess = (
            <Alert severity="success">
                Er is een actieve koppeling tussen administratie {currentStatus.administration_nr} -{' '}
                {selectedAdministrationName?.name} in Minox en RBS.
            </Alert>
        );
    }

    if (currentStatus?.connected && !currentStatus?.administration_nr) {
        alertTakeAction = <Alert severity="warning">Let op: er moet nog een administratie worden gekoppeld.</Alert>;
    }

    const selectionAdministrations = currentStatus?.available_administrations;

    return (
        <>
            <Box mb={2}>
                {alertSuccess}
                <Card>
                    <Box m={2}>
                        <Typography>
                            Koppel een Minox administratie met RBS. Na koppelen worden aanpassingen in RBS automatisch
                            gesynchroniseerd met Minox waar van toepassing.
                        </Typography>
                        <Box pt={2}>
                            <MnxButton key={'23'} color="primary" onClick={() => linkRBSMinox()}>
                                RBS koppelen met Minox
                            </MnxButton>
                        </Box>
                    </Box>
                </Card>
            </Box>
            {currentStatus?.connected && (
                <Box mb={2}>
                    {alertTakeAction}
                    <Card>
                        <Box m={2}>
                            <Typography>Selecteer hier de administratie waar RBS debiteuren moet beheren</Typography>
                            <Box pt={2}>
                                <AdministrationSelector
                                    value={administrationNumber || currentStatus.administration_nr || ''}
                                    administrations={selectionAdministrations}
                                    onChange={(value: string | number) => {
                                        setAdministrationNumber(value);
                                    }}
                                />
                            </Box>
                            <Box pt={2}>
                                <MnxButton
                                    key={'23'}
                                    color="primary"
                                    onClick={() =>
                                        updateConnectionSettings(administrationNumber).then((data) => {
                                            updateStatus();
                                            return data;
                                        })
                                    }
                                    disabled={!administrationNumber}
                                >
                                    Koppel administratie met Minox Online
                                </MnxButton>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            )}
        </>
    );
});

export default LinkRBSWithMinox;
