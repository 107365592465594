import { createTheme } from '@material-ui/core/styles';
import { nlNL } from '@material-ui/core/locale';

export const light_theme = createTheme(
    {
        palette: {
            type: 'light',
            primary: {
                main: '#1b4f8f',
                contrastText: '#fff',
            },
            secondary: {
                main: '#7c9eb1',
                contrastText: '#fff',
            },
            red: {
                main: '#ff0000',
            },
            error: {
                main: '#ab3030',
            },
            background: {
                default: '#f4f6f8',
                paper: '#fff',
            },
        },
    },
    nlNL,
);

export const dark_theme = createTheme(
    {
        palette: {
            type: 'dark',
            primary: {
                main: '#6ba3e8',
                contrastText: '#fff',
            },
            secondary: {
                main: '#7c9eb1',
                contrastText: '#fff',
            },
            red: {
                main: '#ff0000',
            },
            error: {
                main: '#ab3030',
            },
            background: {
                default: '#202020',
                paper: '#424242',
            },
        },
    },
    nlNL,
);
