import { Avatar, Box, Chip, makeStyles } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxToolbar from '../mnx-toolbar';
import env from '../../env';

const useStyles = makeStyles((theme) => ({
    box: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}));

const AvgBar = observer(() => {
    const {
        applicationStore: { reason, tenant, administration, setReason, setTenantId, setAdministrationId },
    } = useMobxStores();

    const classes = useStyles();

    return (
        <Box className={classes.box}>
            <MnxToolbar>
                <Chip label={env.domain?.split('//')?.[1]?.split('.')?.[0]?.toUpperCase()} />
                {reason && (
                    <Chip
                        color="secondary"
                        label={unescape(reason || '')}
                        avatar={<Avatar>R</Avatar>}
                        onDelete={() => {
                            setReason('');
                        }}
                    />
                )}
                {tenant && (
                    <Chip
                        onDelete={() => {
                            setTenantId(null);
                        }}
                        icon={<AccountCircle />}
                        color="secondary"
                        label={`${tenant.tenant_nr} - ${tenant.name}`}
                    />
                )}
                {administration && (
                    <Chip
                        onDelete={() => {
                            setAdministrationId(null);
                        }}
                        icon={<AccountCircle />}
                        color="secondary"
                        label={`${administration.nr} - ${administration.name}`}
                    />
                )}
            </MnxToolbar>
        </Box>
    );
});

export default AvgBar;
