import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useMobxStores } from '../../hooks/useMobxStores';

const Login: React.FunctionComponent = () => {
    const {
        oauthStore: { isLoggedIn, redirectToLogin },
    } = useMobxStores();

    useEffect(() => {
        if (isLoggedIn === undefined) {
            redirectToLogin(true);
        }
    }, []);

    return <>{isLoggedIn && <Redirect to="/" />}</>;
};

Login.propTypes = {
    path: PropTypes.string,
};

export default Login;
