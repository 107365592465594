import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Grid } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { useMobxStores } from '../../hooks/useMobxStores';
import { useParams } from 'react-router';
import MnxAutocomplete from '../mnx-autocomplete';
import { AdministrationMeta } from '../../api/administration';
import { SearchOption } from '../../stores/search';
import { AvailableAdministration } from '../../api/internaladministration';

const AdministrationSelector = observer(
    ({
        reason,
        tenant_id,
        onChange,
        value,
        administrations,
    }: {
        reason?: string;
        tenant_id?: string;
        onChange: (tenantId: string | number) => void;
        value: string;
        administrations?: AvailableAdministration[];
    }) => {
        const params = useParams<{ administrationId?: string }>();
        const {
            administrationStore: { administrationMeta, getAdministrationMeta, loading },
        } = useMobxStores();

        useEffect(() => {
            if (tenant_id && reason) {
                getAdministrationMeta(tenant_id, reason);
            }
        }, [tenant_id, reason]);

        useEffect(() => {
            if (params.administrationId && params.administrationId !== ':administrationId') {
                onChange(params.administrationId);
                getAdministrationMeta(tenant_id, reason);
            }
        }, []);

        const filterOptions = createFilterOptions({ stringify: (option: SearchOption) => option?.search || '' });

        let administrationInput;

        if (administrations) {
            administrationInput = administrations.find((e) => e.nr === Number(value));
        } else {
            administrationInput = administrationMeta.get(tenant_id)?.find((e) => e.administration_id === value);
        }

        return (
            <Grid item xs={12}>
                <Typography paragraph>
                    De op te vragen gegevens of de bewerking heeft betrekking tot een administratie. Specificeer deze:
                </Typography>
                <MnxAutocomplete
                    id="mnx-autocomplete-administration"
                    loading={loading}
                    filterOptions={filterOptions}
                    getOptionLabel={(option: AdministrationMeta) => option?.name || ''}
                    label="Selecteer een administratie"
                    values={administrations || administrationMeta.get(tenant_id) || []}
                    input={administrationInput || null}
                    onValueSelected={(value: AdministrationMeta | AvailableAdministration) => {
                        onChange(
                            (value as AvailableAdministration)?.nr ||
                                (value as AdministrationMeta)?.administration_id ||
                                '',
                        );
                    }}
                />
            </Grid>
        );
    },
);

export default AdministrationSelector;
