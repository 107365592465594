import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    box: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        background: '#f4f6f8',
    },
    text: {
        color: '#aaa',
    },
}));
const Unauthorized: React.FunctionComponent = () => {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <Container>
                <Paper elevation={3}>
                    <Box px={3} py={3}>
                        <h1>Sorry, je bent helaas nog niet bevoegd om gebruik te maken van deze functionaliteit.</h1>
                        <p>
                            We waarderen uiteraard wel je enthousiasme en toewijding. Misschien moet je even overleggen
                            met iemand als je denkt deze bevoegdheid wel te hebben?
                        </p>
                        <p className={classes.text}>* Toegang wordt automatisch gelogd.</p>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

Unauthorized.propTypes = {
    path: PropTypes.string,
};

export default Unauthorized;
