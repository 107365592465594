import React, { ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useParams } from 'react-router';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxAutocomplete from '../mnx-autocomplete';
import { SearchOption } from '../../stores/search';

const TenantSelector = observer(
    ({
        onChange,
        value,
        label,
        placeholder,
    }: {
        onChange: (tenantId: string) => void;
        value: string;
        label?: string;
        placeholder?: string;
    }) => {
        const params: { tenantId?: string } = useParams();
        const {
            searchStore: { tenantOptions, search, loading },
        } = useMobxStores();

        useEffect(() => {
            if (params.tenantId && params.tenantId !== ':tenantId') {
                onChange(params.tenantId);
                search(params.tenantId);
            }
        }, []);
        const filterOptions = createFilterOptions({ stringify: (option: SearchOption) => option.search });

        return (
            <MnxAutocomplete
                id="mnx-autocomplete-tenant"
                loading={loading}
                filterOptions={filterOptions}
                getOptionLabel={(option: SearchOption) => option?.value || ''}
                placeholder={placeholder}
                label={label ?? 'Selecteer een klant'}
                values={tenantOptions}
                input={tenantOptions.find((e) => e.id === value)}
                onValueSelected={(value: SearchOption) => {
                    onChange(value?.id || '');
                }}
                onSearchChange={(term: string) => {
                    search(term);
                    onChange('');
                }}
            />
        );
    },
);

export default TenantSelector;
