/* eslint-disable lines-between-class-members */
import { computed, action, makeObservable, observable } from 'mobx';
import autoBind from 'auto-bind';
import { IServerStoreApi, ServoyServer } from '../api/server';
import OAuthStore from './oauth';
import { GridRowId } from '@material-ui/data-grid';

export class ServerStore {
    data: ServoyServer[] = [];
    selected: GridRowId[] = [];
    loading = false;
    api: IServerStoreApi;
    oauthStore: OAuthStore;

    constructor({ api, oauthStore }: { api: IServerStoreApi; oauthStore: OAuthStore }) {
        makeObservable(this, {
            servers: computed,
            activate: action,
            deactivate: action,
            data: observable,
            selected: observable,
            getData: action,
            setMaintenanceMode: action,
            setSelected: action,
        });
        this.api = api;
        this.oauthStore = oauthStore;
        autoBind(this);
    }

    get servers(): ServoyServer[] {
        return this.data.map((Server) => ({
            ...Server,
            id: Server.server_id,
        }));
    }

    async getData(): Promise<void> {
        this.loading = true;
        try {
            this.data = await this.api.getActiveServers();
        } catch (error) {
            console.error(error);
        }
        this.loading = false;
    }

    async setMaintenanceMode(mode: 'enable' | 'disable'): Promise<boolean> {
        try {
            await this.api.setMaintenanceMode(mode);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async closeServoy(): Promise<boolean> {
        try {
            await this.api.closeServoy();
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    setSelected(selection: GridRowId[]): void {
        this.selected = selection;
    }

    activate(): void {
        this.getData();
    }

    deactivate(): void {
        console.error('Unimplemented');
    }
}
