import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxDataGrid from '../../components/data-grid';
import { toJS } from 'mobx';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { Chip } from '@material-ui/core';
import Buttons from './buttons';
import { useToaster } from '../../hooks/useToaster';

const group_restrictions_map: { [key: string]: string } = { single: 'Niet-accountants', accountant: 'Accountants' };

const columns: GridColDef[] = [
    {
        field: 'group_restrictions',
        headerName: 'Toegang',
        width: 150,
        renderCell: (params: GridCellParams) => (
            <Chip
                color={params?.value ? 'secondary' : 'primary'}
                label={params?.value ? group_restrictions_map[params?.value as string] : 'Onbeperkt'}
            />
        ),
    },
    { field: 'id', headerName: 'ID', width: 130 },
    { field: 'contact_email', headerName: 'Email', width: 200 },
    { field: 'description', headerName: 'Beschrijving', width: 200 },
    {
        field: 'tenant_restrictions',
        headerName: 'Toegestane tenants',
        flex: 1,
        renderCell: (params) => (
            <>
                {(params?.value as string)
                    ?.split(',')
                    ?.filter((e) => !!e)
                    ?.map((tenant) => (
                        <div key={tenant} style={{ paddingRight: '.5rem' }}>
                            <Chip color={'primary'} label={tenant} />
                        </div>
                    ))}
            </>
        ),
    },
    {
        field: 'rate_limit_enabled',
        headerName: 'Gebruikslimiet',
        width: 150,
        valueGetter: (params) => (params?.value ? 'Ja' : 'Nee'),
    },
    { field: 'rate_limit_maximum', headerName: '# Requests', width: 130 },
    { field: 'rate_limit_interval', headerName: 'Per ms', width: 110 },
    {
        field: 'rate_limit_violation',
        headerName: 'Laatste overtreding',
        width: 180,
    },
    {
        field: '',
        headerName: 'Acties',
        width: 120,
        renderCell: (params: GridCellParams) => <Buttons params={params} />,
    },
];

const Partners = observer(() => {
    const {
        partnerStore: { partners, activate, deactivate },
    } = useMobxStores();
    const { addToast } = useToaster();

    const [showGrid, setShowGrid] = useState(false);
    useEffect(() => {
        activate().then(() => {
            addToast('Informatie opgehaald');
        });
        setShowGrid(true);
        return () => {
            deactivate();
        };
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flexGrow: 1, width: '100%' }}>
                {showGrid && <MnxDataGrid rows={toJS(partners) || []} columns={columns} />}
            </div>
        </div>
    );
});

export default Partners;
