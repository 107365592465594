import request from '../utils/request';
import env from '../env';
import { ApiResult, createApiResult } from './apiResult';

export type User = {
    user_id: string;
    name: string;
    full_name: string;
    email: string;
};

export interface IUserStoreApi {
    getUser(userId: string, tenantId: string, reason: string): Promise<User>;
    search(term: string, tenantId: string, reason: string): Promise<User[]>;
    resetPassword(userId: string, tenantId: string, reason: string): Promise<ApiResult<void>>;
}

export class UserStoreApi implements IUserStoreApi {
    async getUser(userId: string, tenantId: string, reason: string): Promise<User> {
        return request('GET', `${env.domain}/admin/tenant/${tenantId}/user/${userId}`)
            .set({ 'x-reason': reason })
            .then((res) => res.body);
    }

    async search(term: string, tenantId: string, reason: string): Promise<User[]> {
        return request('GET', `${env.domain}/admin/tenant/${tenantId}/user/search/${term}`)
            .set({ 'x-reason': reason })
            .then((res) => res.body);
    }

    async resetPassword(userId: string, tenantId: string, reason: string): Promise<ApiResult<void>> {
        return createApiResult<void>(
            request('POST', `${env.domain}/admin/tenant/${tenantId}/user/${userId}/reset_password`).set({
                'x-reason': reason,
            }),
        );
    }
}
