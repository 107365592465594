import React from 'react';
import MnxDialog from '../../components/mnx-dialog';
import PropTypes from 'prop-types';

const CloseServoyPopup: React.FunctionComponent<{
    handleSubmit: () => void;
    handleClose: () => void;
}> = ({ handleSubmit, handleClose }) => (
    <MnxDialog title="Servoy afsluiten" handleClose={handleClose} handleSubmit={handleSubmit} open>
        <div>Weet je het zeker?</div>
    </MnxDialog>
);

CloseServoyPopup.propTypes = {
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
};

export default CloseServoyPopup;
