import request from '../utils/request';
import env from '../env';

export type Module = {
    module_id: string;
    name: string;
    description: string;
};

export interface IModuleApi {
    getMultiple(reason: string): Promise<Module[]>;
}

export class ModuleApi implements IModuleApi {
    async getMultiple(reason: string): Promise<Module[]> {
        return request('GET', `${env.domain}/admin/module`)
            .set({ 'x-reason': reason })
            .then((res) => res.body);
    }
}
