import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Paper } from '@material-ui/core';
import { useMobxStores } from '../../hooks/useMobxStores';
import { useToaster } from '../../hooks/useToaster';
import { Alert } from '@material-ui/lab';

const BizcuitDiagnostics = observer(() => {
    const {
        bizcuitDiagnosticsStore: { refresh, diagnostics },
    } = useMobxStores();

    const { addToast } = useToaster();

    useEffect(() => {
        refresh().then((apiResult) => {
            if (apiResult.result === 'ok') {
                addToast('Informatie opgehaald');
            }
            if (apiResult.result === 'unauthorized') {
                addToast(apiResult.message, {
                    variant: 'warning',
                });
            }
            if (['error', 'forbidden'].includes(apiResult.result)) {
                addToast(apiResult.message, { variant: 'error' });
            }
        });
    }, []);

    return (
        <Paper style={{ padding: 16 }} elevation={3}>
            <Grid container spacing={1} direction="column">
                {diagnostics?.map((diagnostic, index) =>
                    diagnostic.problems?.map((problem, problemIndex) => (
                        <Grid item>
                            <Alert key={`Alert_${index}_${problemIndex}`} severity={problem.type}>
                                {problem.text}
                            </Alert>
                        </Grid>
                    )),
                )}
            </Grid>
        </Paper>
    );
});

export default BizcuitDiagnostics;
