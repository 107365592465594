import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MnxDialog from '../../components/mnx-dialog';
import PropTypes from 'prop-types';

const SendMessagePopup: React.FunctionComponent<{
    handleSubmit: (message: string) => void;
    handleClose: () => void;
}> = ({ handleSubmit, handleClose }) => {
    const [message, setMessage] = useState('');
    return (
        <MnxDialog
            title="Stuur bericht aan sessies"
            handleClose={handleClose}
            handleSubmit={() => handleSubmit(message)}
            open
        >
            <TextField
                autoFocus
                multiline
                maxRows={4}
                margin="dense"
                id="reason"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                fullWidth
            />
        </MnxDialog>
    );
};

SendMessagePopup.propTypes = {
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
};

export default SendMessagePopup;
