import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Paper } from '@material-ui/core';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxButton from '../../components/mnx-button';
import { useToaster } from '../../hooks/useToaster';
import { AdministrationStoreApi } from '../../api/administration';

const FixSHStatus = observer(() => {
    const inputRef = useRef(null);
    const { addToast } = useToaster();
    const [api] = useState(new AdministrationStoreApi());

    const {
        applicationStore: { reason, tenantId, administrationId },
    } = useMobxStores();

    useEffect(() => {
        inputRef?.current?.focus();
    }, []);

    return (
        <>
            <Paper style={{ padding: 16 }} elevation={3}>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <p>
                            Dit onderdeel stelt je in staat om de statuscode van S&amp;H die onterecht op 'Bevestigd'
                            staat terug te zetten naar niet herkend als de detail regels hier missen. Alleen facturen
                            die bevestigd zijn maar niet detail regels hebben worden gewijzigd.
                        </p>
                    </Grid>
                </Grid>
                <Box pt={2}>
                    <Grid item>
                        <MnxButton
                            onClick={() => {
                                api.fixSHStatus(tenantId, administrationId, reason).then((apiResult) => {
                                    if (apiResult.result === 'ok' && apiResult?.value?.changed > 0) {
                                        addToast(
                                            `Voorstel succesvol gewijzigd: ${apiResult?.value?.changed} regels aangepast`,
                                            {
                                                variant: 'success',
                                            },
                                        );
                                    }
                                    if (apiResult.result === 'ok' && apiResult?.value?.changed <= 0) {
                                        addToast(`Voorstel niet gewijzigd: Er waren geen regels om aan te passen`, {
                                            variant: 'warning',
                                        });
                                    }
                                    if (apiResult.result === 'unauthorized') {
                                        addToast(apiResult.message, {
                                            variant: 'warning',
                                        });
                                    }
                                    if (['error', 'forbidden'].includes(apiResult.result)) {
                                        addToast(apiResult.message, { variant: 'error' });
                                    }
                                });
                            }}
                            disabled={!reason || !tenantId || !administrationId}
                            color="primary"
                        >
                            Onterecht 'Bevestigd' herstellen
                        </MnxButton>
                    </Grid>
                </Box>
            </Paper>
        </>
    );
});

export default FixSHStatus;
