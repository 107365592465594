import { useSnackbar, OptionsObject, SnackbarMessage, SnackbarKey } from 'notistack';

export function useToaster(): { addToast: (message: SnackbarMessage, options?: OptionsObject) => void } {
    const { enqueueSnackbar } = useSnackbar();

    return {
        addToast: (message: SnackbarMessage, options?: OptionsObject): SnackbarKey =>
            enqueueSnackbar(message, { anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, ...(options || {}) }),
    };
}
