import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from '@material-ui/core';
import { useMobxStores } from '../../hooks/useMobxStores';
import { useToaster } from '../../hooks/useToaster';
import { Alert, Color } from '@material-ui/lab';
import { LoginInformation } from '../../api/loginInformation';
import { ApiResult } from '../../api/apiResult';
import MnxTextField from '../../components/mnx-textfield';
import MnxButton from '../../components/mnx-button';
import MnxToolbar from '../../components/mnx-toolbar';
import moment from 'moment';

const LoginInformationView = observer(() => {
    const {
        loginInformationStore: {
            refresh,
            loginInformation,
            setType,
            setText,
            setLink,
            setLinkDisplay,
            setStartDate,
            setEndDate,
            update,
            remove,
        },
    } = useMobxStores();
    const { addToast } = useToaster();
    const theme = useTheme();

    useEffect(() => {
        refresh().then((apiResult: ApiResult<LoginInformation>) => {
            if (apiResult.result === 'ok') {
                addToast('Informatie opgehaald');
            }
            if (apiResult.result === 'unauthorized') {
                addToast(apiResult.message, {
                    variant: 'warning',
                });
            }
            if (['error', 'forbidden'].includes(apiResult.result)) {
                addToast(apiResult.message, { variant: 'error' });
            }
        });
    }, []);

    const convertLoginInformationTypeToAlertType = (type: number): Color => {
        switch (type) {
            case 0:
                return 'info';
            case 1:
                return 'info';
            case 2:
                return 'warning';
            case 3:
                return 'error';
            default:
                return 'info';
        }
    };

    return (
        <Paper style={{ padding: 16 }} elevation={3}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Soort melding</FormLabel>
                        <RadioGroup
                            aria-label="type"
                            name="type"
                            row
                            value={loginInformation?.type?.toFixed()}
                            onChange={(event) => {
                                setType(parseInt(event.target.value, 10));
                            }}
                        >
                            <FormControlLabel value="0" control={<Radio />} label="Innovatie" />
                            <FormControlLabel value="1" control={<Radio />} label="Generiek" />
                            <FormControlLabel value="2" control={<Radio />} label="Belangrijk" />
                            <FormControlLabel value="3" control={<Radio />} label="Problemen" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <MnxTextField
                        label={'Tekst'}
                        value={loginInformation.text}
                        onChange={(event) => setText(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MnxTextField
                        label={'Link'}
                        value={loginInformation.link}
                        onChange={(event) => setLink(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MnxTextField
                        label={'Link text'}
                        value={loginInformation.linkdisplay}
                        onChange={(event) => setLinkDisplay(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MnxTextField
                        id="datetime-local"
                        label="Zichtbaar vanaf"
                        type="datetime-local"
                        onChange={(event) => setStartDate(event.target.value)}
                        defaultValue={moment(loginInformation?.startdate).format().substring(0, 16)}
                        // className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MnxTextField
                        id="datetime-local"
                        label="Zichtbaar tot en met"
                        type="datetime-local"
                        onChange={(event) => setEndDate(event.target.value)}
                        defaultValue={moment(loginInformation?.enddate).format().substring(0, 16)}
                        // className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box mt={4}>
                        <Typography>Voorbeeld:</Typography>
                        {loginInformation && (
                            <Alert severity={convertLoginInformationTypeToAlertType(loginInformation.type)}>
                                {loginInformation.text}
                            </Alert>
                        )}
                    </Box>
                </Grid>
                <Box mt={4}>
                    <MnxToolbar>
                        <MnxButton
                            key={'1'}
                            color="primary"
                            onClick={() => {
                                update().then((success) => {
                                    if (success) {
                                        addToast('Melding bijgewerkt!', { variant: 'success' });
                                    } else {
                                        addToast('Melding kon niet worden bijgewerkt!', { variant: 'error' });
                                    }
                                });
                            }}
                        >
                            Melding activeren
                        </MnxButton>
                        <MnxButton
                            key={'2'}
                            mnxColor={theme.palette.error.main}
                            onClick={() => {
                                remove().then((success) => {
                                    if (success) {
                                        addToast('Melding verwijderd!', { variant: 'success' });
                                    } else {
                                        addToast('De melding kon niet worden verwijderd!', { variant: 'error' });
                                    }
                                });
                            }}
                        >
                            Melding verwijderen
                        </MnxButton>
                    </MnxToolbar>
                </Box>
            </Grid>
        </Paper>
    );
});

export default LoginInformationView;
