import request from '../utils/request';
import env from '../env';
import { ApiResult, createApiResult } from './apiResult';

export type LoginInformation = {
    type: number;
    text: string;
    link: string;
    startdate: Date;
    enddate: Date;
    linkdisplay: string;
};

export interface ILoginInformationApi {
    getLoginInformation(reason: string): Promise<ApiResult<LoginInformation>>;
    setLoginInformation(reason: string, information: LoginInformation): Promise<ApiResult<LoginInformation>>;
    removeLoginInformation(reason: string): Promise<ApiResult<void>>;
}

export class LoginInformationApi implements ILoginInformationApi {
    getLoginInformation(reason: string): Promise<ApiResult<LoginInformation>> {
        return createApiResult(request('GET', `${env.domain}/admin/logininformation`).set({ 'x-reason': reason }));
    }
    setLoginInformation(reason: string, information: LoginInformation): Promise<ApiResult<LoginInformation>> {
        return createApiResult(
            request('PUT', `${env.domain}/admin/logininformation`).set({ 'x-reason': reason }).send(information),
        );
    }
    removeLoginInformation(reason: string): Promise<ApiResult<void>> {
        return createApiResult(request('DELETE', `${env.domain}/admin/logininformation`).set({ 'x-reason': reason }));
    }
}
