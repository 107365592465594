import request from '../utils/request';
import env from '../env';
import { ApiResult, createApiResult } from './apiResult';

export type InternalInvoiceStatus = {
    done: number;
    error: number;
    pending: number;
};
export interface IInternalInvoiceApi {
    upload(files: File[], reason: string): Promise<ApiResult<undefined>>;
    status(reason: string): Promise<ApiResult<InternalInvoiceStatus>>;
}

export class InternalInvoiceApi implements IInternalInvoiceApi {
    async upload(files: File[], reason: string): Promise<ApiResult<undefined>> {
        const formData = new FormData();
        for (const key in files) {
            if (files.hasOwnProperty(key) && files[key] instanceof File) {
                formData.append('data', files[key]);
            }
        }

        return createApiResult(
            request('POST', `${env.domain}/admin/sales/internalinvoice`).set({ 'x-reason': reason }).send(formData),
        );
    }

    async status(reason: string): Promise<ApiResult<InternalInvoiceStatus>> {
        return createApiResult(request('GET', `${env.domain}/admin/sales/internalinvoice`).set({ 'x-reason': reason }));
    }
}
