/* eslint-disable lines-between-class-members */
import { computed, action, makeObservable, observable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { GridRowId } from '@material-ui/data-grid';
import { IQosErrorStoreApi, QosError } from '../api/qosError';
import ApplicationStore from './application';
import { TenantStore } from './tenant';

const filterUnique = <T>(value: T, index: number, self: T[]) => {
    return self.indexOf(value) === index;
};

export class QosErrorStore {
    data: QosError[] = [];
    selected: GridRowId[] = [];
    loading = false;
    api: IQosErrorStoreApi;
    applicationStore: ApplicationStore;
    tenantStore: TenantStore;

    constructor({
        api,
        applicationStore,
        tenantStore,
    }: {
        api: IQosErrorStoreApi;
        applicationStore: ApplicationStore;
        tenantStore: TenantStore;
    }) {
        makeObservable(this, {
            qosErrors: computed,
            data: observable,
            loading: observable,
            selected: observable,
            setSelected: action,
            getData: action,
            tenants: computed,
            repairError: action,
        });
        this.api = api;
        this.applicationStore = applicationStore;
        this.tenantStore = tenantStore;
        autoBind(this);
    }

    get qosErrors(): QosError[] {
        if (!this.data?.length) return [];
        return this.data.map((error) => {
            let owner_name;
            if (error.ownerId) {
                const tenant = this.tenantStore.simpleTenants.get(error.ownerId);
                owner_name = tenant ? `(${tenant.tenant_nr}) ${tenant.name}` : error.ownerId;
            }
            return { ...error, owner_name };
        });
    }

    async getData(from?: Date, to?: Date, type?: string, tenant_id?: string): Promise<void> {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const qosErrors = await this.api.getQosErrors(this.applicationStore.reason, from, to, type, tenant_id);
            runInAction(() => {
                this.data = qosErrors;
            });
            this.tenants.forEach((ownerid) => {
                if (!this.tenantStore.simpleTenants.has(ownerid)) {
                    this.tenantStore.getSimpleTenant(ownerid);
                }
            });
        } catch (error) {
            console.error(error);
        }
        runInAction(() => {
            this.loading = false;
        });
    }

    get tenants(): string[] {
        if (!this.data) return null;

        return this.data.map((qosError) => qosError.ownerId).filter(filterUnique);
    }

    async repairError(id: string): Promise<void> {
        const target = this.data.find((error) => error.id === id);
        if (!target || target.fixed || !target.fixable) {
            console.error('Could not repair error:', target);
            return;
        }
        try {
            await this.api.repairQosError(this.applicationStore.reason, target.ownerId, target.type);
        } catch (error) {
            console.error(error);
        }
    }

    setSelected(selection: GridRowId[]): void {
        this.selected = selection;
    }
}
