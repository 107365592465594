import { observable, action, makeObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { TenantStore } from './tenant';
import { Tenant } from '../api/tenant';
import { Administration } from '../api/administration';
import { AdministrationStore } from './administration';

export default class ApplicationStore {
    reason = '';
    tenant: Tenant;
    tenantId: string;
    tenantStore: TenantStore;
    administrationStore: AdministrationStore;
    timeout_id: NodeJS.Timeout;
    administration: Administration;
    administrationId: string;
    loadingTenant = false;
    isDarkMode = false;

    constructor({
        tenantStore,
        administrationStore,
    }: {
        tenantStore: TenantStore;
        administrationStore: AdministrationStore;
    }) {
        this.tenantStore = tenantStore;
        this.administrationStore = administrationStore;
        makeObservable(this, {
            reason: observable,
            tenant: observable,
            tenantId: observable,
            isDarkMode: observable,
            administration: observable,
            administrationId: observable,
            loadingTenant: observable,
            setReason: action,
            setTenant: action,
            setTenantId: action,
            setAdministration: action,
            setAdministrationId: action,
            setIsDarkMode: action,
        });
        autoBind(this);
    }

    setReason(reason: string): void {
        this.reason = reason;
        if (!reason || reason.length < 10) {
            this.setTenantId(null);
        }
    }

    async setTenant(): Promise<void> {
        if (!this.tenantId) {
            runInAction(() => {
                this.setAdministrationId(null);
                this.tenant = null;
            });
            return;
        }
        runInAction(() => {
            this.loadingTenant = true;
        });
        const tenant = await this.tenantStore.getTenant(this.tenantId, this.reason);
        runInAction(() => {
            this.loadingTenant = false;
        });
        if (!tenant) return;
        runInAction(() => {
            this.tenant = tenant;
        });
    }

    setTenantId(tenantId: string): void {
        this.tenantId = tenantId;
        this.setTenant();
    }

    async setAdministration(): Promise<void> {
        if (!this.administrationId) {
            runInAction(() => {
                this.administration = null;
            });
            return;
        }
        const administration = await this.administrationStore.getAdministration(
            this.tenantId,
            this.administrationId,
            this.reason,
        );
        if (!administration) return;
        runInAction(() => {
            this.administration = administration;
        });
    }

    setAdministrationId(administrationId: string): void {
        this.administrationId = administrationId;
        this.setAdministration();
    }

    setIsDarkMode(mode: boolean): void {
        this.isDarkMode = mode;
    }
}
