/* eslint-disable lines-between-class-members */
import { action, makeObservable, observable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { ITenantStoreApi, Tenant, SimpleTenant } from '../api/tenant';
import { IModuleApi, Module } from '../api/module';

const isEdition = (modules: Module[] = [], activeModules: string[] = []) => {
    const editionNames = ['C01', 'C02', 'C03', 'C04', 'C05', 'C06', 'C07', 'C08', 'C09'];
    const editions = modules
        .filter((e) => editionNames.includes(e.name))
        .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
    const editionModulesInLicense = editions.filter(
        (editionModule) => !!activeModules.find((e) => editionModule.module_id.toUpperCase() === e.toUpperCase()),
    );
    return editionModulesInLicense.pop();
};

export class TenantStore {
    loading = false;
    api: ITenantStoreApi;
    moduleApi: IModuleApi;
    tenants: Map<string, Tenant>;
    simpleTenants: Map<string, SimpleTenant>;
    modules: Map<string, Module>;

    constructor({ api, moduleApi }: { api: ITenantStoreApi; moduleApi: IModuleApi }) {
        makeObservable(this, {
            loading: observable,
            activate: action,
            deactivate: action,
            getData: action,
            getModules: action,
            tenants: observable,
            simpleTenants: observable,
            modules: observable,
        });
        this.api = api;
        this.moduleApi = moduleApi;
        this.tenants = new Map();
        this.simpleTenants = new Map();
        this.modules = new Map();
        autoBind(this);
    }

    async getData(): Promise<void> {
        this.loading = true;
        const tenants = await this.api.getTenants().finally(() => {
            this.loading = false;
        });
        runInAction(() => {
            tenants.forEach((tenant) => {
                this.tenants.set(tenant.tenant_id, tenant);
            });
        });
    }

    async getTenant(tenantId: string, reason: string): Promise<Tenant> {
        if (!this.modules.size) {
            await this.getModules(reason);
        }
        if (this.tenants.has(tenantId)) {
            return this.tenants.get(tenantId);
        }
        this.loading = true;
        const tenant = await this.api.getTenant(tenantId, reason).finally(() => {
            this.loading = false;
        });
        if (tenant) {
            tenant.edition = isEdition(Array.from(this.modules.values()), tenant.modules);
            runInAction(() => {
                this.tenants.set(tenant.tenant_id, tenant);
            });
        }

        return tenant;
    }

    async getSimpleTenant(tenantId: string): Promise<SimpleTenant> {
        if (!tenantId) return;

        const tenant = await this.api.getSimpleTenant(tenantId);
        if (tenant) {
            runInAction(() => {
                this.simpleTenants.set(tenantId, tenant);
            });
        }
        return tenant;
    }

    async getModules(reason: string): Promise<void> {
        const modules = await this.moduleApi.getMultiple(reason);
        runInAction(() => {
            modules.forEach((module) => {
                this.modules.set(module.module_id?.toLowerCase(), module);
            });
        });
    }

    activate(): void {
        console.error('Unimplemented');
    }

    deactivate(): void {
        console.error('Unimplemented');
    }
}
