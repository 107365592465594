import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MnxDialog from '../../components/mnx-dialog';
import PropTypes from 'prop-types';

const DisconnectClientsPopup: React.FunctionComponent<{
    selectedClients: string[];
    handleSubmit: () => void;
    handleClose: () => void;
}> = ({ selectedClients, handleSubmit, handleClose }) => {
    return (
        <MnxDialog title="Sessies sluiten" handleClose={handleClose} handleSubmit={handleSubmit} open>
            {selectedClients.length ? (
                <div>
                    <div>Weet je zeker dat je de volgende sessies wilt sluiten:</div>
                    <List dense>
                        {selectedClients.map((client) => (
                            <ListItem key={client}>
                                <ListItemText primary={client} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            ) : (
                <div>Weet je zeker dat je de alle sessies wilt sluiten?</div>
            )}
        </MnxDialog>
    );
};

DisconnectClientsPopup.propTypes = {
    selectedClients: PropTypes.arrayOf(PropTypes.string),
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
};

export default DisconnectClientsPopup;
