import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxButton from '../../components/mnx-button';
import { useToaster } from '../../hooks/useToaster';
import { Box, Card, Typography } from '@material-ui/core';
import { InternalInvoiceStatus } from '../../api/internalinvoice';
import Alert from '@material-ui/lab/Alert';
import CreateBillingInfoPopup from './createBillingInfoPopup';
import { BillingInformation, OldBillingInformation } from '../../api/miscellaneous';

const Misc = observer(() => {
    const { addToast } = useToaster();
    const [currentStatus, setStatus] = useState<InternalInvoiceStatus>({ done: 0, pending: 0, error: 0 });
    const {
        internalInvoiceStore: { uploadCSV, status },
        miscStore: { sendBilling },
    } = useMobxStores();

    const updateStatus = async () => {
        const data = await status();
        setStatus(data.value);
    };

    const [popup, setPopup] = useState(undefined);

    useEffect(() => {
        updateStatus();
        const cancelToken = setInterval(updateStatus, 5000);
        return () => {
            clearInterval(cancelToken);
        };
    }, []);

    const uploadInputRef = useRef(null);
    const onChange = ({ target }: any) => {
        uploadCSV(target.files).then((success) => {
            if (success) {
                addToast('Working...', { variant: 'info' });
            } else {
                addToast('Upload failed', { variant: 'error' });
            }
        });
    };

    let alert = <></>;
    if (!currentStatus?.pending && !currentStatus?.error && currentStatus?.done) {
        alert = <Alert severity="success">Minox is done creating all the draft invoices.</Alert>;
    }

    if (currentStatus?.error) {
        alert = (
            <Alert severity="error">
                Some of the draft invoices could not be created. Consult development for details.
            </Alert>
        );
    }

    if (currentStatus?.pending) {
        alert = (
            <Alert severity="info">
                Minox is busy converting invoice data info draft invoices. Please wait. (Still working on
                {currentStatus?.pending?.toFixed(0) ?? 'an unknown number of'} draft invoices)
            </Alert>
        );
    }

    return (
        <>
            {popup === 'create_billing_information' && (
                <CreateBillingInfoPopup
                    handleSubmit={(information: OldBillingInformation | BillingInformation, type: 'old' | 'new') => {
                        sendBilling(information, type).then((success) => {
                            if (success) {
                                addToast('Invoice CSV is being created.', { variant: 'success' });
                            } else {
                                addToast('There was a problem while creating the invoice CSV.', { variant: 'error' });
                            }
                        });
                        setPopup(null);
                    }}
                    handleClose={() => setPopup(null)}
                />
            )}

            <Box mb={2}>
                {alert}
                <Card>
                    <Box m={2}>
                        <Typography>
                            Upload CSV files which are automatically converted into draft invoices in the Minox company
                            administration.
                        </Typography>
                        <Box pt={2}>
                            <input
                                ref={uploadInputRef}
                                type="file"
                                accept="text/csv"
                                style={{ display: 'none' }}
                                onChange={onChange}
                            />
                            <MnxButton
                                key={'23'}
                                color="primary"
                                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                            >
                                Upload invoice CSV
                            </MnxButton>
                        </Box>
                    </Box>
                </Card>
            </Box>

            <Box mb={2}>
                <Card>
                    <Box m={2}>
                        <Typography>
                            Normally, Minox Online automatically creates the Pay Per Use invoice on a monthly schedule.
                            Using this button you can manually create an intermediate CSV file.
                        </Typography>
                        <Box pt={2}>
                            <MnxButton
                                key={'1'}
                                color="primary"
                                onClick={() => {
                                    setPopup('create_billing_information');
                                }}
                            >
                                Create Pay Per Use Invoice CSV
                            </MnxButton>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    );
});

export default Misc;
