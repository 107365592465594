import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, GridItemsAlignment } from '@material-ui/core';

const propTypes = {
    mode: PropTypes.oneOf(['classic', 'new']),
    className: PropTypes.string,
    gridProps: PropTypes.shape({
        alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
    }),
    children: PropTypes.node,
};

export type MnxToolbarProps = PropTypes.InferProps<typeof propTypes>;

const MnxToolbar = (props: MnxToolbarProps) => {
    const children = React.Children.toArray(props.children as ReactNode) as React.ReactElement[];
    return (
        <Box pb={props.mode === 'classic' ? 0 : 2} className={props.className}>
            <Grid container spacing={1} direction="row" alignItems={props.gridProps?.alignItems as GridItemsAlignment}>
                {children.map((child, index) => (
                    <Grid item key={`${child.key}_${index}`}>
                        {child}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

MnxToolbar.propTypes = propTypes;

export default MnxToolbar;
