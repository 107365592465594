import request from '../utils/request';
import env from '../env';
import { ApiResult, createApiResult } from './apiResult';

export type Problem = {
    type: 'info' | 'warning' | 'error';
    text: string;
};

export type Diagnostics = {
    name?: string;
    problems: Problem[];
};

export interface IBizcuitDiagnosticsApi {
    getDiagnostics(tenantId: string, administrationId: string, reason: string): Promise<ApiResult<Diagnostics[]>>;
}

export class BizcuitDiagnosticsApi implements IBizcuitDiagnosticsApi {
    getDiagnostics(tenantId: string, administrationId: string, reason: string): Promise<ApiResult<Diagnostics[]>> {
        return createApiResult(
            request(
                'POST',
                `${env.domain}/admin/tenant/${tenantId}/administration/${administrationId}/bizcuit_diagnostics`,
            ).set({ 'x-reason': reason }),
        );
    }
}
