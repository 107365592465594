import React from 'react';
import { UserRole } from './stores/oauth';
import StorageIcon from '@material-ui/icons/Storage';
import GroupIcon from '@material-ui/icons/Group';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import EuroIcon from '@material-ui/icons/Euro';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import LinkIcon from '@material-ui/icons/Link';
import ErrorIcon from '@material-ui/icons/Error';
import { Backspace, RotateLeft, SyncProblem, Business, Sort } from '@material-ui/icons';

export type Route = {
    path: string;
    requiredRoles: UserRole[];
    title: string;
    search: string;
    icon: React.ReactNode;
};

export const defaultRoutes: Route[] = [
    { title: 'Dashboard', search: 'dashboard home', requiredRoles: [], path: '/', icon: <HomeIcon /> },
];

export const managementRoutes: Route[] = [
    {
        title: 'RBS koppelen met Minox',
        search: 'minox rbs oauth koppel',
        requiredRoles: ['management'],
        path: '/sales/linkrbswithminox',
        icon: <LinkIcon />,
    },
];

export const salesRoutes: Route[] = [
    {
        title: 'Facturatie',
        search: 'csv billing sales facturatie',
        requiredRoles: ['sales'],
        path: '/sales/internalinvoice',
        icon: <EuroIcon />,
    },
];

export const customerSupportRoutes: Route[] = [
    {
        title: 'Klant informatie',
        search: 'info klant tenant informatie',
        requiredRoles: ['cs'],
        path: '/tenant/:tenantId',
        icon: <PersonIcon />,
    },
    {
        title: 'Wachtwoord resetten',
        search: 'reset wachtwoord resetten',
        requiredRoles: ['cs'],
        path: '/tenant/:tenantId/resetpassword',
        icon: <RotateLeft />,
    },
    {
        title: 'Openstaande posten deblokkeren',
        search: 'openstaande posten deblokkeren',
        requiredRoles: ['cs'],
        path: '/tenant/:tenantId/administration/:administrationId/unblock_payables',
        icon: <Backspace />,
    },
    {
        title: 'S&H voorstel status fix',
        search: 'scan herken status',
        requiredRoles: ['cs'],
        path: '/tenant/:tenantId/administration/:administrationId/fix_sh_statuscodes',
        icon: <Backspace />,
    },
    {
        title: 'Dagboeken deblokkeren',
        search: 'dagboek dagboeken deblokkeren',
        requiredRoles: ['cs'],
        path: '/tenant/:tenantId/administration/:administrationId/unblock_journals',
        icon: <Backspace />,
    },
    {
        title: 'Dagboek hersorteren',
        search: 'dagboek dagboeken hersorteren',
        requiredRoles: ['cs'],
        path: '/tenant/:tenantId/administration/:administrationId/reorder_journal_page',
        icon: <Sort />,
    },
    {
        title: 'Bizcuit connectie diagnose',
        search: 'bizcuit diagnose diagnostics',
        requiredRoles: ['cs'],
        path: '/tenant/:tenantId/administration/:administrationId/bizcuit_diagnostics',
        icon: <SyncProblem />,
    },
    {
        title: 'Kvk vrijgeven',
        search: 'kvk vrijgeven van klant',
        requiredRoles: ['cs'],
        path: '/go2ubl',
        icon: <Business />,
    },
];

export const operationsRoutes: Route[] = [
    {
        title: 'Servoy servers',
        path: '/servers',
        search: 'servoy servers server',
        requiredRoles: ['operations'],
        icon: <StorageIcon />,
    },
    {
        title: 'Servoy sessies',
        path: '/clients',
        search: 'servoy sessies sessions',
        requiredRoles: ['operations'],
        icon: <PeopleIcon />,
    },
    { title: 'API log', path: '/apilog', search: 'api log rest', requiredRoles: ['operations'], icon: <ListIcon /> },
    {
        title: 'API Partners',
        path: '/partners',
        search: 'partners api clients',
        requiredRoles: ['operations'],
        icon: <GroupIcon />,
    },
    {
        title: 'Login informatie',
        path: '/logininformation',
        search: 'login info informatie',
        requiredRoles: ['operations'],
        icon: <ListIcon />,
    },
    {
        title: 'Misc.',
        path: '/misc',
        search: 'miscellaneous',
        requiredRoles: ['operations'],
        icon: <DeveloperModeIcon />,
    },
    {
        title: 'QOS Errors',
        path: '/qos_errors',
        search: 'qos errors',
        requiredRoles: ['operations'],
        icon: <ErrorIcon />,
    },
];
