import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import PropTypes from 'prop-types';

type MnxButtonProps = {
    mnxColor?: string;
} & ButtonProps;

const MnxButton: React.FunctionComponent<MnxButtonProps> = ({ mnxColor, ...props }) => {
    return (
        <Button
            size={props.size ?? 'medium'}
            style={{ backgroundColor: mnxColor }}
            color={props.color ?? 'secondary'}
            variant={props.variant ?? 'contained'}
            {...props}
        >
            {props.children}
        </Button>
    );
};

MnxButton.propTypes = {
    children: PropTypes.any,
};

export default MnxButton;
