/* eslint-disable lines-between-class-members */
import { action, makeObservable, observable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import ApplicationStore from './application';
import { ApiResult, ErrorApiResult } from '../api/apiResult';
import { ILoginInformationApi, LoginInformation } from '../api/loginInformation';
import moment from 'moment-timezone';

const defaultLoginInformation = {
    type: 0,
    text: '',
    link: '',
    startdate: new Date(),
    enddate: new Date(),
    linkdisplay: '',
};

export class LoginInformationStore {
    loading = false;
    api: ILoginInformationApi;
    applicationStore: ApplicationStore;
    loginInformation: LoginInformation;

    constructor({ api, applicationStore }: { api: ILoginInformationApi; applicationStore: ApplicationStore }) {
        makeObservable(this, {
            refresh: action,
            loginInformation: observable,
            setType: action,
            setText: action,
            setLink: action,
            setLinkDisplay: action,
            setStartDate: action,
            setEndDate: action,
        });
        this.api = api;
        this.loginInformation = defaultLoginInformation;
        this.applicationStore = applicationStore;
        autoBind(this);
    }

    setType(type: number): void {
        this.loginInformation.type = type;
    }

    setText(text: string): void {
        this.loginInformation.text = text;
    }

    setLink(text: string): void {
        this.loginInformation.link = text;
    }

    setLinkDisplay(text: string): void {
        this.loginInformation.linkdisplay = text;
    }

    setStartDate(text: string): void {
        this.loginInformation.startdate = new Date(text);
    }

    setEndDate(text: string): void {
        this.loginInformation.enddate = new Date(text);
    }

    async update(): Promise<ApiResult<LoginInformation>> {
        const { reason } = this.applicationStore;
        if (!reason) return ErrorApiResult;
        return this.api.setLoginInformation(reason, this.loginInformation);
    }

    async remove(): Promise<ApiResult<void>> {
        const { reason } = this.applicationStore;
        if (!reason) return ErrorApiResult;
        return this.api.removeLoginInformation(reason);
    }

    async refresh(): Promise<ApiResult<LoginInformation>> {
        const { reason } = this.applicationStore;
        if (!reason) return ErrorApiResult;
        return this.api.getLoginInformation(reason).then((result: ApiResult<LoginInformation>) => {
            runInAction(() => {
                this.loginInformation =
                    result.value && result.value?.type
                        ? {
                              ...result.value,
                              startdate: moment(result.value.startdate).toDate(),
                              enddate: moment(result.value.enddate).toDate(),
                          }
                        : defaultLoginInformation;
            });
            return result;
        });
    }
}
