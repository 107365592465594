import React, { createContext, useContext } from 'react';
import OAuthStore from '../stores/oauth';
import UIStore from '../stores/ui';
import ApplicationStore from '../stores/application';
import NavigationStore from '../stores/navigation';
import { ReactNode } from 'react';
import { Context } from 'react';
import { InternalInvoiceApi } from '../api/internalinvoice';
import { ClientStoreApi } from '../api/client';
import { ClientStore } from '../stores/clients';
import { ServerStoreApi } from '../api/server';
import { ServerStore } from '../stores/server';
import { MiscStore } from '../stores/miscellaneous';
import { TenantStore } from '../stores/tenant';
import { TenantStoreApi } from '../api/tenant';
import { ApiLogStore } from '../stores/apilog';
import { ApiLogApi } from '../api/apilog';
import SearchStore from '../stores/search';
import { LoginInformationStore } from '../stores/loginInformation';
import { SearchApi } from '../api/search';
import { UserStore } from '../stores/user';
import { UserStoreApi } from '../api/user';
import { ModuleApi } from '../api/module';
import { BizcuitDiagnosticsStore } from '../stores/bizcuitDiagnostics';
import { AdministrationStore } from '../stores/administration';
import { AdministrationStoreApi } from '../api/administration';
import { MiscApi } from '../api/miscellaneous';
import { BizcuitDiagnosticsApi } from '../api/bizcuitDiagnostics';
import { LoginInformationApi } from '../api/loginInformation';
import { PartnerStoreApi } from '../api/partner';
import { PartnerStore } from '../stores/partner';
import { QosErrorStore } from '../stores/qosError';
import { QosErrorStoreApi } from '../api/qosError';
import { InternalInvoiceStore } from '../stores/internalinvoice';
import { InternalAdministrationStore } from '../stores/internaladministration';
import { InternalAdministrationApi } from '../api/internaladministration';

class RootStore {
    navigationStore: NavigationStore;
    oauthStore: OAuthStore;
    uiStore: UIStore;
    applicationStore: ApplicationStore;
    clientStore: ClientStore;
    serverStore: ServerStore;
    tenantStore: TenantStore;
    administrationStore: AdministrationStore;
    apiLogStore: ApiLogStore;
    searchStore: SearchStore;
    userStore: UserStore;
    miscStore: MiscStore;
    bizcuitDiagnosticsStore: BizcuitDiagnosticsStore;
    loginInformationStore: LoginInformationStore;
    partnerStore: PartnerStore;
    qosErrorStore: QosErrorStore;
    internalInvoiceStore: InternalInvoiceStore;
    internalAdministrationStore: InternalAdministrationStore;

    constructor() {
        this.navigationStore = new NavigationStore();
        this.oauthStore = new OAuthStore(this.navigationStore);
        this.uiStore = new UIStore();
        this.tenantStore = new TenantStore({
            api: new TenantStoreApi(),
            moduleApi: new ModuleApi(),
        });
        this.administrationStore = new AdministrationStore({
            api: new AdministrationStoreApi(),
        });
        this.applicationStore = new ApplicationStore({
            tenantStore: this.tenantStore,
            administrationStore: this.administrationStore,
        });
        this.internalInvoiceStore = new InternalInvoiceStore({
            applicationStore: this.applicationStore,
            api: new InternalInvoiceApi(),
        });
        this.serverStore = new ServerStore({
            oauthStore: this.oauthStore,
            api: new ServerStoreApi(),
        });
        this.partnerStore = new PartnerStore({
            applicationStore: this.applicationStore,
            api: new PartnerStoreApi(),
        });
        this.apiLogStore = new ApiLogStore({
            api: new ApiLogApi(),
            applicationStore: this.applicationStore,
        });
        this.searchStore = new SearchStore({
            searchApi: new SearchApi(),
            navigationStore: this.navigationStore,
            oauthStore: this.oauthStore,
            serverStore: this.serverStore,
            applicationStore: this.applicationStore,
        });
        this.clientStore = new ClientStore({
            oauthStore: this.oauthStore,
            api: new ClientStoreApi(),
            serverStore: this.serverStore,
            tenantStore: this.tenantStore,
            applicationStore: this.applicationStore,
        });
        this.userStore = new UserStore({
            api: new UserStoreApi(),
            oauthStore: this.oauthStore,
        });
        this.miscStore = new MiscStore({
            api: new MiscApi(),
            applicationStore: this.applicationStore,
        });
        this.bizcuitDiagnosticsStore = new BizcuitDiagnosticsStore({
            api: new BizcuitDiagnosticsApi(),
            applicationStore: this.applicationStore,
        });
        this.loginInformationStore = new LoginInformationStore({
            api: new LoginInformationApi(),
            applicationStore: this.applicationStore,
        });
        this.qosErrorStore = new QosErrorStore({
            api: new QosErrorStoreApi(),
            applicationStore: this.applicationStore,
            tenantStore: this.tenantStore,
        });
        this.internalAdministrationStore = new InternalAdministrationStore({
            api: new InternalAdministrationApi(),
            applicationStore: this.applicationStore,
        });
    }
}

export const rootStore: RootStore = new RootStore();
const StoreContext: Context<RootStore> = createContext<RootStore>(rootStore);

export function StoreProvider({ children }: { children: ReactNode }): JSX.Element {
    return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
}

export function useMobxStores(): RootStore {
    return useContext(StoreContext);
}
