import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { StoreProvider } from './hooks/useMobxStores';
import Routing from './views/routing';
import './styles/globals.css';
import ThemeWrapper from './components/theme';

const App = () => {
    return (
        <>
            <StoreProvider>
                <ThemeWrapper>
                    <SnackbarProvider maxSnack={3}>
                        <Routing />
                    </SnackbarProvider>
                </ThemeWrapper>
            </StoreProvider>
        </>
    );
};

ReactDOM.render(<App />, document.querySelector('#root'));
