import React, { BaseSyntheticEvent, useEffect, useState, useCallback } from 'react';
import { GridCellParams, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxDataGrid from '../../components/data-grid';
import { errorTypes, QosError } from '../../api/qosError';
import MnxButton from '../../components/mnx-button';
import TenantSelector from '../../components/authorization/tenantSelector';
import QosErrorButtons from './buttons';
import XLSX from 'xlsx';

const dateInputFormat = (date: Date): string => (date ? date.toISOString().slice(0, 16) : '');

const datePresentationFormat = new Intl.DateTimeFormat('nl-NL', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 250,
    },
    toolbar: {
        flexGrow: 0,
    },
    searchIcon: {
        paddingLeft: theme.spacing(1),
    },
    paper: {
        background: theme.palette.background.paper,
        padding: theme.spacing(1.5),
        flex: '0 0 auto',
        display: 'flex',
    },
    search_field: {
        marginRight: theme.spacing(2),
    },
    box: {
        maxWidth: '90vw',
        paddingBottom: theme.spacing(2),
    },
}));

const exportToExcel = (selected: QosError[]) => {
    const filename = `QOS Errors ${new Date().toISOString()}.xlsx`;
    const data = [
        Object.keys(selected[0]),
        ...selected.map((object: QosError) => {
            return Object.values({ ...object, error: JSON.stringify(object.error || {}) });
        }),
    ];
    const ws_name = 'QOS Errors';
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);

    function fitToColumn(data: any): [{ wch: number }] {
        // get maximum character of each column
        return data[0].map((a: any, i: any) => {
            const maxLength = Math.max(...data.map((a2: any) => (a2[i] ? a2[i].toString().length : 0)));
            return { wch: maxLength < 50 ? maxLength : 50 };
        });
    }
    ws['!cols'] = fitToColumn(data);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, filename);
};

const QosErrors = observer(() => {
    const {
        qosErrorStore: { qosErrors, getData, loading, setSelected, selected },
    } = useMobxStores();

    const classes = useStyles();
    const [showGrid, setShowGrid] = useState(false);
    const [tenantId, setTenantId] = useState(null);
    const [type, setType] = useState(null);
    const [dateFrom, setDateFrom] = useState(() => {
        const from = new Date();
        from.setDate(from.getDate() - 1);
        return dateInputFormat(from);
    });
    const [dateTo, setDateTo] = useState(() => {
        const to = new Date();
        to.setHours(23, 59, 59);
        return dateInputFormat(new Date(to));
    });

    const getFilteredData = useCallback(() => {
        getData(new Date(dateFrom), new Date(dateTo), type, tenantId);
    }, [dateFrom, dateTo, type, tenantId]);

    const columns = useCallback((): GridColDef[] => {
        return [
            { field: 'type', headerName: 'Type', width: 400 },
            {
                field: 'createdAt',
                headerName: 'Datum',
                width: 180,
                valueGetter: (params: GridValueGetterParams) =>
                    params?.row?.createdAt && datePresentationFormat.format(new Date(params.row.createdAt)),
            },
            { field: 'owner_name', headerName: 'Klant', flex: 1 },
            {
                field: '',
                headerName: 'Acties',
                width: 150,
                renderCell: (params: GridCellParams) => <QosErrorButtons params={params} refresh={getFilteredData} />,
            },
        ];
    }, [getFilteredData]);

    useEffect(() => {
        setShowGrid(true);
    }, []);

    useEffect(() => {
        getFilteredData();
    }, [type, tenantId]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box className={classes.box} display="flex">
                <div className={classes.paper}>
                    <div className={classes.search_field}>
                        <TextField
                            id="input-date-from"
                            color="primary"
                            label="Van"
                            variant="outlined"
                            size="small"
                            type="datetime-local"
                            onBlur={() => {
                                getFilteredData();
                            }}
                            onChange={(event: BaseSyntheticEvent) => {
                                setDateFrom(event.target.value);
                            }}
                            value={dateFrom}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className={classes.search_field}>
                        <TextField
                            id="input-date-to"
                            label="Tot"
                            variant="outlined"
                            size="small"
                            type="datetime-local"
                            onBlur={() => {
                                getFilteredData();
                            }}
                            onChange={(event: BaseSyntheticEvent) => {
                                setDateTo(event.target.value);
                            }}
                            value={dateTo}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className={classes.search_field}>
                        <FormControl variant="outlined" className={classes.formControl} size="small">
                            <InputLabel id="select-error-type-label">Errortype</InputLabel>
                            <Select
                                labelId="select-error-type-label"
                                id="select-error-type"
                                value={type || ''}
                                onChange={(event: BaseSyntheticEvent) => {
                                    setType(event.target.value);
                                }}
                                label="Errortype"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {errorTypes
                                    .slice()
                                    .sort()
                                    .map((type) => (
                                        <MenuItem value={type}>{type}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ width: '300px', marginTop: 'auto' }}>
                        <TenantSelector
                            label=""
                            placeholder="Selecteer een klant"
                            value={tenantId}
                            onChange={(value: string) => {
                                setTenantId(value);
                            }}
                        />
                    </div>
                </div>
                <MnxButton
                    style={{ marginLeft: 'auto' }}
                    color="primary"
                    onClick={() => {
                        const selectedItems = qosErrors.filter((qosError: QosError) => selected.includes(qosError.id));
                        if (!selectedItems?.length) {
                            return;
                        }
                        exportToExcel(selectedItems);
                    }}
                    disabled={!selected?.length}
                >
                    Excel export
                </MnxButton>
            </Box>

            <div style={{ flexGrow: 1, width: '100%' }}>
                {showGrid && (
                    <MnxDataGrid
                        loading={loading}
                        rows={qosErrors}
                        columns={columns()}
                        onSelectionModelChange={(newSelection) => {
                            setSelected(newSelection);
                        }}
                        selectionModel={selected}
                        checkboxSelection
                    />
                )}
            </div>
        </div>
    );
});

export default QosErrors;
