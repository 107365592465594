import { useEffect, useState } from 'react';

export const useKeyCombination = (keyCombination: string): boolean => {
    const pressedKeys: Map<string, boolean> = new Map();
    const [triggered, setTriggered] = useState(false);

    const checkForMonitoredKeyCombinations = () => {
        const individualKeys = keyCombination.split('+');
        const allKeysPressed = individualKeys.reduce((accumulator: boolean, key: string) => {
            if (!pressedKeys.get(key.toLowerCase())) {
                return false;
            }

            return accumulator;
        }, true);

        return allKeysPressed;
    };

    const keyDownFunction = (event: KeyboardEvent) => {
        if (pressedKeys.get(event.key.toLowerCase())) {
            return;
        }

        pressedKeys.set(event.key.toLowerCase(), true);
        setTriggered(checkForMonitoredKeyCombinations());
    };

    const keyUpFunction = (event: KeyboardEvent) => {
        pressedKeys.set(event.key.toLowerCase(), false);
        setTriggered(checkForMonitoredKeyCombinations());
    };

    useEffect(() => {
        document.addEventListener('keydown', keyDownFunction, false);
        document.addEventListener('keyup', keyUpFunction, false);
        return () => {
            document.removeEventListener('keydown', keyDownFunction, false);
            document.removeEventListener('keyup', keyUpFunction, false);
        };
    }, []);

    return triggered;
};
