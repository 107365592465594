import request from '../utils/request';
import env from '../env';

export type Edition = {
    name: string;
    module_id: string;
    description: string;
    modification_date?: string;
};

export type Tenant = {
    active: true;
    tenant_id: string;
    tenant_nr: number;
    name: string;
    edition: Edition;
    modules: string[];
    accountants: string[];
    maximum_number_of_administrations: number;
    maximum_number_of_sessions: number;
    database: string;
};

export type SimpleTenant = {
    tenant_id: string;
    tenant_nr: number;
    name: string;
    maximum_number_of_sessions: number;
    is_trial: boolean;
    database: string;
};

export interface ITenantStoreApi {
    getTenant(id: string, reason: string): Promise<Tenant>;
    getTenants(): Promise<Tenant[]>;
    getSimpleTenant(id: string): Promise<SimpleTenant>;
}

export class TenantStoreApi implements ITenantStoreApi {
    async getTenant(id: string, reason: string): Promise<Tenant> {
        try {
            return await request('GET', `${env.domain}/admin/tenant/${id}`)
                .set({ 'x-reason': reason })
                .then((res) => res.body);
        } catch (e) {
            console.error(e);
            return;
        }
    }

    async getTenants(): Promise<Tenant[]> {
        return request('GET', `${env.domain}/api/1/tenant`).then((res) => res.body.collection);
    }

    async getSimpleTenant(id: string): Promise<SimpleTenant> {
        return request('GET', `${env.domain}/admin/search/tenant/${encodeURIComponent(id)}`).then(
            (res) => res?.body?.collection?.[0],
        );
    }
}
