import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Tenant } from '../../api/tenant';
import MnxTextField from '../../components/mnx-textfield';
import { FileCopy } from '@material-ui/icons';
import { useMobxStores } from '../../hooks/useMobxStores';
import { useToaster } from '../../hooks/useToaster';
import { Module } from '../../api/module';

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        padding: theme.spacing(3),
    },
    bottomMargin: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));

const TenantInformation = observer(() => {
    const {
        tenantStore: { getTenant, modules },
        applicationStore: { tenant, reason },
    } = useMobxStores();

    const [accountant, setAccountant] = useState<Tenant>(null);

    const classes = useStyles();
    const { addToast } = useToaster();

    useEffect(() => {
        if (tenant.accountants?.length) {
            getTenant(tenant.accountants[0], reason).then((response: Tenant) => {
                setAccountant(response);
            });
        }
    }, []);

    const genericTenantInformation = (tenant: Tenant, title?: string) => {
        return (
            <>
                <Typography variant="h4" className={classes.bottomMargin}>
                    {title || `Informatie`}
                </Typography>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <MnxTextField
                                label={'Unieke identificatie code'}
                                InputProps={{ readOnly: true }}
                                value={tenant.tenant_id}
                                inputIcon={<FileCopy />}
                                onClickButton={() => {
                                    navigator?.clipboard?.writeText(tenant.tenant_id);
                                    addToast('Gekopieerd naar klembord!');
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <MnxTextField label={'Nummer'} InputProps={{ readOnly: true }} value={tenant.tenant_nr} />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <MnxTextField label={'Naam'} InputProps={{ readOnly: true }} value={tenant.name} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <MnxTextField
                                label={'Id editie'}
                                InputProps={{ readOnly: true }}
                                value={tenant.edition?.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <MnxTextField
                                label={'Naam editie'}
                                InputProps={{ readOnly: true }}
                                value={tenant.edition?.description}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <Paper className={classes.paperRoot} elevation={3}>
            {genericTenantInformation(tenant)}
            {accountant && genericTenantInformation(accountant, 'Informatie over accountant')}
            {tenant.modules?.length && modules && (
                <Grid className={classes.bottomMargin} container direction="column" spacing={2}>
                    <Typography variant="h4" className={classes.bottomMargin}>
                        Actieve modules voor '{tenant.name}'
                    </Typography>
                    {tenant.modules
                        .map((module_id: string) => modules.get(module_id))
                        .slice()
                        .sort((a: Module, b: Module) => {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0;
                        })
                        .map((module: Module) => {
                            if (!module) return null;
                            return (
                                <Grid item key={module.module_id}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <MnxTextField
                                                label={'Id module'}
                                                InputProps={{ readOnly: true }}
                                                value={module.name}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <MnxTextField
                                                label={'Naam module'}
                                                InputProps={{ readOnly: true }}
                                                value={module.description}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                </Grid>
            )}
        </Paper>
    );
});

export default TenantInformation;
