import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import { UserRole } from '../../stores/oauth';
import Authorization from '../../components/authorization';

const AuthorizedRoute = observer<
    RouteProps & { withAdministration?: boolean; withReason?: boolean; withTenant?: boolean; role?: UserRole[] }
>(({ role, withAdministration, withReason, withTenant, children, ...rest }) => {
    const {
        oauthStore,
        applicationStore: { reason, tenant, administration },
    } = useMobxStores();

    const routeParameterRedirect = (): JSX.Element => {
        let needsRedirect = false;
        const pathParts = window.location.pathname.split('/');
        if (withTenant && !window.location.pathname.includes(`/tenant/${tenant.tenant_id}`)) {
            needsRedirect = true;
            pathParts[pathParts.findIndex((part) => part === 'tenant') + 1] = tenant.tenant_id;
        }
        if (withAdministration && !window.location.pathname.includes(`/administration/${administration.id}`)) {
            needsRedirect = true;
            pathParts[pathParts.findIndex((part) => part === 'administration') + 1] = administration.id;
        }
        return needsRedirect && <Redirect to={pathParts.join('/')} />;
    };

    return (
        <Route
            {...rest}
            component={() =>
                !oauthStore.hasAnyRoles(role) ? (
                    <Redirect to="/unauthorized" />
                ) : (
                    <>
                        {(withReason && !reason) ||
                        (withTenant && !tenant) ||
                        (withAdministration && !administration) ? (
                            <Authorization
                                withReason={withReason}
                                withTenant={withTenant}
                                withAdministration={withAdministration}
                            />
                        ) : (
                            <>{routeParameterRedirect() || children}</>
                        )}
                    </>
                )
            }
        />
    );
});

export default AuthorizedRoute;
