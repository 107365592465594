import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import sjcl from 'sjcl';
import autoBind from 'auto-bind';
import request from 'superagent';
import qs from 'query-string';
import OAuthApi, { Group, IOAuthStoreApi, Profile } from '../api/oauth';
import NavigationStore from './navigation';
import env from '../env';

const storageKeys = {
    token: 'token',
    hash_verification: 'hash_verification',
};

export type UserRole = 'operations' | 'cs' | 'sales' | 'management';

export type OauthToken = {
    access_token: string;
    refresh_token: string;
    expires_at: number;
};

type QueryParameters = {
    code: string;
    state?: string;
};

type OauthRequest = {
    grant_type: string;
    client_id: string;
    client_secret: string;
    code?: string;
    hash_verification?: string;
    hash_verification_type?: string;
    refresh_token?: string;
};

const getUserRolesFromProfile = (profile: Profile): UserRole[] => {
    const userRoles: UserRole[] = [];
    if (profile?.isOperations) {
        userRoles.push('operations');
    }

    if (profile?.isCustomerSupport) {
        userRoles.push('cs');
    }

    if (profile?.isSales) {
        userRoles.push('sales');
    }

    if (profile?.isManagement) {
        userRoles.push('management');
    }

    return userRoles;
};

export default class OAuthStore {
    navigationStore: NavigationStore;
    api: IOAuthStoreApi;
    token: OauthToken;
    client_id = 'minoxcs';
    loading = false;
    profile: Profile;
    groups: Group[];

    constructor(navigationStore: NavigationStore) {
        this.navigationStore = navigationStore;
        this.api = new OAuthApi();
        makeObservable(this, {
            redirectToLoginWhenNeeded: action,
            updateOauthInformation: action,
            getOauthTokenFromStore: action,
            refreshOauthInformation: action,
            convertAuthorizationToAccess: action,
            clearAuthorization: action,
            token: observable,
            loading: observable,
            profile: observable,
            isLoggedIn: computed,
            name: computed,
        });
        autoBind(this);
    }

    hasAnyRoles(requiredUserRoles?: UserRole[]): boolean {
        if (!requiredUserRoles.length) return true;
        const userRoles = getUserRolesFromProfile(this.profile);
        return requiredUserRoles.some((requiredRole) => userRoles.includes(requiredRole));
    }

    get isLoggedIn(): boolean {
        if (this.token === undefined || this.profile === undefined) return undefined;
        return this.token && this.profile?.active === true;
    }

    get name(): string {
        return this.profile?.name;
    }

    async redirectToLoginWhenNeeded(): Promise<void> {
        this.getOauthTokenFromStore();
        const queryParameters = qs.parse(window.location.search);
        if (queryParameters?.error) {
            this.doLogout(queryParameters.error);
            return;
        }
        if (this.isLoggedIn === undefined && queryParameters.code) {
            const { code, state } = queryParameters as QueryParameters;
            await this.convertAuthorizationToAccess({ code, state });
            return;
        }
        if (!this.token) {
            this.doLogout();
        }
        if (this.token?.access_token && (this.tokenIsExpired(this.token) || !this.profile)) {
            await this.refreshOauthInformation(this.token.refresh_token);
        }
    }

    redirectToLogin(toHome?: boolean): void {
        const state = JSON.stringify({ return_url: toHome ? '/' : this.navigationStore.currentPath });
        const redirectUri = `${window.location.protocol}//${window.location.host}`;
        const hash_verification = this.generateString(64);
        this.clearAuthorization();
        localStorage.setItem(storageKeys.hash_verification, hash_verification);
        window.location.assign(
            `${env.domain}/oauth/authorize?` +
                `response_type=code` +
                `&next=minoxcs` +
                `&client_id=${this.client_id}` +
                `&redirect_uri=${redirectUri}` +
                `&state=${state}` +
                `&hash_verification=${sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(hash_verification))}` +
                `&hash_verification_type=sha256`,
        );
    }

    updateOauthInformation(token: OauthToken, profile: Profile): void {
        localStorage.setItem(storageKeys.token, JSON.stringify(token));
        runInAction(() => {
            this.token = token;
            this.profile = profile;
        });
    }

    getOauthTokenFromStore(): OauthToken {
        try {
            if (!this.token) {
                this.token = JSON.parse(localStorage.getItem(storageKeys.token)) || undefined;
            }
            return this.token;
        } catch {
            return undefined;
        }
    }

    tokenIsExpired(token: OauthToken): boolean {
        return new Date().getTime() > token?.expires_at;
    }

    async performTokenAction({ code, grant_type }: { code: string; grant_type: string }): Promise<OauthToken> {
        const requestBody: OauthRequest = {
            grant_type,
            client_id: this.client_id,
            client_secret: this.client_id,
        };
        if (grant_type === 'authorization_code') {
            requestBody.code = code;
            requestBody.hash_verification = localStorage.getItem(storageKeys.hash_verification);
            requestBody.hash_verification_type = 'sha256';
        }
        if (grant_type === 'refresh_token') {
            requestBody.refresh_token = code;
        }
        const { body } = await request.post(`${env.domain}/oauth/token`).send(requestBody);
        localStorage.removeItem(storageKeys.hash_verification);
        return {
            access_token: body.access_token,
            refresh_token: body.refresh_token,
            expires_at: new Date(new Date().getTime() + body.expires_in * 1000).getTime(),
        };
    }

    async convertAuthorizationToAccess({ code, state }: { code: string; state: string }): Promise<void> {
        try {
            const token = await this.performTokenAction({ grant_type: 'authorization_code', code });
            const profile = await this.generateProfile(token);
            this.updateOauthInformation(token, profile);
            const stateObject = state ? JSON.parse(state) : null;
            if (stateObject?.return_url) {
                this.navigationStore.replace(stateObject.return_url);
            }
        } catch (error) {
            console.error(error, 'Failed to acquire authorization.');
            this.doLogout();
        }
    }

    async refreshOauthInformation(refresh_token: string): Promise<void> {
        try {
            const token = await this.performTokenAction({ grant_type: 'refresh_token', code: refresh_token });
            const profile = await this.generateProfile(token);
            this.updateOauthInformation(token, profile);
        } catch (error) {
            console.error(error, 'Failed to refresh authorization.');
            this.doLogout();
        }
    }

    async injectRolesOnProfile(access_token: string, profile: Profile): Promise<void> {
        const groups = await this.api.getGroups(access_token, profile.tenant_id);
        profile.isSuperuser = profile?.name?.toLowerCase() === 'superuser';
        profile.isSales =
            profile.isSuperuser || profile.group_id === groups?.find((e) => e.name.toLowerCase() === 'sales')?.group_id;
        profile.isManagement =
            profile.isSuperuser ||
            profile.group_id === groups?.find((e) => e.name.toLowerCase() === 'management')?.group_id;
        profile.isOperations =
            profile.isSuperuser ||
            profile.group_id === groups?.find((e) => e.name.toLowerCase() === 'operations')?.group_id;
        profile.isCustomerSupport =
            profile.isSuperuser ||
            profile.isOperations ||
            profile.group_id === groups?.find((e) => e.name.toLowerCase() === 'customer support')?.group_id;
        profile.isSuperaccount = profile.tenant_id === 'd0cb2b82-95cb-4bee-a991-2a5d010d1c88';
    }

    async generateProfile(token: OauthToken): Promise<Profile> {
        const { access_token } = token;
        const profile = await this.api.getProfile(access_token);
        await this.injectRolesOnProfile(access_token, profile);
        if (profile.active) {
            return profile;
        } else {
            throw new Error('Profile inactive');
        }
    }

    async updateName(name: string): Promise<void> {
        await this.api.updateName(name);
        runInAction(() => {
            this.profile.name = name;
        });
    }

    clearAuthorization(): void {
        Object.keys(storageKeys).forEach((key) => {
            localStorage.removeItem(key);
        });
        runInAction(() => {
            this.token = undefined;
            this.profile = undefined;
        });
    }

    doLogout(error?: unknown): void {
        this.navigationStore.push('/logout', { state: error });
    }

    generateString(length: number): string {
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let text = '';
        for (let i = 0; i < length; i += 1) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }
}
