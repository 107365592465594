import React from 'react';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';
import { Paper } from '@material-ui/core';

export type MnxDataGridProps = { className?: string } & DataGridProps;

const MnxDataGrid: React.FunctionComponent<MnxDataGridProps> = (props) => {
    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <Paper style={{ flexGrow: 1 }}>
                <DataGrid className={props.className} hideFooterSelectedRowCount autoPageSize {...props} />
            </Paper>
        </div>
    );
};

export default MnxDataGrid;
