import request from '../utils/request';
import env from '../env';

export type ServoyClient = {
    id: string;
    client_id: string;
    server_id: string;
    realownerid?: string;
    ownerid?: string;
    solution_name?: string;
    userid?: string;
    administrationid?: string;
    ownernr?: number;
    administrationnr?: number;
    fiscalyear?: number;
    idle_time?: number;
    login_time?: number;
    logout_time?: number;
    queuename?: string;
    lougout_time?: number;
};

export interface IClientStoreApi {
    getActiveClients(): Promise<ServoyClient[]>;
    sendMessageToAllClients(message: string): Promise<void>;
    disconnectAllClients(): Promise<void>;
    disconnectClients(clientIds: string[]): Promise<void>;
}

export class ClientStoreApi implements IClientStoreApi {
    async getActiveClients(): Promise<ServoyClient[]> {
        return request('GET', `${env.domain}/admin/client`).then((res) => res.body);
    }

    async sendMessageToAllClients(message: string): Promise<void> {
        await request('POST', `${env.domain}/admin/client/message`).send({ message });
    }

    async disconnectAllClients(): Promise<void> {
        await request('POST', `${env.domain}/admin/client/disconnect`);
    }

    async disconnectClients(clientIds: string[]): Promise<void> {
        await request('POST', `${env.domain}/admin/client/disconnect`).send({ clients: clientIds });
    }
}
