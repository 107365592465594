import React, { useEffect, useState } from 'react';
import MnxDialog from '../../components/mnx-dialog';
import PropTypes from 'prop-types';
import {
    FormControl,
    makeStyles,
    Grid,
    Chip,
    Typography,
    TextField,
    Box,
    MenuItem,
    Select,
    InputLabel,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Partner } from '../../api/partner';
import MnxAutocomplete from '../../components/mnx-autocomplete';
import { SearchOption } from '../../stores/search';
import { useMobxStores } from '../../hooks/useMobxStores';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('md')]: {
            width: '40rem',
        },
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addtenant: {
        marginBottom: theme.spacing(1),
    },
    box: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        background: theme.palette.type === 'light' ? '#eee' : '#161616',
    },
}));

const TenantRestrictions = observer<{
    handleChange: (tenant_restrictions: string) => void;
    tenant_restrictions: string;
}>(({ tenant_restrictions, handleChange }) => {
    const {
        searchStore: { tenantOptions, search, loading },
    } = useMobxStores();

    const [tenants, setTenants] = useState(tenant_restrictions?.split(',')?.filter((e: string) => !!e) || []);

    const classes = useStyles();

    useEffect(() => {
        handleChange(tenants?.length ? `,${tenants.join(',')},` : null);
    }, [tenants]);

    const filterOptions = createFilterOptions({ stringify: (option: SearchOption) => option.search });

    return (
        <>
            <Grid item xs={12}>
                <Box className={classes.box}>
                    {tenants.length ? (
                        tenants.map((tenant: string) => (
                            <Chip
                                key={tenant}
                                className={classes.chip}
                                color="primary"
                                label={tenant}
                                onDelete={() => {
                                    setTenants(tenants.filter((e) => e !== tenant));
                                }}
                            />
                        ))
                    ) : (
                        <Box fontStyle="italic" paddingBottom={1}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Alle tenants toegestaan
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Grid>
            <MnxAutocomplete
                id="mnx-autocomplete-tenant"
                loading={loading}
                filterOptions={filterOptions}
                clearOnSelect
                getOptionLabel={(option: SearchOption) => option?.value || ''}
                label="Sta een tenant toe"
                values={tenantOptions}
                onValueSelected={(value: SearchOption) => {
                    if (value?.nr && !tenants.includes(`${value.nr}`)) {
                        setTenants([...tenants, `${value.nr}`]);
                    }
                }}
                onSearchChange={(term: string) => {
                    search(term);
                }}
            />
        </>
    );
});

const SettingsPopup: React.FunctionComponent<{
    handleSubmit: (partner: Partner) => void;
    handleClose: () => void;
    partner: Partner;
}> = ({ handleSubmit, handleClose, partner: oldPartner }) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(false);
    const [partner, setPartner] = useState(oldPartner);

    useEffect(() => {
        setDisabled(
            (!!partner.rate_limit_interval && !partner.rate_limit_maximum) ||
                (!partner.rate_limit_interval && !!partner.rate_limit_maximum),
        );
    }, [partner]);

    return (
        <MnxDialog
            disabled={disabled}
            title={`Instellingen API Partner: ${partner.client_id}`}
            handleClose={handleClose}
            handleSubmit={() =>
                handleSubmit({
                    ...partner,
                    group_restrictions: partner.group_restrictions === 'unlimited' ? null : partner.group_restrictions,
                })
            }
            classes={{ paper: classes.dialog }}
            open
        >
            <FormControl component="fieldset" fullWidth>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Toegestane tenants
                        </Typography>
                        <TenantRestrictions
                            tenant_restrictions={partner?.tenant_restrictions}
                            handleChange={(tenant_restrictions) => setPartner({ ...partner, tenant_restrictions })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="access-select-label">Toegang</InputLabel>
                            <Select
                                fullWidth
                                labelId="access-select-label"
                                id="access-select"
                                value={partner?.group_restrictions || 'unlimited'}
                                onChange={(event) => {
                                    setPartner({
                                        ...partner,
                                        group_restrictions: event.target.value as 'single' | 'accountant',
                                    });
                                }}
                            >
                                <MenuItem value={'unlimited'}>Onbeperkt</MenuItem>
                                <MenuItem value={'single'}>Niet-accountants</MenuItem>
                                <MenuItem value={'accountant'}>Accountants</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            API Beperkingen
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={partner?.rate_limit_enabled || false}
                                    onChange={(event) => {
                                        setPartner({
                                            ...partner,
                                            rate_limit_enabled: event.target.checked,
                                        });
                                    }}
                                />
                            }
                            label="Gebruikslimiet"
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    size="small"
                                    id="rate-limit-maximum"
                                    label="# Requests"
                                    placeholder="20"
                                    value={partner?.rate_limit_maximum || ''}
                                    onChange={(event) => {
                                        setPartner({ ...partner, rate_limit_maximum: Number(event.target.value) });
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    size="small"
                                    id="rate-limit-interval"
                                    label="Per ms"
                                    placeholder="1000"
                                    value={partner?.rate_limit_interval || ''}
                                    onChange={(event) => {
                                        setPartner({ ...partner, rate_limit_interval: Number(event.target.value) });
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </FormControl>
        </MnxDialog>
    );
};

SettingsPopup.propTypes = {
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
};

export default SettingsPopup;
