import request from '../utils/request';
import env from '../env';

export type Profile = {
    name: string;
    group_id: string;
    tenant_id: string;
    security_keys: string[];
    active: boolean;
    administrations: string[];
    client_id: string;
    expires_at: string;
    journals: {
        cash: boolean;
        bank: boolean;
        general: boolean;
        purchase: boolean;
        sales: boolean;
        opening_balance: boolean;
    };
    tenants: string[];
    isSuperuser: boolean;
    isOperations: boolean;
    isCustomerSupport: boolean;
    isSales: boolean;
    isManagement: boolean;
    user_id: string;
    isSuperaccount: boolean;
};

export type Group = {
    name: string;
    group_id: string;
    administrations: string[];
};

export interface IOAuthStoreApi {
    getProfile(token: string): Promise<Profile>;
    getGroups(token: string, tenantId: string): Promise<Group[]>;
    updateName(name: string): Promise<void>;
}

export default class OAuthApi implements IOAuthStoreApi {
    async getGroups(token: string, tenantId: string): Promise<Group[]> {
        const profile = await request('GET', `${env.domain}/api/1/tenant/${tenantId}/group`, false)
            .set({ authorization: `bearer ${token}` })
            .then((res) => res.body);
        return profile;
    }

    async getProfile(token: string): Promise<Profile> {
        const profile = await request('GET', `${env.domain}/api/1/profile`, false)
            .set({ authorization: `bearer ${token}` })
            .then((res) => res.body);
        return profile;
    }

    async updateName(name: string): Promise<void> {
        await request('PUT', `${env.domain}/admin/profile`).send({ full_name: name });
    }
}
