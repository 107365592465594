import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxButton from '../../components/mnx-button';
import { useToaster } from '../../hooks/useToaster';
import { Box, Card, Typography } from '@material-ui/core';

const Misc = observer(() => {
    const {
        miscStore: { activate, deactivate, updateConfiguration, updateMetering },
    } = useMobxStores();

    useEffect(() => {
        activate();
        return () => {
            deactivate();
        };
    }, []);

    const { addToast } = useToaster();

    return (
        <>
            <Box mb={2}>
                <Card>
                    <Box m={2}>
                        <Typography>
                            Forceer het Minox platform om de nieuwe configuratie in te lezen en te activeren.
                        </Typography>
                        <Box pt={2}>
                            <MnxButton
                                key={'2'}
                                color="primary"
                                onClick={() => {
                                    updateConfiguration().then((success) => {
                                        if (success) {
                                            addToast('Configuratie opnieuw ingelezen!', { variant: 'success' });
                                        } else {
                                            addToast('Configuratie opnieuw inlezen mislukt!', { variant: 'error' });
                                        }
                                    });
                                }}
                            >
                                Configuratie opnieuw inlezen
                            </MnxButton>
                        </Box>
                    </Box>
                </Card>
            </Box>

            <Box mb={2}>
                <Card>
                    <Box m={2}>
                        <Typography>
                            Update de statistieken die op het dashboard van de klant worden getoont.
                        </Typography>
                        <Box pt={2}>
                            <MnxButton
                                key={'23'}
                                color="primary"
                                onClick={() => {
                                    updateMetering().then((success) => {
                                        if (success) {
                                            addToast('Metering wordt bijgewerkt!', { variant: 'success' });
                                        } else {
                                            addToast('Metering updaten is mislukt!', { variant: 'error' });
                                        }
                                    });
                                }}
                            >
                                Update metering
                            </MnxButton>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    );
});

export default Misc;
