import { Grid, Paper, Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxButton from '../mnx-button';
import ReasonForm from './reasonForm';
import TenantSelector from './tenantSelector';
import AdministrationSelector from './administrationSelector';

const Authorization = observer<{ withReason: boolean; withTenant: boolean; withAdministration: boolean }>(
    ({ withReason, withTenant, withAdministration }) => {
        const {
            tenantStore: { getTenant, tenants },
            administrationStore: { getAdministration, administrations },
            applicationStore: {
                tenantId,
                administrationId,
                tenant,
                administration,
                reason,
                setReason,
                setTenantId,
                setAdministrationId,
            },
        } = useMobxStores();

        const [tenantRequest, setTenantRequest] = useState<NodeJS.Timeout>();
        const [administrationRequest, setAdministrationRequest] = useState<NodeJS.Timeout>();
        const [shouldFillReason, setShouldFillReason] = useState(false);
        const [shouldFillTenant, setShouldFillTenant] = useState(false);
        const [shouldFillAdministration, setShouldFillAdministration] = useState(false);
        const [formState, setFormState] = useState({ tenantId, reason, administrationId });

        const saveAVG = () => {
            if (shouldFillReason) setReason(formState.reason);
            if (shouldFillTenant) setTenantId(formState.tenantId);
            if (shouldFillAdministration) setAdministrationId(formState.administrationId);
        };

        const reasonIsValid = !shouldFillReason || formState.reason?.length >= 10;
        const tenantIsValid = !shouldFillTenant || tenants.has(formState.tenantId);
        const administrationIsValid = !shouldFillAdministration || administrations.has(formState.administrationId);

        useEffect(() => {
            if (shouldFillTenant && reasonIsValid && formState.tenantId) {
                clearTimeout(tenantRequest);
                setTenantRequest(
                    setTimeout(() => {
                        getTenant(formState.tenantId, formState.reason);
                    }, 500),
                );
            }
            setFormState({ ...formState, administrationId: '' });
        }, [formState.tenantId, formState.reason]);

        useEffect(() => {
            if (shouldFillAdministration && reasonIsValid && tenantIsValid && formState.administrationId) {
                clearTimeout(administrationRequest);
                setAdministrationRequest(
                    setTimeout(() => {
                        getAdministration(formState.tenantId, formState.administrationId, formState.reason);
                    }, 500),
                );
            }
        }, [formState.administrationId]);

        useEffect(() => {
            setShouldFillReason(withReason && !reason);
            setShouldFillTenant(withTenant && !tenant);
            setShouldFillAdministration(withAdministration && !administration);
        }, [reason, tenantId, administrationId]);

        return (
            <Grid item xs={12}>
                <Paper style={{ padding: 16 }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        {shouldFillReason && (
                            <ReasonForm
                                reasonIsValid={reasonIsValid}
                                onChange={(value: string) => {
                                    setFormState({ ...formState, reason: value });
                                }}
                            />
                        )}
                        {shouldFillTenant && (
                            <Grid item xs={12}>
                                <Typography paragraph>
                                    De op te vragen gegevens of de bewerking heeft betrekking tot een klant. Specificeer
                                    deze:
                                </Typography>

                                <TenantSelector
                                    value={formState.tenantId}
                                    onChange={(value: string) => {
                                        setFormState({ ...formState, tenantId: value });
                                    }}
                                />
                            </Grid>
                        )}
                        {shouldFillAdministration && (
                            <AdministrationSelector
                                reason={reasonIsValid && formState.reason}
                                tenant_id={tenantIsValid && formState.tenantId}
                                value={formState.administrationId}
                                onChange={(value: string | number) => {
                                    setFormState({ ...formState, administrationId: value as string });
                                }}
                            />
                        )}
                    </Grid>

                    <Grid item>
                        <Box pt={2} display="flex" alignItems="center">
                            <MnxButton
                                onClick={saveAVG}
                                disabled={!reasonIsValid || !tenantIsValid || !administrationIsValid}
                                color="primary"
                            >
                                Opslaan
                            </MnxButton>
                        </Box>
                    </Grid>
                </Paper>
            </Grid>
        );
    },
);

export default Authorization;
