import request from '../utils/request';
import env from '../env';

export type Partner = {
    client_id: string;
    client_secret: string;
    contact_email: string;
    description: string;
    group_restrictions?: 'accountant' | 'single' | 'unlimited';
    tenant_restrictions: string;
    rate_limit_enabled: boolean;
    rate_limit_interval: number;
    rate_limit_maximum: number;
    rate_limit_violation: string;
};

export interface IPartnerStoreApi {
    getPartners(): Promise<Partner[]>;
    update(partner: Partner): Promise<boolean>;
}

export class PartnerStoreApi implements IPartnerStoreApi {
    async getPartners(): Promise<Partner[]> {
        return request('GET', `${env.domain}/admin/partner`).then((res) => res.body);
    }

    async update(partner: Partner): Promise<boolean> {
        try {
            await request('PATCH', `${env.domain}/admin/partner`).send(partner);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}
