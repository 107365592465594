import React from 'react';
import MnxDialog from '../../components/mnx-dialog';
import PropTypes from 'prop-types';
import { FormControl, makeStyles, Grid, TextField, DialogActions } from '@material-ui/core';
import { Partner } from '../../api/partner';
import MnxButton from '../../components/mnx-button';

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('md')]: {
            width: '40rem',
        },
    },
}));

const InfoPopup: React.FunctionComponent<{
    handleClose: () => void;
    partner: Partner;
}> = ({ handleClose, partner }) => {
    const classes = useStyles();

    return (
        <MnxDialog
            title={`Informatie API Partner: ${partner.client_id}`}
            handleClose={handleClose}
            footer={
                <DialogActions>
                    <MnxButton onClick={handleClose} color="primary" autoFocus>
                        Sluiten
                    </MnxButton>
                </DialogActions>
            }
            classes={{ paper: classes.dialog }}
            open
        >
            <FormControl component="fieldset" fullWidth>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size="small"
                            id="client-id"
                            label="Client ID"
                            aria-readonly
                            value={partner?.client_id || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size="small"
                            id="description"
                            label="Beschrijving"
                            aria-readonly
                            value={partner?.description || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size="small"
                            id="email"
                            label="Email"
                            aria-readonly
                            value={partner?.contact_email || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </FormControl>
        </MnxDialog>
    );
};

InfoPopup.propTypes = {
    handleClose: PropTypes.func,
};

export default InfoPopup;
