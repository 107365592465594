/* eslint-disable lines-between-class-members */
import { action, makeObservable } from 'mobx';
import autoBind from 'auto-bind';
import { IInternalAdministrationApi } from '../api/internaladministration';
import ApplicationStore from './application';
import environment from '../env';

export class InternalAdministrationStore {
    loading = false;
    api: IInternalAdministrationApi;
    applicationStore: ApplicationStore;

    constructor({ api, applicationStore }: { api: IInternalAdministrationApi; applicationStore: ApplicationStore }) {
        makeObservable(this, {
            linkRBSWithMinox: action,
        });
        this.api = api;
        this.applicationStore = applicationStore;
        autoBind(this);
    }

    getConnectionStatus() {
        return this.api.getConnectionStatus();
    }

    updateConnectionSettings(administrationNumber: number) {
        return this.api.updateSettings({
            administration_nr: administrationNumber,
        });
    }

    linkRBSWithMinox() {
        const rbsUrl = `${environment.rbsDomain}/oauth/connect-with-minox`;
        window.location.assign(rbsUrl);
    }
}
