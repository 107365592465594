import React, { BaseSyntheticEvent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import { useMobxStores } from '../../hooks/useMobxStores';
import { Search } from './Search';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        display: 'block',
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuParagraph: {
        paddingRight: '12px',
    },
}));

const Navbar = observer<{ setSidebarOpen: () => void }>((props) => {
    const { setSidebarOpen } = props;
    const {
        oauthStore: { doLogout, redirectToLogin, isLoggedIn },
    } = useMobxStores();

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = !!anchorEl;
    const isMobileMenuOpen = !!mobileMoreAnchorEl;

    const handleProfileMenuOpen = (event: BaseSyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: BaseSyntheticEvent) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const authenticationMenuItem = isLoggedIn ? (
        <MenuItem onClick={() => doLogout()}>
            <IconButton aria-label="log out" aria-controls="btn-log-out" color="inherit">
                <LockIcon />
            </IconButton>
            <p className={classes.menuParagraph}>Log uit</p>
        </MenuItem>
    ) : (
        <MenuItem
            onClick={() => {
                redirectToLogin();
            }}
        >
            <IconButton aria-label="log in" aria-controls="btn-log-in" color="inherit">
                <LockOpenIcon />
            </IconButton>
            <p className={classes.menuParagraph}>Log in</p>
        </MenuItem>
    );

    const profileMenuItem = (
        <Link to="/profile">
            <MenuItem>
                <IconButton aria-label="log out" aria-controls="btn-log-out" color="inherit">
                    <PersonIcon />
                </IconButton>
                <p className={classes.menuParagraph}>Profiel</p>
            </MenuItem>
        </Link>
    );

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {profileMenuItem}
            {authenticationMenuItem}
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {profileMenuItem}
            {authenticationMenuItem}
        </Menu>
    );

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={setSidebarOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                        <Link to="/">Customer Support</Link>
                    </Typography>
                    <div className={classes.grow} />
                    <Search />
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
});

export default Navbar;
