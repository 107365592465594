import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import Layout from '../../components/layout/Layout';
import Home from '../home';
import Clients from '../clients';
import Servers from '../servers';
import Misc from '../miscellaneous';
import ApiLog from '../apilog';
import AuthenticatedRoute from './authenticatedRoute';
import AuthorizedRoute from './authorizedRoute';
import Unauthorized from '../unauthorized';
import Login from '../login';
import Logout from '../logout';
import NoMatch from '../nomatch';
import { useMobxStores } from '../../hooks/useMobxStores';
import ChangePassword from '../change-password';
import Profile from '../profile';
import TenantInformation from '../tenant-information';
import UnblockPayables from '../unblock-payables';
import UnblockJournals from '../unblock-journals';
import BizcuitDiagnostics from '../bizcuit-diagnostics';
import Go2ubl from '../go2ubl';
import LoginInformationView from '../login-information';
import FixSHStatus from '../fix-sh-status';
import ReorderJournalPage from '../reorder-journal-page';
import Partners from '../partners';
import UploadCSV from '../internalinvoice';
import LinkRBSWithMinox from '../internaladministration';
import QosErrors from '../qos-errors';
import { useServiceWorker } from '../../hooks/useServiceWorker';

const Routing = observer(() => {
    const { navigationStore } = useMobxStores();
    useServiceWorker();

    return (
        <Router history={navigationStore.history}>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="theme-color" content="#237fb1" />
                <title>Minox Support</title>
            </Helmet>
            <Switch>
                <Route component={Login} path="/login" />
                <Route component={Logout} path="/logout" />
                <AuthenticatedRoute layout={Layout}>
                    <Switch>
                        <AuthorizedRoute path="/" role={['operations', 'cs', 'sales', 'management']} exact>
                            <Home />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/sales/internalinvoice" withReason role={['sales']} exact>
                            <UploadCSV />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/sales/linkrbswithminox" withReason role={['management']} exact>
                            <LinkRBSWithMinox />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/partners" withReason role={['operations']} exact>
                            <Partners />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/clients" withReason role={['operations']} exact>
                            <Clients />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/apilog" role={['operations']} withReason exact>
                            <ApiLog />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/logininformation" role={['operations']} withReason exact>
                            <LoginInformationView />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/servers" role={['operations']} withReason exact>
                            <Servers />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/misc" withReason role={['operations']} exact>
                            <Misc />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/qos_errors" withReason role={['operations']} exact>
                            <QosErrors />
                        </AuthorizedRoute>
                        <AuthorizedRoute path="/go2ubl" role={['operations', 'cs']} exact withReason>
                            <Go2ubl />
                        </AuthorizedRoute>
                        <Route path="/tenant/:tenantId">
                            <Switch>
                                <AuthorizedRoute
                                    path="/tenant/:tenantId"
                                    role={['operations', 'cs']}
                                    exact
                                    withTenant
                                    withReason
                                >
                                    <TenantInformation />
                                </AuthorizedRoute>
                                <AuthorizedRoute
                                    path="/tenant/:tenantId/resetpassword"
                                    role={['operations', 'cs']}
                                    exact
                                    withTenant
                                    withReason
                                >
                                    <ChangePassword />
                                </AuthorizedRoute>
                                <AuthorizedRoute
                                    path="/tenant/:tenantId/administration"
                                    role={['cs']}
                                    withTenant
                                    withReason
                                    withAdministration
                                >
                                    <Switch>
                                        <AuthorizedRoute path="/administration" role={['operations', 'cs']} exact>
                                            <></>
                                        </AuthorizedRoute>
                                        <AuthorizedRoute
                                            path="/tenant/:tenantId/administration/:administrationId/unblock_payables"
                                            role={['operations', 'cs']}
                                            exact
                                        >
                                            <UnblockPayables />
                                        </AuthorizedRoute>
                                        <AuthorizedRoute
                                            path="/tenant/:tenantId/administration/:administrationId/unblock_journals"
                                            role={['operations', 'cs']}
                                            exact
                                        >
                                            <UnblockJournals />
                                        </AuthorizedRoute>
                                        <AuthorizedRoute
                                            path="/tenant/:tenantId/administration/:administrationId/bizcuit_diagnostics"
                                            role={['operations', 'cs']}
                                            exact
                                        >
                                            <BizcuitDiagnostics />
                                        </AuthorizedRoute>
                                        <AuthorizedRoute
                                            path="/tenant/:tenantId/administration/:administrationId/fix_sh_statuscodes"
                                            role={['operations', 'cs']}
                                            exact
                                        >
                                            <FixSHStatus />
                                        </AuthorizedRoute>
                                        <AuthorizedRoute
                                            path="/tenant/:tenantId/administration/:administrationId/reorder_journal_page"
                                            role={['operations', 'cs']}
                                            exact
                                        >
                                            <ReorderJournalPage />
                                        </AuthorizedRoute>
                                    </Switch>
                                </AuthorizedRoute>
                            </Switch>
                        </Route>
                        <Route path="/profile" component={Profile} />
                        <Route path="/unauthorized" component={Unauthorized} />
                        <Route path="*" component={NoMatch} />
                    </Switch>
                </AuthenticatedRoute>
                <Route path="*" component={NoMatch} />
            </Switch>
        </Router>
    );
});

export default Routing;
