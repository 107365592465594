import request, { ResponseError, SuperAgentRequest } from 'superagent';

export const ErrorApiResult: ApiResult<undefined> = {
    result: 'error',
    message: 'There was an error',
    value: undefined,
};

export type ApiResult<T> = {
    result: 'ok' | 'unauthorized' | 'forbidden' | 'error';
    message: string;
    value: T;
};

export const createApiResult = async <T>(request: SuperAgentRequest): Promise<ApiResult<T>> => {
    let response: request.Response;
    const result: ApiResult<T> = {
        result: 'error',
        message: '',
        value: undefined,
    };
    try {
        response = await request;
    } catch (error) {
        response = (error as ResponseError).response;
    }

    result.result = 'error';
    result.message =
        response?.body ||
        response?.text ||
        (response?.serverError && 'Er is een serverfout opgetreden') ||
        'Er is iets mis gegaan';

    if (response?.status >= 200 && response?.status <= 399) {
        result.result = 'ok';
        result.value = response.body as T;
    }

    if (response?.status === 400) {
        result.result = 'error';
        result.message = response.body?.[0]?.message ?? 'Er is iets verkeerd ingestuurd';
        return result;
    }

    if (response?.status === 401) {
        result.result = 'unauthorized';
        result.message = 'De gebruiker is niet geautoriseerd';
        return result;
    }

    if (response?.status === 403) {
        result.result = 'forbidden';
        result.message = 'Er zijn niet voldoende rechten voor deze opdracht';
        return result;
    }

    if (response?.status >= 400 && response?.status <= 499) {
        result.result = 'error';
        result.message = response.body?.[0]?.message ?? 'Er is iets verkeerd ingestuurd';
    }

    if (response?.status >= 500 && response?.status <= 599) {
        result.result = 'error';
        result.message = response.body?.[0]?.message ?? 'Er is iets mis gegaan';
    }

    return result;
};
