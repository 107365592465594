import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, DialogContentText, Grid, Paper } from '@material-ui/core';
import { useMobxStores } from '../../hooks/useMobxStores';
import { SearchOption } from '../../stores/user';
import MnxAutocomplete from '../../components/mnx-autocomplete';
import MnxButton from '../../components/mnx-button';
import { useToaster } from '../../hooks/useToaster';

const ChangePassword = observer(() => {
    const inputRef = useRef(null);
    const { addToast } = useToaster();
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [options, setOptions] = useState<SearchOption[]>([]);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    const {
        userStore: { search, resetPassword },
        applicationStore: { tenant, reason },
    } = useMobxStores();

    useEffect(() => {
        inputRef?.current?.focus();
    }, []);

    return (
        <>
            <Paper style={{ padding: 16 }} elevation={3}>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <DialogContentText>Kies een gebruiker om het wachtwoord voor te resetten:</DialogContentText>
                        <MnxAutocomplete
                            id="gebruiker"
                            ref={inputRef}
                            loading={loading}
                            getOptionLabel={(option: SearchOption) => option?.value || ''}
                            label="Selecteer een gebruiker"
                            values={options}
                            onValueSelected={(value: SearchOption) => {
                                setUserId(value?.id || '');
                            }}
                            onSearchChange={(term: string) => {
                                clearTimeout(timeoutId);
                                setTimeoutId(
                                    setTimeout(() => {
                                        setLoading(true);
                                        search(term, tenant.tenant_id, reason).then((users) => {
                                            setLoading(false);
                                            setOptions(users);
                                        });
                                    }, 500),
                                );
                                setUserId('');
                            }}
                        />
                    </Grid>
                </Grid>
                <Box py={1}>
                    <Grid item>
                        <MnxButton
                            onClick={() => {
                                resetPassword(userId, tenant.tenant_id, reason).then((apiResult) => {
                                    if (apiResult.result === 'ok') {
                                        addToast('Wachtwoord succesvol gereset!', { variant: 'success' });
                                    }
                                    if (apiResult.result === 'unauthorized') {
                                        addToast(apiResult.message, {
                                            variant: 'warning',
                                        });
                                    }
                                    if (['error', 'forbidden'].includes(apiResult.result)) {
                                        addToast(apiResult.message, { variant: 'error' });
                                    }
                                });
                            }}
                            disabled={!userId}
                            color="primary"
                        >
                            Reset wachtwoord
                        </MnxButton>
                    </Grid>
                </Box>
            </Paper>
        </>
    );
});

export default ChangePassword;
