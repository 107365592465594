import React, { useEffect, useState } from 'react';
import { GridColDef } from '@material-ui/data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxDataGrid from '../../components/data-grid';
import MnxToolbar from '../../components/mnx-toolbar';
import MnxButton from '../../components/mnx-button';
import CloseServoyPopup from './closeServoyPopup';
import { useToaster } from '../../hooks/useToaster';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 340 },
    { field: 'name', headerName: 'Naam', width: 220 },
    { field: 'whoami', headerName: 'URL', width: 180 },
    {
        field: 'in_maintenance',
        headerName: 'Onderhoudsmodus',
        width: 180,
        valueGetter: (params) => (params.row.in_maintenance ? 'Ja' : 'Nee'),
    },
];

const useStyles = makeStyles((theme) => ({
    toolbar: {
        flexGrow: 0,
    },
}));

const Servers = observer(() => {
    const {
        serverStore: { servers, activate, deactivate, setMaintenanceMode, selected, setSelected, closeServoy },
    } = useMobxStores();

    useEffect(() => {
        activate();
        setShowGrid(true);
        return () => {
            deactivate();
        };
    }, []);

    const { addToast } = useToaster();
    const theme = useTheme();
    const classes = useStyles();
    const [showGrid, setShowGrid] = useState(false);
    const [popup, setPopup] = useState(undefined);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {popup === 'close_servoy_popup' && (
                <CloseServoyPopup
                    handleSubmit={() => {
                        closeServoy().then((success) => {
                            if (success) {
                                addToast('Servoy gesloten!', { variant: 'success' });
                            } else {
                                addToast('Servoy sluiten mislukt!', { variant: 'error' });
                            }
                        });
                        setPopup(null);
                    }}
                    handleClose={() => setPopup(null)}
                />
            )}
            <MnxToolbar className={classes.toolbar}>
                <MnxButton
                    key={'1'}
                    color="primary"
                    onClick={() => {
                        setMaintenanceMode('enable').then((success) => {
                            if (success) {
                                addToast('Onderhoudsmodus geactiveerd!', { variant: 'success' });
                            } else {
                                addToast('Onderhoudsmodus activeren mislukt!', { variant: 'error' });
                            }
                        });
                    }}
                >
                    Onderhoudsmodus activeren
                </MnxButton>
                <MnxButton
                    key={'2'}
                    color="primary"
                    onClick={() => {
                        setMaintenanceMode('disable').then((success) => {
                            if (success) {
                                addToast('Onderhoudsmodus gedeactiveerd!', { variant: 'success' });
                            } else {
                                addToast('Onderhoudsmodus deactiveren mislukt!', { variant: 'error' });
                            }
                        });
                    }}
                >
                    Onderhoudsmodus deactiveren
                </MnxButton>
                <MnxButton
                    key={'23'}
                    color="primary"
                    mnxColor={theme.palette.error.main}
                    onClick={() => {
                        setPopup('close_servoy_popup');
                    }}
                >
                    Servoy sluiten
                </MnxButton>
            </MnxToolbar>
            <div style={{ flexGrow: 1, width: '100%' }}>
                {showGrid && (
                    <MnxDataGrid
                        rows={servers}
                        columns={columns}
                        pageSize={5}
                        onSelectionModelChange={(newSelection) => {
                            setSelected(newSelection);
                        }}
                        selectionModel={selected}
                        checkboxSelection
                    />
                )}
            </div>
        </div>
    );
});

export default Servers;
