import superagent from 'superagent';
import { rootStore } from '../hooks/useMobxStores';

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

function request(method: Method, url: string, authenticated = true): superagent.SuperAgentRequest {
    const request = superagent(method, url);
    return authenticated
        ? request.set({ authorization: `bearer ${rootStore.oauthStore?.token?.access_token}` })
        : request;
}

export default request;
