/* eslint-disable lines-between-class-members */
import { action, makeObservable } from 'mobx';
import autoBind from 'auto-bind';
import { BillingInformation, IMiscApi, OldBillingInformation } from '../api/miscellaneous';
import ApplicationStore from './application';

const toISO = (input: string): string => {
    const [year, month, day] = input.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day), 12, 0, 0).toISOString();
};

const isOldBillingInformation = (x: unknown): x is OldBillingInformation => true;

export class MiscStore {
    api: IMiscApi;
    applicationStore: ApplicationStore;

    constructor({ api, applicationStore }: { api: IMiscApi; applicationStore: ApplicationStore }) {
        makeObservable(this, {
            activate: action,
            deactivate: action,
        });
        this.api = api;
        this.applicationStore = applicationStore;
        autoBind(this);
    }

    async sendBilling(information: BillingInformation | OldBillingInformation, type: 'old' | 'new'): Promise<boolean> {
        try {
            if (type === 'old' && isOldBillingInformation(information)) {
                information.billingFromDate = toISO(information.billingFromDate);
                information.billingUntilDate = toISO(information.billingUntilDate);
            }
            await this.api.sendBilling(information, type, this.applicationStore.reason);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async updateConfiguration(): Promise<boolean> {
        try {
            await this.api.updateConfiguration();
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async updateMetering(): Promise<boolean> {
        try {
            await this.api.updateMetering(this.applicationStore.reason);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async deleteConnectionChamberOfCommerce(
        chamberOfCommerceNumber: string,
    ): Promise<{ success: boolean; deleted: boolean }> {
        try {
            return await this.api.deleteConnectionChamberOfCommerce(
                chamberOfCommerceNumber,
                this.applicationStore.reason,
            );
        } catch (error) {
            console.error(error);
            return {
                success: false,
                deleted: false,
            };
        }
    }

    activate(): void {
        console.error('Unimplemented');
    }

    deactivate(): void {
        console.error('Unimplemented');
    }
}
