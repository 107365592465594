import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useMobxStores } from '../../hooks/useMobxStores';
import { Avatar, Divider, TextField, Card, CardContent, FormControlLabel, Switch } from '@material-ui/core';
import MnxButton from '../../components/mnx-button';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        wordBreak: 'break-word',
    },
    columnBox: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    avatar: {
        height: '100px',
        width: '100px',
        fontSize: '50px',
        marginBottom: theme.spacing(2),
    },
    cardFooter: {
        padding: theme.spacing(2),
    },
    saveNameButton: {
        whiteSpace: 'nowrap',
        marginLeft: 'auto',
    },
}));

const Profile = observer(() => {
    const classes = useStyles();
    const {
        oauthStore: { name, updateName },
        applicationStore: { isDarkMode, setIsDarkMode },
    } = useMobxStores();

    const [newName, setNewName] = useState(name);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                    <Card>
                        <CardContent className={classes.columnBox}>
                            <Avatar className={classes.avatar}>{newName?.substring(0, 2)?.toUpperCase()}</Avatar>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Naam'}
                                onChange={(event) => setNewName(event.target.value)}
                                value={newName}
                            />
                        </CardContent>
                        <Divider />
                        <Box display="flex" width={1} className={classes.cardFooter}>
                            <MnxButton
                                color="primary"
                                className={classes.saveNameButton}
                                disabled={newName?.length < 1 || newName === name}
                                onClick={() => updateName(newName)}
                            >
                                Opslaan
                            </MnxButton>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardContent className={classes.columnBox}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isDarkMode || false}
                                            onChange={(event) => {
                                                setIsDarkMode(event.target.checked);
                                            }}
                                        />
                                    }
                                    label="Donkere modus"
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
});

export default Profile;
