import request from '../utils/request';
import env from '../env';

export type ServoyServer = {
    server_id: string;
    name: string;
    in_maintenance: boolean;
    whoami: string;
};

export interface IServerStoreApi {
    getActiveServers(): Promise<ServoyServer[]>;
    setMaintenanceMode(mode: 'enable' | 'disable'): Promise<void>;
    closeServoy(): Promise<void>;
}

export class ServerStoreApi implements IServerStoreApi {
    async getActiveServers(): Promise<ServoyServer[]> {
        return request('GET', `${env.domain}/admin/server`).then((res) => res.body);
    }

    async setMaintenanceMode(mode = 'enable'): Promise<void> {
        await request('POST', `${env.domain}/admin/server/maintenance/${mode}`);
    }

    async closeServoy(): Promise<void> {
        await request('PUT', `${env.domain}/admin/server/kill_servers`);
    }
}
