import { Route, RouteProps } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';

export type AuthenticatedRouteProps = { layout: React.ElementType } & RouteProps;

const AuthenticatedRoute = observer(({ layout, children, ...rest }: AuthenticatedRouteProps) => {
    const { oauthStore } = useMobxStores();
    const isLoggedIn = oauthStore.isLoggedIn;
    const Layout = layout;
    return (
        <Route
            {...rest}
            render={() => {
                oauthStore.redirectToLoginWhenNeeded();
                return <Layout children={isLoggedIn !== undefined ? children : <div></div>} />;
            }}
        />
    );
});

export default AuthenticatedRoute;
