import { action, computed, makeObservable, observable } from 'mobx';
import autoBind from 'auto-bind';
import { createBrowserHistory } from 'history';

export default class NavigationStore {
    history = createBrowserHistory();

    constructor() {
        autoBind(this);

        makeObservable(this, {
            history: observable,
            push: action,
            currentPath: computed,
        });
    }

    push(location: string, state?: any): void {
        this.history.push(location, state);
    }

    replace(location: string): void {
        this.history.replace(location);
    }

    go(n: number): void {
        this.history.go(n);
    }

    goBack(): void {
        this.history.goBack();
    }

    goForward(): void {
        this.history.goForward();
    }

    get currentPath(): string {
        return this.history?.location?.pathname;
    }
}
