/* eslint-disable lines-between-class-members */
import { observable, action, makeObservable, runInAction, computed } from 'mobx';
import autoBind from 'auto-bind';

import {
    customerSupportRoutes,
    defaultRoutes,
    managementRoutes,
    operationsRoutes,
    Route,
    salesRoutes,
} from '../routes';
import { SimpleTenant } from '../api/tenant';
import { ServoyServer } from '../api/server';
import { ServoyClient } from '../api/client';
import OAuthStore from './oauth';
import NavigationStore from './navigation';
import { ISearchApi } from '../api/search';
import { ServerStore } from './server';
import ApplicationStore from './application';

export type SearchOption = {
    type: string;
    value: string;
    search: string;
    id?: string;
    nr?: number;
};

export default class SearchStore {
    searchApi: ISearchApi;
    navigationStore: NavigationStore;
    serverStore: ServerStore;
    oauthStore: OAuthStore;
    applicationStore: ApplicationStore;
    servers: ServoyServer[];
    clients: ServoyClient[];
    tenants: SimpleTenant[];
    timeout_id: NodeJS.Timeout;
    loading = false;
    routes = defaultRoutes
        .concat(salesRoutes)
        .concat(managementRoutes)
        .concat(customerSupportRoutes)
        .concat(operationsRoutes);

    constructor({
        searchApi,
        navigationStore,
        oauthStore,
        serverStore,
        applicationStore,
    }: {
        searchApi: ISearchApi;
        navigationStore: NavigationStore;
        oauthStore: OAuthStore;
        serverStore: ServerStore;
        applicationStore: ApplicationStore;
    }) {
        this.searchApi = searchApi;
        this.navigationStore = navigationStore;
        this.applicationStore = applicationStore;
        this.serverStore = serverStore;
        this.oauthStore = oauthStore;
        makeObservable(this, {
            loading: observable,
            servers: observable,
            clients: observable,
            tenants: observable,
            tenantOptions: computed,
            options: computed,
            onSelect: action,
            getData: action,
            search: action,
        });
        autoBind(this);
    }

    get tenantOptions(): SearchOption[] {
        return (
            this.tenants?.map((tenant: SimpleTenant) => ({
                nr: tenant.tenant_nr,
                id: tenant.tenant_id,
                type: 'Tenant',
                value: tenant.name ? `${tenant.tenant_nr} - ${tenant.name}` : 'Nameless tenant',
                search: `${tenant.name} ${tenant.database} ${tenant.tenant_id} ${tenant.tenant_nr}`,
            })) || []
        );
    }

    get options(): SearchOption[] {
        const routes: SearchOption[] = this.routes
            ?.filter((route: Route) => this.oauthStore.hasAnyRoles(route.requiredRoles))
            ?.map((route: Route) => ({
                type: 'Pagina',
                value: route.title,
                search: route.search,
            }));
        const servers: SearchOption[] = this.servers?.map((server: ServoyServer) => ({
            id: server.server_id,
            type: 'Server',
            value: server.name || 'Nameless server',
            search: `${server.name} ${server.server_id} ${server.whoami}`,
        }));
        const clients: SearchOption[] = this.clients?.map((client: ServoyClient) => ({
            id: client.client_id,
            type: 'Sessie',
            value: client.solution_name || 'Nameless session',
            search: `${client.ownerid} ${client.administrationid} ${client.ownernr} ${client.administrationnr} ${client.fiscalyear} ${client.server_id} ${client.client_id}`,
        }));
        return (routes || [])
            .concat(this.tenantOptions)
            .concat(servers || [])
            .concat(clients || []);
    }

    onSelect(selectedOption: SearchOption): void {
        if (!selectedOption?.value) return;
        if (selectedOption.type === 'Pagina') {
            const route = this.routes.find((route) => route.title === selectedOption.value);
            if (!route) return;
            this.navigationStore.push(route.path);
        }
        if (selectedOption.type === 'Server') {
            const server = this.servers.find((server) => server.name === selectedOption.value);
            if (!server) return;
            this.navigationStore.push('/servers');
            this.serverStore.setSelected([server.server_id]);
        }
        if (selectedOption.type === 'Tenant') {
            this.applicationStore.setTenantId(selectedOption.id);
            this.navigationStore.push(`/tenant/${selectedOption.id}`);
        }
    }

    async getData(term: string): Promise<void> {
        runInAction(() => {
            this.loading = true;
        });

        if (this.oauthStore.hasAnyRoles(['cs', 'operations'])) {
            const tenants = await this.searchApi.tenant(term).catch(() => []);
            runInAction(() => {
                this.tenants = tenants;
            });
        }

        if (this.oauthStore.hasAnyRoles(['operations'])) {
            const servers = await this.searchApi.server(term).catch(() => []);
            runInAction(() => {
                this.servers = servers;
            });
        }

        if (this.oauthStore.hasAnyRoles(['operations'])) {
            const clients = await this.searchApi.client(term).catch(() => []);
            runInAction(() => {
                this.clients = clients;
            });
        }

        runInAction(() => {
            this.loading = false;
        });
    }

    async search(term: string): Promise<void> {
        if (term === '' || term === null) return;

        clearTimeout(this.timeout_id);
        this.timeout_id = setTimeout(() => this.getData(term), 500);
    }
}
