import request from '../utils/request';
import { ServoyClient } from './client';
import { ServoyServer } from './server';
import { SimpleTenant } from './tenant';
import env from '../env';

export interface ISearchApi {
    server(term: string): Promise<ServoyServer[]>;
    client(term: string): Promise<ServoyClient[]>;
    tenant(term: string): Promise<SimpleTenant[]>;
}

export class SearchApi implements ISearchApi {
    async server(term: string): Promise<ServoyServer[]> {
        return request('GET', `${env.domain}/admin/search/server/${encodeURIComponent(term)}`).then((res) => res.body);
    }

    async client(term: string): Promise<ServoyClient[]> {
        return request('GET', `${env.domain}/admin/search/client/${encodeURIComponent(term)}`).then((res) => res.body);
    }

    async tenant(term: string): Promise<SimpleTenant[]> {
        return request('GET', `${env.domain}/admin/search/tenant/${encodeURIComponent(term)}`).then(
            (res) => res?.body?.collection,
        );
    }
}
