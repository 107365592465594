import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    box: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        background: '#f4f6f8',
    },
    text: {
        color: '#aaa',
    },
}));
const NoMatch: React.FunctionComponent = () => {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <Container>
                <Paper elevation={3}>
                    <Box px={3} py={3}>
                        <h1>Sorry, we hebben het geprobeerd.</h1>
                        <p>
                            <strong>-Echt- geprobeerd.</strong> We hebben ver gezocht en diep gehoopt. Toch, ondanks al
                            onze moeite, moeten we je teleurstellen.
                        </p>
                        <p>
                            Wij denken dat de pagina die jij zoekt niet bestaat en daarom sturen we je graag naar huis.
                        </p>
                        <p>
                            Mocht je overtuigd zijn van je gelijk en toch denken dat deze pagina bestaat, dan kan je
                            contact met ons opnemen. Wij denken, je weet ons te vinden.
                        </p>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

NoMatch.propTypes = {
    path: PropTypes.string,
};

export default NoMatch;
