/* eslint-disable no-unused-vars */
import request from '../utils/request';
import env from '../env';
import { ApiResult, createApiResult } from './apiResult';

export enum SortingByFirstEnum {
    EntryNumber = 'Boekstuknummer',
    DocumentDate = 'Boekdatum',
    CreatedAt = 'Creatiedatum en tijd in Minox',
}
export type SortingByFirstKeys = keyof typeof SortingByFirstEnum;

export enum SortingBySecondEnum {
    None = 'Geen',
    EntryNumber = 'Boekstuknummer',
    DocumentDate = 'Boekdatum',
    CreatedAt = 'Creatiedatum en tijd in Minox',
}
export type SortingBySecondKeys = keyof typeof SortingBySecondEnum;

export enum LineSortingEnum {
    None = 'Geen',
    AccountNumber = 'Grootboekrekeningnummer',
    CreatedAt = 'Creatiedatum en tijd in Minox',
}
export type LineSortingKeys = keyof typeof LineSortingEnum;

type ResortJournalPageParameters = {
    administrationId: string;
    tenantId: string;
    reason: string;
    year: number;
    journal: string;
    firstJournalPage: number;
    lastJournalPage: number;
    sortingByFirst: keyof typeof SortingByFirstEnum;
    sortingBySecond: keyof typeof SortingBySecondEnum;
    lineSorting: keyof typeof LineSortingEnum;
    compileEntries: boolean;
};

export interface IBookkeepingApi {
    resortJournalPage({
        administrationId,
        tenantId,
        reason,
        year,
        journal,
        firstJournalPage,
        lastJournalPage,
        sortingByFirst,
        sortingBySecond,
        lineSorting,
        compileEntries,
    }: ResortJournalPageParameters): Promise<ApiResult<void>>;
}

export class BookkeepingApi implements IBookkeepingApi {
    async resortJournalPage({
        administrationId,
        tenantId,
        reason,
        year,
        journal,
        firstJournalPage,
        lastJournalPage,
        sortingByFirst,
        sortingBySecond,
        lineSorting,
        compileEntries,
    }: ResortJournalPageParameters): Promise<ApiResult<void>> {
        return createApiResult<void>(
            request(
                'POST',
                `${env.domain}/admin/tenant/${tenantId}/administration/${administrationId}/reorder_journal_page`,
            )
                .set({ 'x-reason': reason })
                .set({ 'accept-language': 'nl' })
                .send({
                    year,
                    journal,
                    firstJournalPage,
                    lastJournalPage,
                    sortingByFirst,
                    sortingBySecond,
                    lineSorting,
                    compileEntries,
                }),
        );
    }
}
