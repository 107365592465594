/* eslint-disable lines-between-class-members */
import { action, makeObservable, observable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { Diagnostics, IBizcuitDiagnosticsApi } from '../api/bizcuitDiagnostics';
import ApplicationStore from './application';
import { ApiResult, ErrorApiResult } from '../api/apiResult';

export class BizcuitDiagnosticsStore {
    loading = false;
    api: IBizcuitDiagnosticsApi;
    applicationStore: ApplicationStore;
    diagnostics: Diagnostics[];

    constructor({ api, applicationStore }: { api: IBizcuitDiagnosticsApi; applicationStore: ApplicationStore }) {
        makeObservable(this, {
            refresh: action,
            diagnostics: observable,
        });
        this.api = api;
        this.applicationStore = applicationStore;
        this.diagnostics = [
            {
                problems: [],
            },
        ];
        autoBind(this);
    }

    async refresh(): Promise<ApiResult<Diagnostics[]>> {
        const { tenantId, administrationId, reason } = this.applicationStore;
        if (!administrationId || !tenantId || !reason) return ErrorApiResult;
        return this.api.getDiagnostics(tenantId, administrationId, reason).then((result: ApiResult<Diagnostics[]>) => {
            const diagnostics = result.value;
            runInAction(() => {
                this.diagnostics = diagnostics;
            });
            return result;
        });
    }
}
