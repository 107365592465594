import React, { useState } from 'react';
import { Box, Card, makeStyles, Typography, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import { useToaster } from '../../hooks/useToaster';
import TextField from '@material-ui/core/TextField';
import MnxDialog from '../../components/mnx-dialog';
import MnxToolbar from '../../components/mnx-toolbar';
import MnxButton from '../../components/mnx-button';

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        padding: theme.spacing(3),
    },
    bottomMargin: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    toolbar: {
        flexGrow: 0,
    },
}));

const Go2ubl = observer(() => {
    const {
        miscStore: { deleteConnectionChamberOfCommerce },
    } = useMobxStores();

    const classes = useStyles();
    const { addToast } = useToaster();
    const [chamberOfCommerceId, setChamberOfCommerceId] = useState('');
    const [popup, setPopup] = useState(false);
    const [showError, setShowError] = useState(false);
    const theme = useTheme();

    const validate = () => {
        setShowError(chamberOfCommerceId.length !== 8);
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {popup && (
                    <MnxDialog
                        title="Verwijder kvk nummer"
                        handleSubmit={() =>
                            deleteConnectionChamberOfCommerce(chamberOfCommerceId).then((apiResult) => {
                                if (apiResult.success && apiResult.deleted) {
                                    addToast('Kvk nummer is succesvol verwijderd.', { variant: 'success' });
                                } else if (apiResult.success && !apiResult.deleted) {
                                    addToast(
                                        'Dit kvk nummer kon niet gevonden worden. Heb je het juiste kvk nummer ingevuld?',
                                        { variant: 'error' },
                                    );
                                } else {
                                    addToast(
                                        'De connectie met de backend kon niet worden gemaakt, probeer het later nog eens.',
                                        { variant: 'error' },
                                    );
                                }
                            })
                        }
                        handleClose={() => setPopup(!popup)}
                        open={popup}
                        disabled={chamberOfCommerceId.length !== 8}
                    >
                        <TextField
                            focused
                            multiline
                            maxRows={4}
                            margin="dense"
                            value={chamberOfCommerceId}
                            fullWidth
                            onBlur={validate}
                            onChange={(event) => {
                                setChamberOfCommerceId(event.target.value);
                            }}
                            error={showError}
                            helperText={showError ? 'Vul een kvk nummer van 8 cijfers in' : ''}
                        />
                    </MnxDialog>
                )}

                <Box mb={2}>
                    <Card>
                        <Box m={2}>
                            <Typography>
                                Klik op de knop hieronder als je zeker weet dat je een kvk nummer wilt vrijgeven.
                            </Typography>
                            <Box pt={2}>
                                <MnxToolbar className={classes.toolbar}>
                                    <MnxButton
                                        key="1"
                                        color="primary"
                                        mnxColor={theme.palette.primary.main}
                                        onClick={() => setPopup(true)}
                                    >
                                        Verwijder een kvk nummer
                                    </MnxButton>
                                </MnxToolbar>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </div>
        </>
    );
});

export default Go2ubl;
