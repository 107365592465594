import React, { BaseSyntheticEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Paper,
    Card,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CardContent,
} from '@material-ui/core';
import MnxButton from '../../components/mnx-button';
import { useMobxStores } from '../../hooks/useMobxStores';
import environment, { domains } from '../../env';

const useStyles = makeStyles((theme) => ({
    box: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    header: {
        marginTop: 0,
    },
    button: {},
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    text: {
        marginTop: 'auto',
        marginBottom: 'auto',
        color: '#aaa',
    },
    h3: {
        margin: theme.spacing(2),
        marginBottom: 0,
    },
    card: {
        marginTop: theme.spacing(3),
    },
}));

const Logout = observer(() => {
    const classes = useStyles();
    const { oauthStore } = useMobxStores();
    const history = useHistory();

    useEffect(() => {
        oauthStore.clearAuthorization();
    }, []);

    return (
        <Box className={classes.box}>
            <Container maxWidth="sm">
                <Paper elevation={3}>
                    <Box px={3} py={3}>
                        <h1 className={classes.header}>Tot ziens!</h1>
                        <p>
                            Leuk dat je ons hebt meegeholpen met dingen beter maken voor iedereen! We hopen je nog een
                            keer terug te zien!
                        </p>
                        <div className={classes.footer}>
                            <p className={classes.text}>* Toegang wordt automatisch gelogd.</p>
                            <MnxButton
                                variant="text"
                                className={classes.button}
                                onClick={() => {
                                    history.push('/login');
                                }}
                            >
                                Inloggen
                            </MnxButton>
                        </div>
                    </Box>
                </Paper>
                <Card className={classes.card}>
                    <h3 className={classes.h3}>Serverselectie</h3>
                    <CardContent>
                        <FormControl fullWidth>
                            <InputLabel id="select-server-label">Server</InputLabel>
                            <Select
                                labelWidth={60}
                                labelId="select-server-label"
                                id="select-server-input"
                                margin="dense"
                                value={environment.domain}
                                onChange={(event: BaseSyntheticEvent) => environment.setDomain(event.target.value)}
                            >
                                {Object.entries(domains).map(([key, value]) => (
                                    <MenuItem key={key} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
});

export default Logout;
