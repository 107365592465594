import request from '../utils/request';
import env from '../env';

export type OldBillingInformation = {
    generateFairUsageReport: boolean;
    generateStoreCoveInformation: boolean;
    billingFromDate: string;
    billingUntilDate: string;
};

export type BillingInformation = {
    year: string;
    month: string;
};

export interface IMiscApi {
    sendBilling(
        information: BillingInformation | OldBillingInformation,
        type: 'old' | 'new',
        reason: string,
    ): Promise<void>;

    updateConfiguration(): Promise<void>;
    updateMetering(reason: string): Promise<void>;
    deleteConnectionChamberOfCommerce(
        chamberOfCommerceNumber: string,
        reason: string,
    ): Promise<{ success: boolean; deleted: boolean }>;
}

export class MiscApi implements IMiscApi {
    async sendBilling(
        information: BillingInformation | OldBillingInformation,
        type: 'old' | 'new',
        reason: string,
    ): Promise<void> {
        await request('POST', `${env.domain}/admin/sales/send${type === 'old' ? 'old' : ''}`)
            .set({ 'x-reason': reason })
            .send({
                billingInformation: information,
            });
    }

    async updateConfiguration(): Promise<void> {
        await request('PATCH', `${env.domain}/admin/configuration`);
    }

    async updateMetering(reason: string): Promise<void> {
        await request('POST', `${env.domain}/admin/billing/update_insight`).set({
            'x-reason': reason,
        });
    }

    async deleteConnectionChamberOfCommerce(
        chamberOfCommerceNumber: string,
        reason: string,
    ): Promise<{ success: boolean; deleted: boolean }> {
        return await request('DELETE', `${env.domain}/admin/go2ubl/${chamberOfCommerceNumber}`)
            .set({
                'x-reason': reason,
            })
            .then((res) => res.body);
    }
}
