import React, { ReactElement, ReactNode, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import AvgBar from './AvgBar';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    box: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(3),
        background: 'rgba(100, 100, 100, 0.005)',
    },
    chip: {
        background: '#ffdf35',
    },
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));

const Layout: React.FunctionComponent = (props: { children?: any }) => {
    const { children } = props;
    const classes = useStyles();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <Navbar setSidebarOpen={() => setSidebarOpen(!sidebarOpen)} />
            <div style={{ display: 'flex', flex: '1 1 auto', flexDirection: 'row', overflow: 'hidden' }}>
                <Sidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
                <Box className={classes.box}>
                    <AvgBar />
                    <Container maxWidth="xl" className={classes.container}>
                        {children}
                    </Container>
                </Box>
            </div>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.element,
};

export default Layout;
