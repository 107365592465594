/* eslint-disable lines-between-class-members */
import { computed, action, makeObservable, observable, runInAction, IObservableArray } from 'mobx';
import autoBind from 'auto-bind';
import { IApiLogApi, ApiLog } from '../api/apilog';
import ApplicationStore from './application';

export class ApiLogStore {
    data: ApiLog[];
    loading = false;
    api: IApiLogApi;
    applicationStore: ApplicationStore;
    totalCount: number;
    currentPage: number;
    pageSize: number;
    search: string;
    startDate: number;
    endDate: number;
    backendPageSize: number;

    constructor({ api, applicationStore }: { api: IApiLogApi; applicationStore: ApplicationStore }) {
        makeObservable(this, {
            logs: computed,
            count: computed,
            getData: action,
            setCurrentPage: action,
            setPageSize: action,
            setSearch: action,
            search: observable,
            data: observable,
            currentPage: observable,
            loading: observable,
            setLoading: action,
        });
        this.api = api;
        this.applicationStore = applicationStore;
        this.backendPageSize = 0;
        this.data = observable.array([
            {
                id: '0',
                active: true,
                client_id: '',
                database_name: '',
                method: '',
                response_status: -1,
                response_time: 0,
                tenant_id: '',
                tenant_nr: 0,
                tenants: [],
                time: 0,
                url: '',
                user_id: '',
            },
        ]);
        autoBind(this);
    }

    get logs(): ApiLog[] {
        return this.data;
    }

    get count(): number {
        return this.data.length;
    }

    async getData(): Promise<void> {
        const frontendPagesPerBackendPage = this.backendPageSize / this.pageSize;
        const backendPage = Math.floor(this.currentPage / frontendPagesPerBackendPage) + 1 || 1;

        const indexToFillFrom = (backendPage - 1) * this.backendPageSize;

        if (this.data[indexToFillFrom]?.response_status !== -1) return;

        this.setLoading(true);
        const logsFromApi = await this.api.getLogs({
            reason: this.applicationStore.reason,
            search: this.search,
            from: this.startDate ? new Date(this.startDate).toISOString() : null,
            to: this.endDate ? new Date(this.endDate).toISOString() : null,
            page: backendPage,
        });
        this.setLoading(false);

        runInAction(() => {
            for (let i = 0; i < logsFromApi.collection.length; i += 1) {
                this.data[i + indexToFillFrom] = logsFromApi.collection[i];
            }
        });
    }

    async setCurrentPage(currentPage: number) {
        this.currentPage = currentPage + 1;
        this.getData();
    }

    async setPageSize(pageSize: number) {
        this.pageSize = pageSize;
        this.getData();
    }

    async setLoading(loading: boolean) {
        this.loading = loading;
    }

    async setSearch(
        { search, startDate, endDate }: { search: string; startDate: number; endDate: number },
        isInitialRender?: boolean,
    ) {
        this.search = search;
        this.startDate = startDate;
        this.endDate = endDate;

        if (!isInitialRender) {
            (this.data as IObservableArray).clear();
        }

        this.setLoading(true);
        const logsFromApi = await this.api.getLogs({
            reason: this.applicationStore.reason,
            search,
            from: startDate ? new Date(startDate).toISOString() : null,
            to: endDate ? new Date(endDate).toISOString() : null,
            page: 1,
        });
        this.setLoading(false);

        this.backendPageSize = logsFromApi.collection.length;

        runInAction(() => {
            this.currentPage = 1;
            for (let index = 0; index < logsFromApi.count; index++) {
                this.data[index] = logsFromApi.collection[index] || {
                    id: index.toString(),
                    active: true,
                    client_id: '',
                    database_name: '',
                    method: '',
                    response_status: -1,
                    response_time: 0,
                    tenant_id: '',
                    tenant_nr: 0,
                    tenants: [],
                    time: 0,
                    url: '',
                    user_id: '',
                };
            }
        });
    }

    async printSearchAsJSON({ search, startDate, endDate }: { search: string; startDate: number; endDate: number }) {
        this.search = search;
        this.startDate = startDate;
        this.endDate = endDate;

        let page = 1;
        let response;
        const collection = [];
        do {
            response = await this.api.getLogs({
                reason: this.applicationStore.reason,
                search,
                from: startDate ? new Date(startDate).toISOString() : null,
                to: endDate ? new Date(endDate).toISOString() : null,
                page,
            });
            page += 1;
            collection.push(...(response?.collection || []));
        } while (response?.collection?.length);

        const blob = new Blob([JSON.stringify(collection, null, 2)], { type: 'application/json' });
        const link = document.createElement('a');
        link.download = `logs-${new Date().toISOString()}.json`;
        link.href = window.URL.createObjectURL(blob);
        link.click();
    }
}
