/* eslint-disable lines-between-class-members */
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { IClientStoreApi, ServoyClient } from '../api/client';
import OAuthStore from './oauth';
import { ServerStore } from './server';
import { TenantStore } from './tenant';
import sleep from '../utils/sleep';
import ApplicationStore from './application';
import { ResponseError } from 'superagent';

export class ClientStore {
    data: ServoyClient[] = [];
    loading = false;
    api: IClientStoreApi;
    oauthStore: OAuthStore;
    serverStore: ServerStore;
    tenantStore: TenantStore;
    applicationStore: ApplicationStore;

    constructor({
        api,
        oauthStore,
        serverStore,
        tenantStore,
        applicationStore,
    }: {
        api: IClientStoreApi;
        oauthStore: OAuthStore;
        serverStore: ServerStore;
        tenantStore: TenantStore;
        applicationStore: ApplicationStore;
    }) {
        autoBind(this);
        makeObservable(this, {
            activate: action,
            deactivate: action,
            clients: computed,
            data: observable,
            getData: action,
        });
        this.api = api;
        this.data = [];
        this.oauthStore = oauthStore;
        this.serverStore = serverStore;
        this.tenantStore = tenantStore;
        this.applicationStore = applicationStore;
    }

    async getData(): Promise<void> {
        this.loading = true;
        try {
            const clients = await this.api.getActiveClients();
            runInAction(async () => {
                this.data = clients;
            });
            this.clients.forEach(async (client) => {
                if (client.ownerid && !this.tenantStore.simpleTenants.has(client.ownerid)) {
                    await this.tenantStore.getSimpleTenant(client.ownerid);
                }
                if (
                    client.realownerid &&
                    client.ownerid !== client.realownerid &&
                    !this.tenantStore.simpleTenants.has(client.realownerid)
                ) {
                    await this.tenantStore.getSimpleTenant(client.realownerid);
                }
            });
        } catch (error) {
            this.loading = false;
            if ((error as ResponseError)?.response?.status === 401) {
                this.oauthStore.redirectToLogin();
            }
        }
        this.loading = false;
    }

    get clients(): ServoyClient[] {
        if (!this.data?.length) return [];
        return this.data.map((client) => ({
            ...client,
            id: client.client_id,
            owner_name: client.ownerid && this.tenantStore.simpleTenants.get(client.ownerid)?.name,
            real_owner_name: client.realownerid && this.tenantStore.simpleTenants.get(client.realownerid)?.name,
            server: this.serverStore?.data?.find((server) => server?.server_id === client?.server_id)?.whoami,
        }));
    }

    async sendMessageToAllClients(message: string): Promise<void> {
        try {
            await this.api.sendMessageToAllClients(message);
        } catch (error) {
            console.error(error);
        }
    }

    async disconnectSessions(sessionsToClose: string[]): Promise<void> {
        let promise;
        if (sessionsToClose.length) {
            promise = this.api.disconnectClients(sessionsToClose);
        } else {
            promise = this.api.disconnectAllClients();
        }
        promise.then(() => {
            sleep(10000).then(() => {
                this.getData();
            });
        });
    }

    async activate(): Promise<void> {
        await this.serverStore.getData();
        await this.getData();
    }

    deactivate(): void {
        console.error('Unimplemented');
    }
}
