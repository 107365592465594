/* eslint-disable lines-between-class-members */
import { action, makeObservable } from 'mobx';
import autoBind from 'auto-bind';
import { InternalInvoiceStatus, IInternalInvoiceApi } from '../api/internalinvoice';
import { ApiResult, ErrorApiResult } from '../api/apiResult';
import ApplicationStore from './application';

export class InternalInvoiceStore {
    loading = false;
    api: IInternalInvoiceApi;
    applicationStore: ApplicationStore;

    constructor({ api, applicationStore }: { api: IInternalInvoiceApi; applicationStore: ApplicationStore }) {
        makeObservable(this, {
            uploadCSV: action,
        });
        this.api = api;
        this.applicationStore = applicationStore;
        autoBind(this);
    }

    async uploadCSV(files: File[]): Promise<ApiResult<undefined>> {
        const { reason } = this.applicationStore;
        if (!reason) return ErrorApiResult;
        return this.api.upload(files, this.applicationStore.reason);
    }

    async status(): Promise<ApiResult<InternalInvoiceStatus>> {
        const { reason } = this.applicationStore;
        if (!reason) return ErrorApiResult;
        return this.api.status(this.applicationStore.reason);
    }
}
