import React, { useRef, useState } from 'react';
import { Popover, Box, IconButton } from '@material-ui/core';
import { GridCellParams } from '@material-ui/data-grid';
import { FileCopyOutlined, Visibility } from '@material-ui/icons';
import { useToaster } from '../../hooks/useToaster';

const ApiLogButtons: React.FunctionComponent<{ params: GridCellParams }> = ({ params }) => {
    const [open, setOpen] = useState(false);
    const { addToast } = useToaster();
    const ref = useRef();

    return (
        <div style={{ height: '100%' }} ref={ref}>
            <IconButton onClick={() => setOpen(!open)}>
                <Visibility />
            </IconButton>
            <IconButton
                onClick={() => {
                    navigator?.clipboard?.writeText(params?.row ? JSON.stringify(params.row, null, 2) : '');
                    addToast('Gekopieerd naar klembord!');
                }}
            >
                <FileCopyOutlined />
            </IconButton>
            <Popover
                open={open}
                anchorEl={ref?.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: { marginLeft: '-4px' },
                }}
            >
                <Box px={3} style={{ overflow: 'auto' }}>
                    <pre>{JSON.stringify(params?.row, null, 2)}</pre>
                </Box>
            </Popover>
        </div>
    );
};

export default ApiLogButtons;
