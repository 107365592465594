import request from '../utils/request';
import { ApiResult, createApiResult } from './apiResult';
import env from '../env';

export type Administration = {
    id: string;
    nr: number;
    owner_id: string;
    name: string;
};

export type AdministrationMeta = {
    administration_id: string;
    administration_nr: number;
    name: string;
    tenant_id: string;
    search?: string;
};

export type FixSHStatusResponse = {
    changed: number;
};

export interface IAdministrationStoreApi {
    getAdministration(tenantId: string, administrationId: string, reason: string): Promise<Administration>;
    getAdministrationMeta(tenantId: string, reason: string): Promise<AdministrationMeta[]>;
    unblockPayables(tenantId: string, administrationId: string, reason: string): Promise<ApiResult<void>>;
    unblockJournals(tenantId: string, administrationId: string, reason: string): Promise<ApiResult<void>>;
}

export class AdministrationStoreApi implements IAdministrationStoreApi {
    async getAdministration(tenantId: string, administrationId: string, reason: string): Promise<Administration> {
        try {
            return await request('GET', `${env.domain}/admin/tenant/${tenantId}/administration/${administrationId}`)
                .set({ 'x-reason': reason })
                .then((res) => res.body);
        } catch (e) {
            console.error(e);
            return;
        }
    }

    async getAdministrationMeta(tenantId: string, reason: string): Promise<AdministrationMeta[]> {
        try {
            return await request('GET', `${env.domain}/admin/tenant/${tenantId}/administration`)
                .set({ 'x-reason': reason })
                .then((res) => res.body.collection);
        } catch (e) {
            console.error(e);
            return;
        }
    }

    async unblockPayables(tenantId: string, administrationId: string, reason: string): Promise<ApiResult<void>> {
        return createApiResult(
            request(
                'POST',
                `${env.domain}/admin/tenant/${tenantId}/administration/${administrationId}/force_unblock_payables`,
            ).set({ 'x-reason': reason }),
        );
    }

    async unblockJournals(tenantId: string, administrationId: string, reason: string): Promise<ApiResult<void>> {
        return createApiResult(
            request(
                'POST',
                `${env.domain}/admin/tenant/${tenantId}/administration/${administrationId}/force_unblock_journals`,
            ).set({ 'x-reason': reason }),
        );
    }

    async fixSHStatus(
        tenantId: string,
        administrationId: string,
        reason: string,
    ): Promise<ApiResult<FixSHStatusResponse>> {
        return createApiResult(
            request(
                'POST',
                `${env.domain}/admin/tenant/${tenantId}/administration/${administrationId}/fix_sh_statuscodes`,
            ).set({ 'x-reason': reason }),
        );
    }
}
