import React, { useRef, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { GridCellParams } from '@material-ui/data-grid';
import { Settings, Info } from '@material-ui/icons';
import { useToaster } from '../../hooks/useToaster';
import SettingsPopup from './settingsPopup';
import InfoPopup from './infoPopup';
import { Partner } from '../../api/partner';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';

const Buttons = observer<{ params: GridCellParams }>(({ params }) => {
    const {
        partnerStore: { update },
    } = useMobxStores();
    const [popup, setPopup] = useState('');
    const { addToast } = useToaster();
    const ref = useRef();

    return (
        <div style={{ height: '100%' }} ref={ref}>
            {popup === 'settings' && (
                <SettingsPopup
                    partner={params.row as unknown as Partner}
                    handleClose={() => setPopup('')}
                    handleSubmit={(partner) => {
                        update(partner).then((success) => {
                            if (success) {
                                addToast('Partner geupdate!', { variant: 'success' });
                            } else {
                                addToast('Partner updaten mislukt!', { variant: 'error' });
                            }
                        });
                        setPopup('');
                    }}
                />
            )}
            {popup === 'info' && (
                <InfoPopup partner={params.row as unknown as Partner} handleClose={() => setPopup('')} />
            )}
            <IconButton onClick={() => setPopup('settings')}>
                <Settings />
            </IconButton>
            <IconButton onClick={() => setPopup('info')}>
                <Info />
            </IconButton>
        </div>
    );
});

export default Buttons;
