/* eslint-disable lines-between-class-members */
import { action, makeObservable, observable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { IAdministrationStoreApi, Administration, AdministrationMeta } from '../api/administration';

export class AdministrationStore {
    loading = false;
    api: IAdministrationStoreApi;
    administrationMeta: Map<string, AdministrationMeta[]>;
    administrations: Map<string, Administration>;

    constructor({ api }: { api: IAdministrationStoreApi }) {
        makeObservable(this, {
            loading: observable,
            administrations: observable,
            administrationMeta: observable,
            getAdministration: action,
            getAdministrationMeta: action,
        });
        this.api = api;
        this.administrationMeta = new Map();
        this.administrations = new Map();
        autoBind(this);
    }

    async getAdministrationMeta(tenantId: string, reason: string): Promise<AdministrationMeta[]> {
        if (this.administrationMeta.has(tenantId)) {
            return this.administrationMeta.get(tenantId);
        }
        this.loading = true;
        const administrationMeta = await this.api.getAdministrationMeta(tenantId, reason).finally(() => {
            this.loading = false;
        });
        if (administrationMeta) {
            runInAction(() => {
                this.administrationMeta.set(
                    tenantId,
                    administrationMeta.map((meta) => ({
                        ...meta,
                        name: meta.name ? `${meta.administration_nr} - ${meta.name}` : 'Nameless administration',
                        search: `${meta.name} ${meta.administration_nr} ${meta.administration_id}`,
                    })),
                );
            });
        }
    }

    async getAdministration(tenantId: string, administrationId: string, reason: string): Promise<Administration> {
        if (this.administrations.has(administrationId)) {
            return this.administrations.get(administrationId);
        }

        this.loading = true;
        const administration = await this.api.getAdministration(tenantId, administrationId, reason).finally(() => {
            this.loading = false;
        });
        if (administration) {
            runInAction(() => {
                this.administrations.set(administration.id, administration);
            });
        }

        return administration;
    }
}
