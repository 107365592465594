import React, { useEffect, forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import { useToaster } from './useToaster';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as serviceWorker from '../serviceWorkerRegistration';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '344px !important',
        },
        alignItems: 'baseline',
    },
    typography: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        fontWeight: 'bold',
    },
    actionRoot: {
        width: '100%',
        padding: '8px 8px 8px 16px',
        justifyContent: 'space-between',
    },
    button: {
        color: '#fff',
    },
    icons: {
        marginLeft: 'auto',
    },
}));

const SwUpdatePrompt = forwardRef<
    HTMLDivElement,
    { id: string | number; message: string | React.ReactNode; doUpdate: () => void }
>((props, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <Typography variant="subtitle2" className={classes.typography}>
                {props.message}
            </Typography>
            <div className={classes.icons}>
                <Button aria-label="Activeren" className={classes.button} onClick={props.doUpdate}>
                    ACTIVEER
                </Button>
                <IconButton onClick={handleDismiss}>
                    <CloseIcon />
                </IconButton>
            </div>
        </SnackbarContent>
    );
});

export function useServiceWorker(): void {
    const key = 'sw-update-toast';
    const { addToast } = useToaster();

    const handleUpdate = useCallback((worker: ServiceWorker) => {
        worker?.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
    }, []);

    useEffect(() => {
        function showUpdateSwPrompt(registration: ServiceWorkerRegistration) {
            addToast(
                <SwUpdatePrompt
                    id={key}
                    message="Er is een nieuwe versie beschikbaar"
                    doUpdate={() => handleUpdate(registration.waiting)}
                />,
                { key, persist: true, variant: 'info' },
            );
        }

        serviceWorker.register({ onUpdate: showUpdateSwPrompt });
    }, []);
}
