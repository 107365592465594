export default function clean(obj: any, strict = false): any {
    if (typeof obj !== 'object') {
        return obj;
    }
    const cleanObject = { ...obj };
    if (Array.isArray(obj)) {
        return obj.map((item) => clean(item, strict));
    }

    for (const propertyName of Object.getOwnPropertyNames(cleanObject)) {
        if (cleanObject[propertyName] === null) {
            delete cleanObject[propertyName];
            continue;
        }

        if (typeof cleanObject[propertyName] === 'undefined') {
            delete cleanObject[propertyName];
            continue;
        }

        if (!strict && typeof cleanObject[propertyName] === 'boolean' && !cleanObject[propertyName]) {
            delete cleanObject[propertyName];
            continue;
        }

        if (
            typeof cleanObject[propertyName] === 'number' &&
            (Number.isNaN(cleanObject[propertyName]) || (cleanObject[propertyName] === 0 && !strict))
        ) {
            delete cleanObject[propertyName];
            continue;
        }

        if (
            typeof cleanObject[propertyName] === 'string' &&
            (cleanObject[propertyName] === null || cleanObject[propertyName] === '')
        ) {
            delete cleanObject[propertyName];
            continue;
        }

        if (typeof cleanObject[propertyName] === 'object' && Array.isArray(cleanObject[propertyName])) {
            cleanObject[propertyName] = clean(cleanObject[propertyName], strict);
            cleanObject[propertyName] = cleanObject[propertyName].filter((item: any) => !!item);
            if (cleanObject[propertyName].length === 0) {
                delete cleanObject[propertyName];
            }
            continue;
        }

        if (typeof cleanObject[propertyName] === 'object' && cleanObject[propertyName].toISOString) {
            continue;
        }

        if (typeof cleanObject[propertyName] === 'object') {
            cleanObject[propertyName] = clean(cleanObject[propertyName], strict);
            if (Object.keys(cleanObject[propertyName]).length === 0) {
                delete cleanObject[propertyName];
            }
        }
    }
    return cleanObject;
}
