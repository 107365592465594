import React, { ChangeEvent, useEffect, useState } from 'react';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, InputAdornment, Chip, Paper, Typography, Slider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxDataGrid from '../../components/data-grid';
import ApiLogButtons from './buttons';
import distanceOfTimeInWords from '../../utils/distanceOfTimeInWords';
import MnxToolbar from '../../components/mnx-toolbar';
import MnxButton from '../../components/mnx-button';
import MnxTextField from '../../components/mnx-textfield';
import moment from 'moment';

interface KeyboardEvent {
    key: string;
}

const formatMillis = (ms: number): string => {
    if (!ms) return '';
    if (ms < 1000) return Math.floor(ms) + ' ms';
    if (ms < 1000 * 60) return Math.floor(ms / 60) + ' s';
    if (ms < 1000 * 60 * 60) return Math.floor(ms / 60 / 60) + ' m';
};

const columns: GridColDef[] = [
    {
        field: 'method',
        headerName: 'Methode',
        width: 135,
        renderCell: (params: GridCellParams) => (
            <Chip color={params.value === 'GET' ? 'primary' : 'secondary'} label={<>{params.value}</>} />
        ),
    },
    { field: 'tenant_nr', headerName: 'Klantnr.', width: 135 },
    { field: 'administration_nr', headerName: 'Administratienr.', width: 185 },
    { field: 'url', headerName: 'URL', flex: 1 },
    { field: 'response_status', headerName: 'Status', width: 120 },
    {
        field: 'response_time',
        headerName: 'Responstijd',
        width: 160,
        valueFormatter: (params) => formatMillis(params?.row?.response_time),
    },
    {
        field: 'time',
        headerName: 'Wanneer',
        width: 150,
        valueFormatter: (params) => params?.row?.time && distanceOfTimeInWords(new Date().getTime(), params.row.time),
    },
    { field: 'id', headerName: 'Correlatie ID', width: 325 },
    {
        field: '',
        headerName: 'Acties',
        width: 150,
        renderCell: (params: GridCellParams) => <ApiLogButtons params={params} />,
    },
];

const useStyles = makeStyles((theme) => ({
    grid: {
        '& .mnx-cell': {
            textAlign: 'center',
        },
        '& .mnx-cell.primary': {
            opacity: 0.8,
            backgroundColor: '#2c9fdd',
            borderColor: '#2c9fdd',
        },
        '& .mnx-cell.secondary': {
            opacity: 0.8,
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
        '& .mnx-cell.error': {
            opacity: 0.8,
            backgroundColor: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        },
    },
    searchIcon: {
        paddingLeft: theme.spacing(1),
    },
    box_1: {
        background: theme.palette.background.paper,
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center',
        width: '450px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: '4px',
    },
    box_2: {
        width: '450px',
        maxWidth: '90vw',
    },
    box_3: {
        marginBottom: theme.spacing(2),
    },
}));

function ceilTimeQuarterHour(timestamp: number): number {
    const timeToReturn = new Date(timestamp);

    timeToReturn.setMilliseconds(Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000);
    timeToReturn.setSeconds(Math.ceil(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);
    return timeToReturn.valueOf();
}

function getDateRangeMinMax() {
    return {
        min: ceilTimeQuarterHour(moment().subtract(7, 'days').valueOf()),
        max: ceilTimeQuarterHour(moment().valueOf()),
    };
}

function formatDate(value: number) {
    return moment(value).format('DD-MM HH:mm');
}

const ApiLog = observer(() => {
    const {
        apiLogStore: { logs, count, currentPage, loading, setCurrentPage, setPageSize, setSearch, printSearchAsJSON },
    } = useMobxStores();

    const classes = useStyles();

    const [showGrid, setShowGrid] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [dateRangeMinMax] = useState(getDateRangeMinMax());
    const [dateRange, setDateRange] = useState(dateRangeMinMax);

    useEffect(() => {
        setSearch({ search: searchValue, startDate: dateRange.min, endDate: dateRange.max }, true);
        setShowGrid(true);
    }, []);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            setSearch({ search: searchValue, startDate: dateRange.min, endDate: dateRange.max });
        }
    };

    const handleSetDateRange = (_: ChangeEvent<unknown>, value: number | number[]) => {
        const [min, max] = value as number[];
        setDateRange({ min, max });
    };

    const searchKey = '*client_id*/*response_status_code*/*request_correlation_id*';

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box className={classes.box_1}>
                    <Box sx={{ flex: '0 0 auto', marginRight: '15px' }}>{formatDate(dateRange.min)}</Box>
                    <Box sx={{ flex: '1 1 auto' }}>
                        <Slider
                            getAriaLabel={() => 'Datumbereik logs'}
                            step={900000}
                            min={dateRangeMinMax.min}
                            max={dateRangeMinMax.max}
                            value={[dateRange.min, dateRange.max]}
                            onChange={handleSetDateRange}
                        />
                    </Box>
                    <Box sx={{ flex: '0 0 auto', marginLeft: '15px' }}>{formatDate(dateRange.max)}</Box>
                </Box>
                <MnxToolbar gridProps={{ alignItems: 'flex-end' }}>
                    <Box className={classes.box_2}>
                        <Paper>
                            <TextField
                                fullWidth
                                id="input-apilog-search"
                                placeholder="Zoeken.."
                                value={searchValue || ''}
                                onKeyDown={handleKeyDown}
                                onChange={(event) => setSearchValue(event.target.value)}
                                style={{ marginTop: 'auto' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className={classes.searchIcon}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Paper>
                    </Box>
                    <MnxButton
                        key="2"
                        color="primary"
                        style={{ marginTop: 'auto' }}
                        onClick={() => {
                            setSearch({
                                search: searchValue,
                                startDate: dateRange.min,
                                endDate: dateRange.max,
                            });
                        }}
                    >
                        Zoeken
                    </MnxButton>
                    <MnxButton
                        key="3"
                        color="secondary"
                        style={{ marginTop: 'auto' }}
                        onClick={() => {
                            printSearchAsJSON({
                                search: searchValue,
                                startDate: dateRange.min,
                                endDate: dateRange.max,
                            });
                        }}
                    >
                        JSON Export
                    </MnxButton>
                </MnxToolbar>
            </Box>
            <Box className={classes.box_3}>
                <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                    Je zoekt op key: {searchKey}
                </Typography>
            </Box>
            <div style={{ flexGrow: 1, width: '100%' }}>
                {showGrid && (
                    <MnxDataGrid
                        className={classes.grid}
                        loading={loading}
                        rows={[...logs]}
                        page={currentPage - 1}
                        rowCount={count}
                        columns={columns}
                        onPageChange={(newPage) => setCurrentPage(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    />
                )}
            </div>
        </div>
    );
});

export default ApiLog;
