import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Chip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useMobxStores } from '../../hooks/useMobxStores';
import { SearchOption } from '../../stores/search';
import MnxAutocomplete from '../mnx-autocomplete';
import { useKeyCombination } from '../../hooks/useKeyCombination';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40ch',
        },
    },
    searchIcon: {
        paddingLeft: theme.spacing(1),
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    autocompleteRoot: {
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40ch',
        },
    },
    chipRoot: {
        marginRight: theme.spacing(1),
    },
}));

export const Search = observer(() => {
    const {
        searchStore: { search, loading, options, onSelect },
    } = useMobxStores();

    const classes = useStyles();

    const shouldFocusSearch = useKeyCombination('control+m');
    const inputRef = useRef(null);
    useEffect(() => {
        if (!shouldFocusSearch) return;
        inputRef?.current?.focus();
    }, [shouldFocusSearch]);
    const filterOptions = createFilterOptions({ stringify: (option: SearchOption) => option.search });

    return (
        <div className={classes.search}>
            <MnxAutocomplete
                id="search"
                ref={inputRef}
                filterOptions={filterOptions}
                startAdornment={
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                }
                clearOnSelect
                onValueSelected={onSelect}
                classes={{ root: classes.autocompleteRoot }}
                values={options.sort((a, b) => -b.type.localeCompare(a.type))}
                getOptionLabel={(option: SearchOption) => option?.value || ''}
                renderOption={(option: SearchOption) => (
                    <div>
                        <Chip color="secondary" size="small" label={option.type} classes={{ root: classes.chipRoot }} />
                        {option.value}
                    </div>
                )}
                onSearchChange={search}
                label=""
                margin="none"
                size="small"
                loading={loading}
            />
        </div>
    );
});
