/* eslint-disable lines-between-class-members */
import { action, makeObservable, observable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { IUserStoreApi, User } from '../api/user';
import OAuthStore from './oauth';
import { ApiResult, ErrorApiResult } from '../api/apiResult';

export type SearchOption = {
    value: string;
    search: string;
    id?: string;
};

export class UserStore {
    loading = false;
    api: IUserStoreApi;
    oauthStore: OAuthStore;
    users: Map<string, User>;

    constructor({ api, oauthStore }: { api: IUserStoreApi; oauthStore: OAuthStore }) {
        makeObservable(this, {
            search: action,
            users: observable,
        });
        this.api = api;
        this.users = new Map();
        this.oauthStore = oauthStore;
        autoBind(this);
    }

    async getUser(userId: string, tenantId: string, reason: string): Promise<User> {
        if (this.users.has(userId)) {
            return this.users.get(userId);
        }
        if (!tenantId || !reason) return;
        const user = await this.api.getUser(userId, tenantId, reason);
        if (user) {
            runInAction(() => {
                this.users.set(user.user_id, user);
            });
        }

        return user;
    }

    async search(term: string, tenantId: string, reason: string): Promise<SearchOption[]> {
        if (term === '' || term === null || !tenantId || !reason) return [];

        const users = await this.api.search(term, tenantId, reason);
        return (
            users?.map((user) => ({
                value: !user.email
                    ? `${user.full_name || user.name}`
                    : `${user.full_name || user.name} - ${user.email}`,
                id: user.user_id,
                search: `${user.name} ${user.user_id} ${user.full_name}`,
            })) || []
        );
    }

    async resetPassword(userId: string, tenantId: string, reason: string): Promise<ApiResult<void>> {
        if (!userId || !tenantId || !reason) return ErrorApiResult;
        return this.api.resetPassword(userId, tenantId, reason);
    }
}
