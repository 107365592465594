import React, { useEffect, useState } from 'react';
import MnxDialog from '../../components/mnx-dialog';
import PropTypes from 'prop-types';
import { BillingInformation, OldBillingInformation } from '../../api/miscellaneous';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    makeStyles,
    Grid,
    Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('md')]: {
            width: '40rem',
        },
    },
}));

const CreateBillingInfoPopup: React.FunctionComponent<{
    handleSubmit: (information: OldBillingInformation | BillingInformation, type: 'old' | 'new') => void;
    handleClose: () => void;
}> = ({ handleSubmit, handleClose }) => {
    const classes = useStyles();
    const [type, setType] = useState<'old' | 'new'>('new');
    const [disabled, setDisabled] = useState(true);
    const [information, setInformation] = useState(undefined);

    useEffect(() => {
        if (type === 'new') {
            const now = new Date();
            setInformation({ year: now.getFullYear(), month: now.getMonth() });
        } else {
            setInformation({});
        }
    }, [type]);

    useEffect(() => {
        if (type === 'old') {
            setDisabled(!information?.billingFromDate || !information?.billingUntilDate);
        } else {
            setDisabled(!information?.year || !information?.month);
        }
    }, [information]);

    return (
        <MnxDialog
            disabled={disabled}
            title="Factuurinformatie maken"
            handleClose={handleClose}
            handleSubmit={() => handleSubmit(information, type)}
            classes={{ paper: classes.dialog }}
            open
        >
            <FormControl component="fieldset">
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            aria-label="type"
                            name="billing-info-type"
                            value={type}
                            onChange={(event) => setType(event.target.value === 'old' ? 'old' : 'new')}
                        >
                            <FormControlLabel value="old" control={<Radio />} label="Oud" />
                            <FormControlLabel value="new" control={<Radio />} label="Nieuw" />
                        </RadioGroup>
                    </Grid>
                    {type === 'old' ? (
                        <>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    size="small"
                                    id="billing-information-from"
                                    label="Van"
                                    value={information?.billingFromDate || ''}
                                    onChange={(event) =>
                                        setInformation({ ...information, billingFromDate: event.target.value })
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    size="small"
                                    id="billing-information-until"
                                    label="Tot"
                                    value={information?.billingUntilDate || ''}
                                    onChange={(event) => {
                                        setInformation({ ...information, billingUntilDate: event.target.value });
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={information?.generateStoreCoveInformation || false}
                                            onChange={(event) => {
                                                setInformation({
                                                    ...information,
                                                    generateStoreCoveInformation: event.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                    label="Genereer store cove informatie"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={information?.generateFairUsageReport || false}
                                            onChange={(event) => {
                                                setInformation({
                                                    ...information,
                                                    generateFairUsageReport: event.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                    label="Genereer fair usage rapport"
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    size="small"
                                    id="billing-information-year"
                                    label="Jaar"
                                    value={information?.year || ''}
                                    onChange={(event) => setInformation({ ...information, year: event.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    size="small"
                                    id="billing-information-month"
                                    label="Maand"
                                    value={information?.month || ''}
                                    onChange={(event) => setInformation({ ...information, month: event.target.value })}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </FormControl>
        </MnxDialog>
    );
};

CreateBillingInfoPopup.propTypes = {
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
};

export default CreateBillingInfoPopup;
