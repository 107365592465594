import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxDataGrid from '../../components/data-grid';
import MnxToolbar from '../../components/mnx-toolbar';
import MnxButton from '../../components/mnx-button';
import { toJS } from 'mobx';
import { makeStyles, useTheme } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import SendMessagePopup from './sendMessagePopup';
import DisconnectClientsPopup from './disconnectClientsPopup';
import distanceOfTimeInWords from '../../utils/distanceOfTimeInWords';

const formatter = new Intl.DateTimeFormat('nl-NL', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const columns: GridColDef[] = [
    { field: 'solution_name', headerName: 'Soort', width: 130 },
    { field: 'server', headerName: 'Server', width: 200 },
    { field: 'real_owner_name', headerName: 'Acc.naam', width: 120 },
    { field: 'owner_name', headerName: 'Klantnaam', width: 120 },
    { field: 'administrationnr', headerName: 'Adm.nr.', width: 120 },
    { field: 'fiscalyear', headerName: 'Boekj.', width: 120 },
    {
        field: 'idle_time',
        headerName: 'Inactief',
        width: 170,
        valueGetter: (params) =>
            params?.row?.idle_time && distanceOfTimeInWords(new Date().getTime(), params.row.idle_time),
    },
    {
        field: 'login_time',
        headerName: 'Login tijd',
        width: 170,
        valueGetter: (params) => params?.row?.login_time && formatter.format(new Date(params.row.login_time)),
    },
];

const useStyles = makeStyles(() => ({
    toolbar: {
        flexGrow: 0,
    },
}));

const Clients = observer(() => {
    const {
        clientStore: { clients, activate, deactivate, sendMessageToAllClients, disconnectSessions },
    } = useMobxStores();

    const classes = useStyles();
    const [popup, setPopup] = useState(undefined);
    const [selectedClients, setSelectedClients] = useState([]);
    const [showGrid, setShowGrid] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        activate();
        setShowGrid(true);
        return () => {
            deactivate();
        };
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {popup === 'send_message_popup' && (
                <SendMessagePopup
                    handleSubmit={(message: string) => {
                        sendMessageToAllClients(message);
                        setPopup(null);
                    }}
                    handleClose={() => setPopup(null)}
                />
            )}
            {popup === 'disconnect_clients_popup' && (
                <DisconnectClientsPopup
                    selectedClients={selectedClients}
                    handleSubmit={() => {
                        disconnectSessions(selectedClients);
                        setPopup(null);
                    }}
                    handleClose={() => setPopup(null)}
                />
            )}
            <MnxToolbar className={classes.toolbar}>
                <MnxButton
                    key="1"
                    color="primary"
                    mnxColor={theme.palette.error.main}
                    onClick={() => setPopup('disconnect_clients_popup')}
                >
                    Sessies sluiten
                </MnxButton>
                <MnxButton key="2" color="primary" onClick={() => setPopup('send_message_popup')}>
                    Bericht sturen
                </MnxButton>
            </MnxToolbar>
            <div style={{ flexGrow: 1, width: '100%' }}>
                {showGrid && (
                    <MnxDataGrid
                        rows={toJS(clients) || []}
                        columns={columns}
                        checkboxSelection
                        onSelectionModelChange={(newSelection) => {
                            setSelectedClients(newSelection);
                        }}
                    />
                )}
            </div>
        </div>
    );
});

export default Clients;
