import React from 'react';
import { FilledInputProps, IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import PropTypes from 'prop-types';

type MnxTextFieldProps = {
    InputProps?: Partial<FilledInputProps>;
    inputIcon?: React.ReactNode;
    onClickButton?: React.MouseEventHandler<HTMLButtonElement>;
} & TextFieldProps;

const MnxTextField: React.FunctionComponent<MnxTextFieldProps> = ({
    InputProps,
    inputIcon,
    onClickButton,
    ...props
}) => {
    return (
        <TextField
            fullWidth
            // variant="filled"
            size={'small'}
            InputProps={{
                ...InputProps,
                endAdornment: inputIcon ? (
                    <InputAdornment position="end">
                        <IconButton aria-label="copy to clipboard" onClick={onClickButton} edge="end">
                            {inputIcon}
                        </IconButton>
                    </InputAdornment>
                ) : null,
            }}
            {...props}
        />
    );
};

MnxTextField.propTypes = {
    children: PropTypes.any,
};

export default MnxTextField;
