import { observable, action, makeObservable } from 'mobx';
import autoBind from 'auto-bind';

const storageKeys = {
    domain: 'mnx_domain',
};

type Env = { domain: string };

const environment: Env = {
    domain: process.env.REACT_APP_MINOX_DOMAIN,
};

export const domains: { [key: string]: string } = {
    dev: 'http://tst.minox.nl',
    qa: 'https://acc.minox.nl',
    prod: 'https://app.minox.nl',
};

const suggestRbsDomainForMinoxDomain = (url: string): string => {
    switch (url) {
        case domains.dev:
            return 'https://hqd19gnut3.execute-api.eu-west-1.amazonaws.com/prod';
        case domains.qa:
            return 'https://rbs-api.acc.aws.minox.nl';
        case domains.prod:
            return 'https://rbs-api.app.aws.minox.nl';
        default:
            return 'https://hqd19gnut3.execute-api.eu-west-1.amazonaws.com/prod';
    }
};

class Environment {
    domain: string;
    rbsDomain: string;

    constructor() {
        makeObservable(this, {
            domain: observable,
            loadEnvironmentFromStorage: action,
            setDomain: action,
        });
        autoBind(this);
        this.domain = environment.domain || domains.prod;
        this.loadEnvironmentFromStorage();
        this.rbsDomain = suggestRbsDomainForMinoxDomain(this.domain);
    }

    loadEnvironmentFromStorage() {
        const domain = localStorage.getItem(storageKeys.domain);
        if (domain) {
            this.domain = domain;
        }
    }

    setDomain(domain: string) {
        localStorage.setItem(storageKeys.domain, domain);
        this.domain = domain;
        this.rbsDomain = suggestRbsDomainForMinoxDomain(this.domain);
    }
}

export default new Environment();
