import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useMobxStores } from '../../hooks/useMobxStores';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        wordBreak: 'break-word',
    },
}));

const Home: React.FunctionComponent = () => {
    const classes = useStyles();
    const {
        oauthStore: { name },
    } = useMobxStores();

    const quotes = [
        'Vier de kleine overwinning!',
        'Jouw passie wacht op je!',
        'Magie.. dat is klanten helpen!',
        'Één Minox, één visie!',
        'Dit is niet een dag, dit is dé dag',
        'Investeer in je klant! Poets nu, glim later!',
        'Minox is een raket! Klim aan boord!',
        'Hard werken maakt je sterker!',
        'Albert Einstein had net zoveel uren in de dag als jij!',
        'Het enige verschil in gewoon en uitmuntend zijn, dat ben jij!',
        'Morgen wordt nog beter dankzij jou!',
        'Wij waarderen jou!',
        'Jullie helpen de klant. -Wij- zijn er voor jullie! Altijd.',
        'Wij zijn je vriend. De klant is jullie familie!',
        'Alleen jij bent het verschil tussen ons en de concurrentie!',
        'Service zonder gevoel kan iedereen. Jij maakt het verschil!',
        'Later worden dingen niet gedaan. Alleen nu!',
        'Goed bezig zijn kan iedereen. Ga voor geweldig!',
        'Jouw koffie maakt de klantbeleving beter!',
        '{partOfDay}? Geen probleem. Ook nu staan we voor je klaar!',
        'Leuk je te zien, {name}! Ben jij er ook klaar voor?',
        'Jeej! Je bent er weer! Koffie of thee bij de hand?',
        'Inspiratieloos? Maak een praatje met ons!',
        'Succes komt niet alleen door talent en vaardigheden, maar ook door toewijding, doorzettingsvermogen en de bereidheid om keer op keer op te staan na elke val.',
        'De sleutel tot succes ligt niet in het vermijden van fouten, maar in het leren en groeien vanuit elke ervaring.',
    ];

    function fuzzyDayPart() {
        const dt = new Date();
        const day = dt.getDay();
        const hour = dt.getHours();
        const daysAsArray = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
        let fuzzy = daysAsArray[day];
        if (hour >= 0 && hour < 6) fuzzy += 'nacht';
        if (hour >= 6 && hour < 12) fuzzy += 'ochtend';
        if (hour >= 12 && hour < 18) fuzzy += 'middag';
        if (hour >= 18 && hour <= 23) fuzzy += 'avond';
        return fuzzy;
    }

    function autoInjectValuesIntoQuote(quote: string): string {
        let newQuote = quote;
        newQuote = newQuote.replace(/{partOfDay}/, fuzzyDayPart());
        newQuote = newQuote.replace(/{name}/, name || 'collega');
        return newQuote;
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <h3>Welkom, {name || 'gebruiker'}</h3>
                        <p>
                            Wat een heerlijke dag om een productieve, gezellige medewerker van Minox te zijn! Minox
                            streeft continue om jouw productiviteit en blijdschap groter te maken! Jouw feedback hierbij
                            is waardevol voor ons!
                        </p>
                        <h4>
                            "{autoInjectValuesIntoQuote(quotes[Math.floor(Math.random() * quotes.length)])} -- jouw
                            developer vriend"
                        </h4>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

Home.propTypes = {
    path: PropTypes.string,
};

export default Home;
