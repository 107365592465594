import { makeObservable } from 'mobx';
import autoBind from 'auto-bind';

export default class UIStore {
    constructor() {
        makeObservable(this, {});
        autoBind(this);
    }

    async initialize(): Promise<void> {
        console.error('Unimplemented');
    }
}
