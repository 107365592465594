import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Paper } from '@material-ui/core';
import { useMobxStores } from '../../hooks/useMobxStores';
import MnxButton from '../../components/mnx-button';
import { useToaster } from '../../hooks/useToaster';
import { AdministrationStoreApi } from '../../api/administration';

const UnblockPayables = observer(() => {
    const inputRef = useRef(null);
    const { addToast } = useToaster();
    const [api] = useState(new AdministrationStoreApi());

    const {
        applicationStore: { reason, tenantId, administrationId },
    } = useMobxStores();

    useEffect(() => {
        inputRef?.current?.focus();
    }, []);

    return (
        <>
            <Paper style={{ padding: 16 }} elevation={3}>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <p>
                            Dit onderdeel stelt je in staat om geforceerd openstaande posten vrij te geven van hun
                            Bizcuit blokkering. Dit betekent dat ze weer beschikbaar komen voor onderdelen zoals
                            automatische betalingen. LET OP: Dit kan in de slechtste gevallen leiden tot dubbele
                            betalingen!
                        </p>
                    </Grid>
                </Grid>
                <Box pt={2}>
                    <Grid item>
                        <MnxButton
                            onClick={() => {
                                api.unblockPayables(tenantId, administrationId, reason).then((apiResult) => {
                                    if (apiResult.result === 'ok') {
                                        addToast('Openstaande posten succesvol gedeblokkeerd!', { variant: 'success' });
                                    }
                                    if (apiResult.result === 'unauthorized') {
                                        addToast(apiResult.message, {
                                            variant: 'warning',
                                        });
                                    }
                                    if (['error', 'forbidden'].includes(apiResult.result)) {
                                        addToast(apiResult.message, { variant: 'error' });
                                    }
                                });
                            }}
                            disabled={!reason || !tenantId || !administrationId}
                            color="primary"
                        >
                            Openstaande posten vrijgeven
                        </MnxButton>
                    </Grid>
                </Box>
            </Paper>
        </>
    );
});

export default UnblockPayables;
