import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';
import { defaultRoutes, customerSupportRoutes, operationsRoutes, managementRoutes, salesRoutes } from '../../routes';
import { useMobxStores } from '../../hooks/useMobxStores';

const drawerWidth = 240;

export const themeOptions = {
    palette: {
        type: 'dark',
        background: {
            default: '#E1E1E1',
            paper: '#333333',
        },
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        position: 'static',
        [theme.breakpoints.up('lg')]: {
            position: 'static',
        },
    },
    drawerContainer: {
        overflow: 'auto',
    },
    navLink: {
        '&.selected *': {
            color: theme.palette.primary.light,
            fontWeight: 500,
        },
    },
    subheader: {
        background: theme.palette.background.default,
    },
}));

const Sidebar: React.FunctionComponent<{ open: boolean; onClose: () => void }> = (props) => {
    const { open, onClose } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));
    const {
        oauthStore: { profile },
    } = useMobxStores();

    return (
        <Drawer
            className={classes.drawer}
            variant={isLg ? 'permanent' : 'temporary'}
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerContainer}>
                <List>
                    {defaultRoutes.map(({ title, path, icon }) => (
                        <NavLink
                            to={path}
                            key={title}
                            onClick={onClose}
                            exact
                            className={classes.navLink}
                            activeClassName="selected"
                        >
                            <ListItem button key={title}>
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText primary={title} />
                            </ListItem>
                        </NavLink>
                    ))}
                </List>
                {profile?.isSales && (
                    <>
                        <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
                                    Sales
                                </ListSubheader>
                            }
                        >
                            {salesRoutes.map(({ title, path, icon }) => (
                                <NavLink
                                    to={path}
                                    key={title}
                                    onClick={onClose}
                                    className={classes.navLink}
                                    activeClassName="selected"
                                >
                                    <ListItem button key={title}>
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText primary={title} />
                                    </ListItem>
                                </NavLink>
                            ))}
                        </List>
                    </>
                )}
                {profile?.isManagement && (
                    <>
                        <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
                                    Management
                                </ListSubheader>
                            }
                        >
                            {managementRoutes.map(({ title, path, icon }) => (
                                <NavLink
                                    to={path}
                                    key={title}
                                    onClick={onClose}
                                    className={classes.navLink}
                                    activeClassName="selected"
                                >
                                    <ListItem button key={title}>
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText primary={title} />
                                    </ListItem>
                                </NavLink>
                            ))}
                        </List>
                    </>
                )}
                {profile?.isCustomerSupport && (
                    <>
                        <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
                                    Customer support
                                </ListSubheader>
                            }
                        >
                            {customerSupportRoutes.map(({ title, path, icon }) => (
                                <NavLink
                                    to={path}
                                    key={title}
                                    onClick={onClose}
                                    className={classes.navLink}
                                    activeClassName="selected"
                                >
                                    <ListItem button key={title}>
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText primary={title} />
                                    </ListItem>
                                </NavLink>
                            ))}
                        </List>
                    </>
                )}
                {profile?.isOperations && (
                    <>
                        <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
                                    Operations
                                </ListSubheader>
                            }
                        >
                            {operationsRoutes.map(({ title, path, icon }) => (
                                <NavLink
                                    to={path}
                                    key={title}
                                    onClick={onClose}
                                    className={classes.navLink}
                                    activeClassName="selected"
                                >
                                    <ListItem button key={title}>
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText primary={title} />
                                    </ListItem>
                                </NavLink>
                            ))}
                        </List>
                    </>
                )}
            </div>
        </Drawer>
    );
};

export default Sidebar;
