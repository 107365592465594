import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';

const ReasonForm: React.FunctionComponent<{ onChange: (reason: string) => void; reasonIsValid: boolean }> = ({
    onChange,
    reasonIsValid,
}) => {
    const [showError, setShowError] = useState(false);

    return (
        <Grid item xs={12}>
            <Typography paragraph>
                In het kader van de algemene verordening gegevensbescherming (
                <Link href="https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/avg-europese-privacywetgeving">
                    AVG
                </Link>
                ) dient er een reden te zijn voor het opvragen van gegevens:
            </Typography>
            <TextField
                onBlur={() => {
                    setShowError(!reasonIsValid);
                }}
                autoFocus
                multiline
                maxRows={4}
                size="small"
                id="reason"
                label="Reden"
                helperText={showError ? 'Reden is te kort' : ''}
                error={showError}
                onChange={(event) => {
                    setShowError(false);
                    onChange(escape(event.target.value));
                }}
                fullWidth
            />
        </Grid>
    );
};

export default ReasonForm;
