import { ThemeProvider, useMediaQuery } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useMobxStores } from '../../hooks/useMobxStores';
import { dark_theme, light_theme } from '../../theme';

const ThemeWrapper = observer(({ children }: { children: any }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const {
        applicationStore: { isDarkMode, setIsDarkMode },
    } = useMobxStores();

    useEffect(() => {
        setIsDarkMode(prefersDarkMode);
    }, [prefersDarkMode]);

    return (
        <ThemeProvider theme={isDarkMode ? dark_theme : light_theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
});

export default ThemeWrapper;
