import React, { BaseSyntheticEvent } from 'react';
import { IconButton } from '@material-ui/core';
import { GridCellParams } from '@material-ui/data-grid';
import { QosError } from '../../api/qosError';
import { useMobxStores } from '../../hooks/useMobxStores';
import BuildIcon from '@material-ui/icons/Build';

const QosErrorButtons: React.FunctionComponent<{ params: GridCellParams; refresh: () => void }> = ({
    params,
    refresh,
}) => {
    const {
        qosErrorStore: { repairError },
    } = useMobxStores();
    const { id, fixed, fixable } = (params?.row as QosError) || {};

    if (fixed || !fixable) return null;

    return (
        <div style={{ height: '100%' }}>
            <IconButton
                onClick={async (e: BaseSyntheticEvent) => {
                    e.stopPropagation();
                    await repairError(id);
                    refresh();
                }}
            >
                <BuildIcon />
            </IconButton>
        </div>
    );
};

export default QosErrorButtons;
