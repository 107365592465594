import { ApiResult, createApiResult } from './apiResult';
import request from '../utils/request';
import environment from '../env';

export type AvailableAdministration = {
    id: string;
    nr: number;
    name: string;
};

export type MinoxConnectionStatus = {
    connected: boolean;
    tenant_nr: number;
    administration_nr: number;
    available_administrations: AvailableAdministration[];
};

export type AdministrationNumber = {
    administration_nr: number;
};

export interface IInternalAdministrationApi {
    getConnectionStatus(): Promise<ApiResult<MinoxConnectionStatus>>;
    updateSettings(settings: AdministrationNumber): Promise<ApiResult<void>>;
}

export class InternalAdministrationApi implements IInternalAdministrationApi {
    getConnectionStatus(): Promise<ApiResult<MinoxConnectionStatus>> {
        return createApiResult(request('GET', `${environment.rbsDomain}/oauth/settings-for-minox`));
    }

    updateSettings(settings: MinoxConnectionStatus): Promise<ApiResult<void>> {
        return createApiResult(request('PATCH', `${environment.rbsDomain}/oauth/settings-for-minox`).send(settings));
    }
}
